<template>
	<div class="is-inline-flex">
		<!-- expand collapse buttons for groups-->
		<ws-button
			v-tooltip="expandTooltip"
			data-testid="expand-button"
			is-primary
			is-outlined
			name="button"
			@click="$emit('expandedRows')"
		>
			<template #icon>
				<ws-icon icon="up-right-and-down-left-from-center" />
			</template>
		</ws-button>
		<ws-button
			v-tooltip="collapseTooltip"
			data-testid="collapse-button"
			class="ml-1"
			is-primary
			is-outlined
			name="button"
			@click="$emit('collapsedRows')"
		>
			<template #icon>
				<ws-icon icon="down-left-and-up-right-to-center" />
			</template>
		</ws-button>
	</div>
</template>

<script>
export default {
	name: "ButtonExpandCollapse",
	props: {
		collapseTooltip: {
			type: String,
			default: null
		},
		expandTooltip: {
			type: String,
			default: null
		}
	},
	data() {
		return {};
	},
	emits: ["expandedRows", "collapsedRows"]
};
</script>
