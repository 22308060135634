<!-- NOTE: THIS IS IMPLEMENTATION OF A SINGLE <radio> INPUT.
IF YOU WANT TO USE MULTIPLE OPTIONS, USE THE <ws-form-radio-input> COMPONENT -->
<template>
	<div class="mr-10" style="margin-bottom: 8px">
		<div
			class="control radio-input p-0"
			:class="{
				checked: checked,
				error: error !== null,
				disabled: disabled
			}"
		>
			<label class="radio is-align-items-flex-start">
				<input
					type="radio"
					style="margin-top: 2px"
					:value="inputValue"
					:checked="checked ? 'checked' : null"
					@input="$emit('update:modelValue', $event.target.value)"
					:disabled="disabled"
				/>
				<slot>
					{{ text }}
				</slot>
			</label>
		</div>
		<p class="help is-danger" v-if="!!error">{{ error }}</p>
	</div>
</template>

<script>
export default {
	/**
	 * NOTE: THIS IS IMPLEMENTATION OF A SINGLE <radio> INPUT.
	 * IF YOU WANT TO USE MULTIPLE OPTIONS, USE THE <ws-form-radio-input> COMPONENT
	 */
	name: "RadioInput",

	emits: ["update:modelValue"],

	props: {
		text: {
			type: [String, null],
			default: null
		},
		checked: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: [String, Number, Boolean, null],
			default: null
		},
		inputValue: {
			type: [String, Number, Boolean, null],
			default: null,
			required: true
		},
		error: {
			type: [String, null],
			default: null
		}
	}
};
</script>

<style lang="scss">
// temporaty solution while vite split and bundle css not in a correct way - https://github.com/vitejs/vite/issues/3924
.radio-input {
	background: $white;
	padding: 10px 15px;
	border-radius: 5px;
	margin-bottom: 5px;
	border: none !important;
	box-shadow: none !important;

	&.checked {
		border: none !important;
		color: unset !important;
	}
}
</style>
<style lang="scss" scoped>
.radio-input {
	&.error {
		border: 0.5px solid $color-danger-500;

		label:hover {
			color: $color-danger-500;
		}
	}

	&.disabled:not(.checked),
	label:hover {
		color: inherit;
	}
}
</style>
