<template>
	<tr style="height: 35px">
		<th
			:colspan="props.totalColumns"
			data-testid="header-checkbox-selection-info"
			id="ws_datatable_column_header_selected_rows_length"
			class="has-background-grey-200"
			style="padding: 8px 16px"
		>
			<div
				class="is-flex is-justify-content-space-between has-text-grey-700"
			>
				<div
					class="is-flex is-flex-direction-column is-justify-content-center has-text-weight-bold"
				>
					{{ selectableMessage }}
				</div>
				<div
					class="has-text-centered has-text-weight-light"
					style="margin: auto"
				>
					<span
						v-if="
							props.isDataFiltered ||
							props.allAvailableRowsAreSelected
						"
					>
						{{
							$t(
								"ws-data-table.rows-of-page-are-selected",
								{ qtt: props.selectedRows },
								props.selectedRows
							)
						}}
					</span>

					<ws-text-link
						v-if="!allVisibleRowsAreSelected"
						:is-link="true"
						:show-icon="false"
						@click="emits('applySelectToAllRows', true)"
					>
						{{
							$t(
								"ws-data-table.select-all-available-rows",
								{
									qtt: totalVisibleAvailableRowsToSelect
								},
								totalVisibleAvailableRowsToSelect
							)
						}}
					</ws-text-link>
					<ws-text-link
						v-if="props.selectedRows"
						:is-link="true"
						:show-icon="false"
						@click="emits('applySelectToAllRows', false)"
					>
						{{ $t("clear-selection") }}
					</ws-text-link>
				</div>
				<div class="buttons mb-0 ml-4" v-if="slots['bulk-action']">
					<!-- <ws-button
						class="mb-0"
						is-primary
						is-outlined
						@click="emits('applySelectToAllRows', false)"
					>
						{{ $t("cancel") }}
					</ws-button> -->
					<slot name="bulk-action" />
				</div>
			</div>
		</th>
	</tr>
</template>

<script setup>
import { computed, useSlots } from "vue";
import { WS_DATATABLE_SELECT, WS_IGNORE_SELECT } from "../helpers.js";
import { useI18n } from "vue-i18n";

const emits = defineEmits(["applySelectToAllRows"]);

const slots = useSlots();

const props = defineProps({
	totalColumns: {
		type: Number,
		default: 0
	},
	selectableMessage: {
		type: Function,
		default: null
	},
	visibleRows: {
		type: Array,
		default: () => []
	},
	allAvailableRowsAreSelected: {
		type: Boolean,
		default: false
	},
	isDataFiltered: {
		type: Boolean,
		default: false
	},
	selectedRows: {
		type: Number,
		default: 0
	}
});

const { t: $t } = useI18n();

const totalVisibleAvailableRowsToSelect = computed(() => {
	return props.visibleRows.filter((row) => !row[WS_IGNORE_SELECT]).length;
});

const totalSelectedRowsFromVisibleRows = computed(() => {
	return props.visibleRows.filter(
		(row) => row[WS_DATATABLE_SELECT] && !row[WS_IGNORE_SELECT]
	).length;
});

const selectableMessage = computed({
	get() {
		if (props.selectableMessage) {
			return props.selectableMessage(
				totalSelectedRowsFromVisibleRows.value,
				totalVisibleAvailableRowsToSelect.value
			);
		}

		return `${$t("selected")}: ${totalSelectedRowsFromVisibleRows.value}/${
			totalVisibleAvailableRowsToSelect.value
		}`;
	},

	set(newValue) {
		return props.selectableMessage(newValue);
	}
});

const allVisibleRowsAreSelected = computed(() => {
	return (
		totalSelectedRowsFromVisibleRows.value ===
		totalVisibleAvailableRowsToSelect.value
	);
});
</script>

<style scoped lang="scss">
// as it will use a <ws-dropdown> component and it has a <Ws-button> inside, it's necessary to remove the margin-bottom of it (child component from <slot name="bulk-action">)
.buttons {
	:deep(button.button) {
		margin-bottom: 0;
	}
}
</style>
