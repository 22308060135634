<template>
	<ws-form-select
		:id="props.id"
		:label="props.label"
		v-model="content"
		:options="optionsList"
		:allow-null="false"
		@update:model-value="changedLanguage"
		:data-testid="props.dataTestid"
	>
		<template #afterInput>
			<span class="icon globe is-left">
				<ws-icon icon="globe" />
			</span>
		</template>
	</ws-form-select>
</template>
<script setup>
import { ref, computed, watchEffect } from "vue";
import { generateRandomId } from "@/helpers/functions.helper.js";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	id: {
		type: String,
		default: () => {
			return generateRandomId();
		}
	},
	modelValue: {
		type: String,
		default: "en"
	},
	label: {
		type: String,
		default: null
	},
	options: {
		type: Array,
		default: () => ["en", "fr", "de"]
	},
	dataTestid: {
		type: String,
		default: "language-selector"
	}
});

const content = ref(null);
watchEffect(() => {
	content.value = props.options.includes(props.modelValue)
		? props.modelValue
		: props.options[0];
	// changedLanguage();
});
const optionsList = computed(() => {
	return props.options.map((lang) => {
		return {
			value: lang,
			label: lang.toUpperCase()
		};
	});
});

function changedLanguage() {
	emits("update:modelValue", content.value);
}
</script>

<style lang="scss" scoped>
:deep(.select) {
	width: unset !important;

	select {
		padding-left: 2rem !important;
	}
}
:deep(.icon.globe) {
	color: $color-grey-500 !important;
	font-size: 0.8em;
	line-height: 3em;
	left: 4px;
	top: 8px !important;
	position: absolute;
}
</style>
