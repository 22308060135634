const LOG_LEVELS = ["debug", "info", "warn", "error", "fatal"];
const DEFAULT_OPTIONS = {
	isEnabled: true,
	logLevel: "debug",
	separator: "|",
	showConsoleColors: true,
	showLogLevel: true,
	showMethodName: false,
	showFiledName: true,
	stringifyArguments: false
};

const getMethodName = function () {
	let error = {};

	try {
		throw new Error("");
	} catch (e) {
		error = e;
	}
	// IE9 does not have .stack property
	if (error.stack === undefined) {
		return "";
	}
	let stackTrace = error.stack.split("\n")[3];
	if (/ /.test(stackTrace)) {
		stackTrace = stackTrace.trim().split(" ")[1];
	}
	if (stackTrace && stackTrace.indexOf(".") > -1) {
		stackTrace = stackTrace.split(".")[1];
	}
	return stackTrace;
};

const getFileName = function () {
	let error = {};
	const STACK_FUNC_NAME = new RegExp(/at\s+((\S+)\s)?\((\S+):(\d+):(\d+)\)/);

	try {
		throw new Error("");
	} catch (e) {
		error = e;
	}
	// IE9 does not have .stack property
	if (error.stack === undefined) {
		return "";
	}
	let stacks = error.stack.split("\n");

	let callerInfo = null;
	callerInfo = STACK_FUNC_NAME.exec(stacks[3]);
	if (callerInfo) {
		return {
			filename: callerInfo[3],
			line: callerInfo[4],
			column: callerInfo[5],
			val: callerInfo[3] + ":" + callerInfo[4] + ":" + callerInfo[5]
		};
	}
	return {
		val: ""
	};
};

const printLogMessage = function (
	logLevel = "warn",
	logMessage = "",
	showConsoleColors = false,
	formattedArguments = null
) {
	if (
		showConsoleColors &&
		(logLevel === "warn" || logLevel === "error" || logLevel === "fatal")
	) {
		const debugLogLevel = logLevel === "debug" ? "trace" : logLevel;
		// eslint-disable-next-line
		console[logLevel === "fatal" ? "error" : debugLogLevel](
			logMessage,
			...formattedArguments
		);
	} else {
		// eslint-disable-next-line
		console.trace(logMessage, ...formattedArguments);
	}
};
const logger = {
	warn: () => {},
	error: () => {},
	fatal: () => {},
	debug: () => {},
	trace: () => {}
};

const initLoggerInstance = function (options, logLevels) {
	logLevels.forEach((logLevel) => {
		if (
			options.isEnabled &&
			logLevels.indexOf(logLevel) >= logLevels.indexOf(options.logLevel)
		) {
			logger[logLevel] = (...args) => {
				const fileName = getFileName();
				const filePrefix = options.showFiledName
					? fileName.val + ` ${options.separator} `
					: "";

				const methodName = getMethodName();
				const methodNamePrefix = options.showMethodName
					? methodName + ` ${options.separator} `
					: "";
				const logLevelPrefix = options.showLogLevel
					? logLevel + ` ${options.separator} `
					: "";
				const formattedArguments = options.stringifyArguments
					? args.map((a) => JSON.stringify(a))
					: args;
				const logMessage = `${logLevelPrefix}  ${filePrefix} ${methodNamePrefix}`;
				printLogMessage(
					logLevel,
					logMessage,
					options.showConsoleColors,
					formattedArguments
				);
				return `${logMessage} ${formattedArguments.toString()}`;
			};
		} else {
			logger[logLevel] = () => undefined;
		}
	});
	return logger;
};

export const $log = logger;
const useLogger = () => {
	// when logger is instantiated by app, it will overwrite logger object, so it's ok to set isEnabled as false
	if (Object.keys(logger).length === 0) {
		initLoggerInstance(
			{
				...DEFAULT_OPTIONS,
				isEnabled: false,
				logLevel: "debug",
				showConsoleColors: true
			},
			LOG_LEVELS
		);
	}

	return LOG_LEVELS.reduce(
		(acc, curr) => ({
			...acc,
			[curr]: $log[curr]
		}),
		{}
	);
};

export { useLogger };
export default {
	install(app, options = {}) {
		app.$log = initLoggerInstance(
			{ ...DEFAULT_OPTIONS, ...options },
			LOG_LEVELS
		);
		app.config.globalProperties.$log = app.$log;
	}
};
