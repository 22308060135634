<template>
	<div class="control-tag" :data-testid="props.dataTestid">Control</div>
</template>
<script setup>
const props = defineProps({
	dataTestid: {
		type: String,
		default: "control-licence-tag"
	}
});
</script>
<style lang="scss" scoped>
.control-tag {
	display: inline;
	padding: 0.25rem 0.5rem;
	justify-content: center;
	align-items: center;
	border-radius: 0.75rem;
	color: #ffffff;
	background: #4285fb;

	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1rem;
	max-height: 1.5rem;
}
</style>
