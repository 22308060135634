<template>
	<span>
		<label v-if="column.type === 'checkbox'" class="checkbox">
			<input
				data-testid="row-checkbox"
				type="checkbox"
				:checked="isRowChecked(row)"
				:disabled="row.WS_IGNORE_SELECT === true"
				@input="$emit('change', $event.target.checked)"
			/>
		</label>
		<span v-else>
			{{ row[column.selector] }}
		</span>
	</span>
</template>

<script>
import { WS_DATATABLE_SELECT } from "../helpers";
export default {
	name: "RowContent",

	emits: ["change"],

	props: {
		row: {
			type: Object,
			default: () => ({}),
			required: true
		},
		column: {
			type: Object,
			default: () => ({}),
			required: true
		}
	},

	methods: {
		isRowChecked(row) {
			return row[WS_DATATABLE_SELECT];
		}
	}
};
</script>
