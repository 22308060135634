import helpers from "@/helpers/functions.helper";
import _countryCodes from "./countryCodesEnFr.json";
export const countryCodes = _countryCodes;

export const alpha2ToCode = countryCodes.reduce((acc, country) => {
	if (country.separator) {
		return { ...acc };
	}
	return {
		...acc,
		[country.code]: country.dial_code
	};
}, {});
export const codeToAlpha2 = countryCodes.reduce((acc, country) => {
	if (country.separator) {
		return { ...acc };
	}
	return {
		...acc,
		[country.dial_code]: country.code
	};
}, {});
export const codeToPlaceholder = countryCodes.reduce((acc, country) => {
	if (country.separator) {
		return { ...acc };
	}
	return {
		...acc,
		[country.dial_code]: country.placeholder || ""
	};
}, {});
export const alphaToPlaceholder = countryCodes.reduce((acc, country) => {
	if (country.separator) {
		return { ...acc };
	}
	return {
		...acc,
		[country.code]: country.placeholder || ""
	};
}, {});
export const countryCodesOptions = (lang) => {
	return countryCodes
		.map((entry) => {
			const countryName = lang === "fr" ? entry.nameFr : entry.name;
			const image = new URL(
				`../../assets/imgs/flags/${entry.code.toLowerCase()}.png`,
				import.meta.url
			).href;
			return {
				code: entry.code,
				dial_code: entry.dial_code,
				favorite: entry.favorite || false,
				separator: entry.separator || false,
				$isDisabled: entry.separator || false,
				value: entry.code,
				original: entry.original || "",
				countryName,
				// label: `${countryName} ${entry.dial_code} ${entry.original}`,
				label: `${countryName} ${entry.dial_code}`,
				name: entry.dial_code,
				img: image
			};
		})
		.sort((a, b) => {
			if (!a.countryName || !b.countryName) {
				return 0;
			}
			if (
				helpers.replaceSpecialCharacteres(a.countryName) <
				helpers.replaceSpecialCharacteres(b.countryName)
			) {
				return -1;
			}
			if (
				helpers.replaceSpecialCharacteres(a.countryName) >
				helpers.replaceSpecialCharacteres(b.countryName)
			) {
				return 1;
			}
			return 0;
		})
		.sort((a, b) => {
			if (!a.favorite && b.favorite) {
				return 1;
			}
			if (a.favorite && !b.favorite) {
				return -1;
			}
			return 0;
		});
};

import * as googleLibPhoneNumber from "google-libphonenumber";
export const PNF = googleLibPhoneNumber.PhoneNumberFormat;
export const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
