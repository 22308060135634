<template>
	<div
		class="field"
		:class="[
			{ 'is-flex is-justify-content-space-between': props.isInline }
		]"
	>
		<ws-form-label
			v-if="props.label || hasLabelSlot"
			:disabled="props.disabled"
			:id="`${props.id}-label`"
			:for="props.id"
			:error="props.error"
			:is-inline="props.isInline"
			:optional="props.optional"
			:tooltip="props.tooltip"
			:tooltip-position="props.tooltipPosition"
		>
			<slot name="label">{{ label }}</slot>
		</ws-form-label>
		<label class="label-description" v-if="props.labelDescription">
			{{ props.labelDescription }}
		</label>
		<div
			class="control is-expanded inputField ws-treeselect"
			:class="{ 'has-text-danger': !!error }"
		>
			<treeselect
				:key="componentKey"
				:id="props.id"
				:data-testid="props.dataTestid"
				:aria-label="props.label"
				:is-single-select="props.isSingleSelect"
				:options="props.options"
				:placeholder="props.placeholder"
				v-model="modelValue"
				@update:model-value="selectedOption"
				:show-tags="props.showTags"
				:clearable="props.clearable"
				:always-open="props.alwaysOpen"
				:open-level="props.openLevel"
				:expand-selected="true"
				:show-placeholder-on-open="true"
				:unselect-on-click-single-selected="true"
				:default-padding="12"
				:zero-level-item-padding="16"
				:is-independent-nodes="true"
				:empty-text="$t('ws-form-treeselect.no-results')"
				@open="emits('open')"
				@close="emits('close')"
			/>
			<p
				class="help mb-0"
				v-if="props.labelUnderInput || hasLabelUnderInputSlot"
			>
				<span
					v-if="props.labelUnderInput"
					v-html="props.labelUnderInput"
				/>
				<slot name="labelUnderInput" />
			</p>
			<p class="help is-danger" v-if="!!props.error">
				{{ props.error }}
			</p>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, useSlots, watchEffect, watch, nextTick } from "vue";
import { generateRandomId } from "@/helpers/functions.helper.js";
import Treeselect from "./custom-vue-treeselectjs.vue";
import "vue-treeselectjs/dist/vue-treeselectjs.css";

const emits = defineEmits(["update:model-value", "open", "close"]);

/**
 * If there's missing a prop to treeselect component, look at its documentation to add it here: https://www.npmjs.com/package/vue-treeselectjs
 */
const props = defineProps({
	id: {
		type: String,
		default: () => {
			return generateRandomId();
		},
		required: true
	},
	dataTestid: {
		type: String,
		default: "treeselect"
	},
	label: {
		type: String,
		default: null
	},
	modelValue: {
		type: [String, Number],
		default: null
	},
	placeholder: {
		type: [String, null],
		default: null
	},
	options: {
		type: Array,
		default: () => [],
		validate: (value) => {
			// {name: String, value: String, disabled?: Boolean, htmlAttr?: object, children: [] }
			// https://www.npmjs.com/package/vue-treeselectjs#option-description
			function validateOption(option) {
				return (
					typeof option === "object" &&
					Object.prototype.hasOwnProperty.call(option, "name") &&
					typeof option.name === "string" &&
					Object.prototype.hasOwnProperty.call(option, "value") &&
					typeof option.value === "string" &&
					(!Object.prototype.hasOwnProperty.call(
						option,
						"disabled"
					) ||
						typeof option.disabled === "boolean") &&
					(!Object.prototype.hasOwnProperty.call(
						option,
						"htmlAttr"
					) ||
						typeof option.htmlAttr === "object") &&
					(!Object.prototype.hasOwnProperty.call(
						option,
						"children"
					) ||
						(Array.isArray(option.children) &&
							option.children.every(validateOption)))
				);
			}

			return value.every((option) => {
				return validateOption(option);
			});
		}
	},
	showTags: {
		type: Boolean,
		default: false
	},
	clearable: {
		type: Boolean,
		default: false
	},
	alwaysOpen: {
		type: Boolean,
		default: false
	},
	openLevel: {
		type: Number,
		default: 1
	},
	isSingleSelect: {
		type: Boolean,
		default: true
	},
	disabled: {
		type: Boolean,
		default: false
	},
	error: {
		type: [String, Boolean],
		default: null
	},
	isInline: {
		type: Boolean,
		default: false
	},
	optional: {
		type: Boolean,
		default: false
	},
	labelDescription: {
		type: String,
		default: null
	},
	labelUnderInput: {
		type: String,
		default: null
	},
	tooltip: {
		type: [String, Object],
		default: null
	},
	tooltipPosition: {
		type: String,
		default: "top"
	}
});

const componentKey = ref(0);
const modelValue = ref(props.modelValue);
watchEffect(async () => {
	modelValue.value = props.modelValue;
});

/**
 * Due to some weird bug in treeselectjs component, we need to update the key of the component to force it to re-render when the options change (otherwise, user won't see in the dropdown the selected organization)
 */
watch(
	() => props.options,
	async () => {
		// when testing, bug is not present
		if (import.meta.env.MODE === "test") {
			return;
		}
		await nextTick();
		componentKey.value++;
	}
);

const { label: slotLabel, labelUnderInput: slotLabelUnderInput } = useSlots();
const hasLabelSlot = computed(() => !!slotLabel);
const hasLabelUnderInputSlot = ref(!!slotLabelUnderInput);

function selectedOption(value) {
	modelValue.value = value;
	emits("update:model-value", value);

	// timeout to move the blur() to the end of the event loop because the treeselect component will trigger a focus() using a timeout with 0ms
	setTimeout(() => {
		const treeselectInput = document.querySelector(
			".treeselect-input__edit"
		);
		if (!treeselectInput) {
			return;
		}
		treeselectInput.blur();
	}, 10);
}
</script>

<style lang="scss" scoped>
::v-deep(.vue-treeselect) {
	.treeselect {
		&-input {
			min-height: 40px;
			border-radius: 8px;
			color: #35495e;
			background-color: rgb(247, 247, 247);
			border-color: $input-border-color;
			padding-left: 8px;

			&:hover {
				border-color: $input-hover-color;
			}
			&:focus {
				border-color: $input-focus-color;
			}

			span {
				min-height: 20px;
				line-height: 20px;
				font-size: 16px;
			}

			&--opened {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: none;
				border-color: $input-focus-color;
			}

			&__tags {
				gap: 8px;

				input.treeselect-input__edit {
					font-size: 16px;
				}
			}

			input {
				background-color: rgb(247, 247, 247);
			}

			&__operators {
				right: 1px;

				svg {
					stroke: #35495e;
					width: 40px;
					min-width: 40px;
					height: 40px;
					stroke-width: 1px;
				}
			}
		}

		&-list {
			box-shadow: $box-shadow-dropdown;
			border-color: $input-hover-color;

			.treeselect-list__item--single-selected {
				background-color: $primary !important;
				color: $white;

				svg {
					stroke: white;
				}
			}

			&__empty-icon {
				display: none;
			}

			&__item,
			&__empty {
				min-height: 40px;
				line-height: 16px;

				&-icon svg {
					stroke: $color-grey-500;
				}

				&--focused {
					background-color: $primary !important;
					color: $white;

					svg {
						stroke: white;
					}
				}

				&-label,
				&-text {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}
}
</style>
