<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
		:id="id"
		:data-testid="dataTestid"
	>
		<ws-form-label
			v-if="label"
			:id="`${id}-label`"
			:disabled="disabled"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			{{ label }}
		</ws-form-label>
		<div class="control inputField">
			<div class="is-flex is-fullwidth">
				<div class="pr-0 w-30">
					<ws-form-select
						:data-testid="`${dataTestid}_country`"
						:disabled="disabled"
						label=""
						placeholder="Code"
						:searchable="true"
						:internal-search="true"
						:options="countryCodes"
						:show-multiselect-labels="true"
						:allow-null="false"
						@change="onChange"
						v-model="code"
						class="ws-phone-number-country-select"
						:error="error && !code ? '' : null"
					>
						<template #customOption="{ option }">
							<div v-if="option.$isDisabled"></div>
							<div
								v-else
								class="is-flex"
								:data-testid="`country_option_${option.value}`"
							>
								<img
									class="mr-1"
									width="25"
									v-if="option.img"
									:src="option.img"
									:alt="option.countryName || 'country flag'"
								/>
								<span class="countryName">
									{{ option.countryName }}
									<!-- <span v-if="option.original">
										({{ option.original }})
									</span> -->
								</span>
								<span class="countryCode">
									{{ option.dial_code }}
								</span>
							</div>
						</template>
						<template #customSingleLabel="{ option }">
							<label :id="id" class="is-flex">
								<img
									class="mr-1"
									width="25"
									v-if="option.img"
									:src="option.img"
									:alt="option.countryName || 'country flag'"
								/>
								<span style="line-height: 25px">
									{{ option.dial_code }}
								</span>
							</label>
						</template>
					</ws-form-select>
				</div>
				<div class="pl-2 w-70">
					<input
						class="input"
						:class="{
							'is-danger': error && (!number || code)
						}"
						:placeholder="numberPlaceholder"
						name="phone-number"
						:data-testid="`${dataTestid}_number`"
						v-model="number"
						@change="onChange"
						:disabled="disabled"
					/>
				</div>
			</div>
			<p class="help" style="margin-bottom: 0" v-if="labelUnderInput">
				{{ labelUnderInput }}
			</p>
			<p class="help is-danger" v-if="!!error">{{ error }}</p>
		</div>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";
import { countryCodesOptions } from "@/components/ws-phone-number/helpers.js";

export default {
	name: "WsPhoneNumber",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		modelValue: {
			type: Object,
			default: () => ({})
		},
		label: {
			type: [String, null],
			default: null
		},
		optional: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dataTestid: {
			type: null,
			default: "phone-number-input"
		},
		tooltip: {
			type: [String, null],
			default: null
		},
		tooltipPosition: {
			type: [String, null],
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		codeField: {
			type: String,
			default: "countryCode"
		},
		numberField: {
			type: String,
			default: "phoneNumber"
		},
		error: {
			type: String,
			default: null
		},
		numberPlaceholder: {
			type: String,
			default: ""
		},
		lang: {
			type: [String, null],
			default: null
		},
		isInline: {
			type: Boolean,
			default: false
		}
	},
	emits: ["update:modelValue"],
	data() {
		return {
			codeProxy: this.modelValue[this.codeField],
			numberProxy: this.modelValue[this.numberField],
			countryCodes: countryCodesOptions(this.lang || this.$i18n.locale)
		};
	},

	mounted() {
		this.codeProxy = this.modelValue.alphaCode;
		this.numberProxy = this.modelValue.phoneNumber;
	},

	methods: {
		onChange() {
			return this.$emit("update:modelValue", {
				...this.modelValue,
				[this.codeField]: this.codeProxy,
				[this.numberField]: this.numberProxy
			});
		}
	},

	computed: {
		code: {
			get() {
				return this.modelValue[this.codeField] || "";
			},
			set(val) {
				this.codeProxy = val;
			}
		},
		number: {
			get() {
				return this.modelValue[this.numberField] || "";
			},
			set(val) {
				this.numberProxy = val;
			}
		}
	}
};
</script>

<style lang="scss">
.ws-phone-number-country-select {
	.multiselect__content-wrapper {
		width: 576px !important;
		max-width: calc(100vw - 4em);
	}
	// separator
	.multiselect__option--disabled {
		padding: 0;
		height: 1px;
		background: $black !important;
	}
	li.multiselect__element,
	.multiselect__option {
		min-height: 1px !important;
	}
}
</style>
<style lang="scss" scoped>
.countryName {
	line-height: 25px;
	margin: 0 0.25em;
}
.countryCode {
	line-height: 25px;
	color: $grey;
}
.w-30 {
	width: 30%;
}
.w-70 {
	width: 70%;
}
.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
}

.optional {
	font-weight: 400;
}
</style>
