<template>
	<a
		class="ws-tabs-element"
		:class="{ 'is-active': isActive }"
		@click.prevent="$emit('click')"
	>
		<slot />
	</a>
</template>

<script>
export default {
	name: "WsTabsElement",

	emits: ["click"],
	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
$borderWidth: 2px;
a.ws-tabs-element {
	max-height: 3.5rem;
	margin: 0 1rem;
	padding: 1.5rem 0 calc(0.5rem - $borderWidth) 0;
	color: $color-grey-500 !important;
	font-weight: 400;
	border-bottom-width: $borderWidth;
	border-bottom-color: transparent;

	&:hover {
		border-bottom-color: $color-grey-500;
	}

	&.is-active {
		font-weight: 400;
		color: $color-grey-900 !important;
		border-bottom-color: $color-primary-500;
	}
}
</style>
