<template>
	<ws-form-select
		data-testid="ws-data-table-filter"
		:label="label"
		:placeholder="placeholder || $t('select')"
		v-model="selectedFilter"
		:options="availableItems"
		:allow-null="true"
		@change="changedFilter"
	/>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ""
		},

		placeholder: {
			type: String,
			default: ""
		},

		items: {
			type: Array,
			default: () => []
		},
		defaultFilterValue: {
			type: [String, null],
			default: null
		}
	},

	data() {
		return {
			selectedFilter: this.defaultFilterValue || null
		};
	},

	emits: ["selectedFilter"],
	methods: {
		changedFilter(newFilter) {
			// this.$nextTick(() => {
			if (newFilter === "") {
				this.selectedFilter = null;
			}
			this.$emit("selectedFilter", this.selectedFilter);
			// });
		}
	},

	computed: {
		availableItems() {
			return this.items.map((item) => {
				if (typeof item === "object") {
					return item;
				}
				return {
					label: item,
					value: item
				};
			});
		}
	}
};
</script>
