<template>
	<div
		class=""
		data-testid="rangedate-picker-div"
		:style="style"
		v-click-outside="() => toggleCalendar(true)"
	>
		<ws-form-label
			v-if="label"
			:id="id"
			:error="error"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			{{ label }}
		</ws-form-label>
		<label class="label-description" v-if="labelDescription">
			{{ labelDescription }}
		</label>
		<div>
			<div
				class="input-date"
				data-testid="rangedate-picker-input"
				:class="{ 'is-danger': !!error }"
				@click="toggleCalendar()"
			>
				<span v-if="configs.timepicker && selectedRange.start">
					{{ getDateString(selectedRange.start) }},
					{{ getTimeString(selectedRange.start) }}
					-
					{{
						isOneDaySelected()
							? ""
							: `${getDateString(selectedRange.end)},`
					}}
					{{ getTimeString(selectedRange.end) }}
				</span>
				<span v-else-if="selectedRange.start">
					{{ getDateString(selectedRange.start) }}
					- {{ getDateString(selectedRange.end) }}
				</span>
				<span v-else>{{ emptyMsg }}</span>
			</div>

			<div
				class="calendar box"
				:class="{
					'right-aligned': rightToLeft,
					'with-predefined': configs.predefinedRanges
				}"
				v-if="isOpen"
			>
				<div class="calendar-head">
					<div class="columns is-mobile">
						<div class="column is-11">
							<h2>{{ captions.title }}</h2>
						</div>
						<div class="column is-1 has-text-right">
							<em class="close" @click="toggleCalendar()"
								>&times;</em
							>
						</div>
					</div>
				</div>
				<div class="columns">
					<div
						v-if="configs.predefinedRanges"
						class="predefined-ranges"
					>
						<div
							v-if="canSetDateRange('last2Days')"
							class="predefined-button"
							data-testid="predefined-button-last2Days"
							@click="setDateRange('last2Days')"
						>
							{{ $t("datepicker-last-x-days", { days: 2 }) }}
						</div>
						<div
							v-if="canSetDateRange('last7Days')"
							class="predefined-button"
							data-testid="predefined-button-last7Days"
							@click="setDateRange('last7Days')"
						>
							{{ $t("datepicker-last-x-days", { days: 7 }) }}
						</div>
						<div
							v-if="canSetDateRange('last14Days')"
							class="predefined-button"
							data-testid="predefined-button-last14Days"
							@click="setDateRange('last14Days')"
						>
							{{ $t("datepicker-last-x-days", { days: 14 }) }}
						</div>
						<div
							v-if="canSetDateRange('last30Days')"
							class="predefined-button"
							data-testid="predefined-button-last30Days"
							@click="setDateRange('last30Days')"
						>
							{{ $t("datepicker-last-x-days", { days: 30 }) }}
						</div>
						<div
							v-if="canSetDateRange('last90Days')"
							class="predefined-button"
							data-testid="predefined-button-last90Days"
							@click="setDateRange('last90Days')"
						>
							{{ $t("datepicker-last-x-days", { days: 90 }) }}
						</div>
						<div
							v-if="canSetDateRange('lastYear')"
							class="predefined-button"
							data-testid="predefined-button-lastYear"
							@click="setDateRange('lastYear')"
						>
							{{ $t("datepicker-last-year") }}
						</div>
					</div>
					<div class="rows" style="flex-grow: 1">
						<div
							v-if="canSetDateRange('lastYear')"
							class="predefined-years"
							style="text-align: center"
						>
							<span class="predefined-button" @click="goPrevYear">
								{{ activeYearStart - 1 }}
							</span>

							<span style="font-size: larger; margin: 0 1em">
								{{ activeYearStart }}
							</span>

							<span class="predefined-button" @click="goNextYear">
								{{ activeYearStart + 1 }}
							</span>
						</div>
						<div
							class="columns calendar-wrap is-flex"
							style="flex-grow: 1"
						>
							<div class="column" v-if="showMonth">
								<div class="months-text">
									<span
										@click="goPrevYear"
										v-if="canSetDateRange('lastYear')"
									>
										<ws-icon
											class="left"
											icon="double-chevron"
											rotate="180"
										/>
									</span>
									<span @click="goPrevMonth">
										<ws-icon
											class="left"
											icon="chevron"
											rotate="180"
										/>
									</span>
									{{
										monthsLocale[activeMonthStart] +
										" " +
										activeYearStart
									}}
								</div>
								<ul :class="s.daysWeeks">
									<li
										v-for="item in shortDaysLocale"
										:key="item"
									>
										{{ item }}
									</li>
								</ul>
								<ul v-for="r in 6" :class="[s.days]" :key="r">
									<li
										:class="[
											{
												[s.daysSelected]:
													isDateSelected(
														r,
														i,
														'first',
														startMonthDay,
														endMonthDate
													),
												[s.daysInRange]: isDateInRange(
													r,
													i,
													'first',
													startMonthDay,
													endMonthDate
												),
												[s.dateDisabled]:
													isDateDisabled(
														r,
														i,
														'first',
														startMonthDay,
														endMonthDate
													)
											}
										]"
										v-for="i in numOfDays"
										:key="i"
										@click="selectFirstItem(r, i)"
									>
										{{
											getDayCell(
												r,
												i,
												startMonthDay,
												endMonthDate
											)
										}}
									</li>
								</ul>
							</div>
							<div class="column has-text-right">
								<div class="months-text">
									{{
										monthsLocale[startNextActiveMonth] +
										" " +
										activeYearEnd
									}}
									<span
										@click="goNextYear"
										v-if="canSetDateRange('lastYear')"
									>
										<ws-icon
											class="right"
											icon="double-chevron"
										/>
									</span>
									<span @click="goNextMonth"
										><ws-icon
											class="right"
											icon="chevron"
										/>
									</span>
								</div>
								<ul :class="s.daysWeeks">
									<li
										v-for="item in shortDaysLocale"
										:key="item"
									>
										{{ item }}
									</li>
								</ul>
								<ul v-for="r in 6" :class="[s.days]" :key="r">
									<li
										:class="[
											{
												[s.daysSelected]:
													isDateSelected(
														r,
														i,
														'second',
														startNextMonthDay,
														endNextMonthDate
													),
												[s.daysInRange]: isDateInRange(
													r,
													i,
													'second',
													startNextMonthDay,
													endNextMonthDate
												),
												[s.dateDisabled]:
													isDateDisabled(
														r,
														i,
														'second',
														startNextMonthDay,
														endNextMonthDate
													)
											}
										]"
										v-for="i in numOfDays"
										:key="i"
										@click="selectSecondItem(r, i)"
									>
										{{
											getDayCell(
												r,
												i,
												startNextMonthDay,
												endNextMonthDate
											)
										}}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="columns" style="align-items: center">
					<div class="column is-8">
						<div
							v-if="configs.timepicker"
							class="time-picker is-flex"
						>
							<div class="time-picker__start">
								<div class="time-picker__label">
									{{
										getDateString(
											dateRange.start,
											"MMMM DD"
										)
									}}
								</div>
								<div class="time-picker__ctrl">
									<input
										type="text"
										class="input is-small"
										placeholder="00:00"
										v-model="startTime"
										@blur="checkTimeValues"
									/>
								</div>
							</div>
							<div class="time-picker__dash">-</div>
							<div class="time-picker__end">
								<div class="time-picker__label">
									{{
										getDateString(dateRange.end, "MMMM DD")
									}}
								</div>
								<div class="time-picker__ctrl">
									<input
										type="text"
										class="input is-small"
										placeholder="23:59"
										v-model="endTime"
										@blur="checkTimeValues"
									/>
									&nbsp;
									<ws-button
										is-primary
										is-outlined
										is-small
										class="reset ml-10"
										@click="resetTimeRange"
									>
										{{ $t("reset-time") }}
									</ws-button>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-4 has-text-right">
						<ws-button
							is-primary
							class="apply"
							@click="setDateValue"
						>
							{{ captions.ok_button }}
						</ws-button>
					</div>
				</div>
			</div>
		</div>
		<p class="help is-danger" v-if="!!error">
			{{ error }}
		</p>
	</div>
</template>

<script src="./ws-range-date-picker.js"></script>

<style lang="scss" scoped>
$color-calendar-day: #333;
$color-calendar-input-chevron: #666;
$color-calendar-week: #999;
$color-calendar-day-disabled: #999;

// reset .columns width to default Bulma value
.columns {
	width: auto;
}

.label-description {
	display: block;
	font-size: $size-7;
	font-weight: $weight-normal;
	color: $grey;
	margin-top: -0.5rem;
	margin-bottom: 0.2rem;
}

$predefinedWidth: 120px;

.input-date {
	display: block;
	border: 1px solid $input-border-color;
	padding-right: 2.5em;
	padding-left: 7px;
	padding-top: calc(0.5em - 1px);
	padding-bottom: calc(0.5em - 1px);
	border-radius: $radius;
	cursor: pointer;
	// z-index: 1000;
	position: relative;
	width: 100%;

	&::after {
		position: absolute;
		right: 1.125em;
		top: 12px;
		content: " ";
		border: solid $color-calendar-input-chevron;
		border-radius: 2px;
		border-width: 0 3px 3px 0;
		// margin-top: 7px;
		height: 0.625em;
		width: 0.625em;
		transform: rotate(45grad);
	}
	&.is-danger {
		border-color: $color-danger-500;
	}
}

.calendar-head {
	border-bottom: 1px solid $color-grey-200;

	h2 {
		font-size: 1.3rem;
		font-weight: 400;
		line-height: 1;
		margin: 0 !important;
	}
}

.months-text {
	text-align: center;
	font-weight: bold;

	.left {
		float: left;
		margin-left: 5px;
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
	.goPrevMonth {
		background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5NCAzMS40OTQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMxLjQ5NCAzMS40OTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTEwLjI3Myw1LjAwOWMwLjQ0NC0wLjQ0NCwxLjE0My0wLjQ0NCwxLjU4NywwYzAuNDI5LDAuNDI5LDAuNDI5LDEuMTQzLDAsMS41NzFsLTguMDQ3LDguMDQ3aDI2LjU1NCAgYzAuNjE5LDAsMS4xMjcsMC40OTIsMS4xMjcsMS4xMTFjMCwwLjYxOS0wLjUwOCwxLjEyNy0xLjEyNywxLjEyN0gzLjgxM2w4LjA0Nyw4LjAzMmMwLjQyOSwwLjQ0NCwwLjQyOSwxLjE1OSwwLDEuNTg3ICBjLTAuNDQ0LDAuNDQ0LTEuMTQzLDAuNDQ0LTEuNTg3LDBsLTkuOTUyLTkuOTUyYy0wLjQyOS0wLjQyOS0wLjQyOS0xLjE0MywwLTEuNTcxTDEwLjI3Myw1LjAwOXoiIGZpbGw9IiMwMDZERjAiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
	}

	.right {
		float: right;
		margin-right: 5px;
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
	.goNextMonth {
		background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMxLjQ5IDMxLjQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMS40OSAzMS40OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cGF0aCBkPSJNMjEuMjA1LDUuMDA3Yy0wLjQyOS0wLjQ0NC0xLjE0My0wLjQ0NC0xLjU4NywwYy0wLjQyOSwwLjQyOS0wLjQyOSwxLjE0MywwLDEuNTcxbDguMDQ3LDguMDQ3SDEuMTExICBDMC40OTIsMTQuNjI2LDAsMTUuMTE4LDAsMTUuNzM3YzAsMC42MTksMC40OTIsMS4xMjcsMS4xMTEsMS4xMjdoMjYuNTU0bC04LjA0Nyw4LjAzMmMtMC40MjksMC40NDQtMC40MjksMS4xNTksMCwxLjU4NyAgYzAuNDQ0LDAuNDQ0LDEuMTU5LDAuNDQ0LDEuNTg3LDBsOS45NTItOS45NTJjMC40NDQtMC40MjksMC40NDQtMS4xNDMsMC0xLjU3MUwyMS4yMDUsNS4wMDd6IiBmaWxsPSIjMDA2REYwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=");
	}
}

.calendar {
	position: absolute;
	overflow: hidden;
	width: 100%;
	font-size: 12px;
	z-index: 1000;
	box-shadow: $box-shadow-rangedate-picker-calendar;
	left: 0;

	@include desktop {
		width: 520px;
		left: auto;
	}

	&.right-aligned {
		@include desktop {
			right: 75px;
		}
	}

	ul {
		list-style-type: none;
	}
}
.calendar.with-predefined {
	@include desktop {
		width: calc(520px + #{$predefinedWidth});
		left: auto;
	}
}

.close {
	padding: 0 10px;
	font-size: 32px;
	font-style: normal;
	display: block;
	transform: translate(-6px, -5px);
	cursor: pointer;
	line-height: 1;
}

.calendar-wrap {
	padding-top: 1rem;
	margin-bottom: 0;
}

.range {
	float: left;
	padding-left: 1rem;
	margin: 10px 0 0 0;
}

.calendar_weeks {
	margin: 0 !important;
	padding: 10px 0;
	width: auto;

	li {
		display: inline-block;
		width: 13.6%;
		color: $color-calendar-week;
		text-align: center;
	}
}

.calendar_days {
	margin: 0 !important;
	padding: 0;

	li {
		display: inline-block;
		width: 13.6%;
		color: $color-calendar-day;
		text-align: center;
		cursor: pointer;
		line-height: 2em;

		&:hover {
			background: $light;
			color: $black;
		}

		& + li {
			margin-top: 0;
		}
	}
}

li {
	&.calendar_days--disabled {
		pointer-events: none;
		color: $color-calendar-day-disabled;
	}

	&.calendar_days_selected {
		background: darken($color-primary-500, 8%);
		color: $white;
	}

	&.calendar_days_in-range {
		background: $color-primary-500;
		color: $white;
	}
}

.button {
	&.apply {
		min-width: 7rem;
		height: auto;
	}

	&.is-text {
		text-decoration: none;
		box-shadow: none;
		background: transparent;

		&:hover {
			background: $light;
		}
	}
}
.button.reset {
	width: auto;
}

.time-picker {
	align-items: center;
	transform: translateY(2px);

	&__dash {
		padding: 0.5rem 0.5rem 0 0.5rem;
	}

	&__label {
		font-weight: bold;
		font-size: 0.8rem;
		height: 1em;
		transform: translateY(-6px);
	}

	&__ctrl {
		display: flex;
		.input {
			/*font-size: 13px;*/
			width: 6em;
			display: inline-flex;
		}
	}
}

.predefined-ranges {
	min-width: $predefinedWidth;
	padding-top: 0.75em;
	padding-right: 1em;
	margin-right: 1em;
	margin-top: 1em;
	border-right: 1px solid $color-grey-200;

	.predefined-button {
		cursor: pointer;
		width: 100%;
		border: 1px solid $light;
		margin: 0.25em;
		padding: 0.25em 0.5em;
		border-radius: 7px;
	}
	.predefined-button:focus,
	.predefined-button:hover {
		background: $light;
		color: $black;
	}
}
.predefined-years {
	padding-top: 0.75em;
	padding-right: 1em;
	margin-right: 1em;
	margin-top: 1em;
	font-weight: bold;

	.predefined-button {
		cursor: pointer;
		width: 100%;
		border: 1px solid $light;
		margin: 0.25em;
		padding: 0.25em 0.5em;
		border-radius: 7px;
	}
	.predefined-button:focus,
	.predefined-button:hover {
		background: $light;
		color: $black;
	}
}
// .predefined-ranges:after {
// 	border-right: 1px solid $color-grey-200;
// 	content: "";
// 	display: block;
// 	height: 100%;
// 	left: calc(100% + 0.5em);
// 	position: relative;
// 	top: -61%;
// 	width: 0;
// }
</style>
