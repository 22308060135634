export default class Filters {
	constructor(columns = []) {
		this.filtersGrouped = {};
		columns.forEach((column) => {
			if (column.filter) {
				this.addFilter(column.name || column.selector, column.selector);
			}
		});
	}

	addFilter(displayName, selectorName) {
		this.filtersGrouped[selectorName] = {
			name: displayName,
			selector: selectorName,
			items: []
		};
	}

	addItemToFilter(filterSelector, item) {
		this.filtersGrouped[filterSelector].items.push(item);
	}

	get selectors() {
		return Object.keys(this.filtersGrouped);
	}

	get finalFilters() {
		return Object.keys(this.filtersGrouped).map((filter) => {
			const allItems = [...new Set(this.filtersGrouped[filter].items)];
			return Object.assign({}, this.filtersGrouped[filter], {
				items: allItems.sort() // simple sort here due to performance. naturalSort is better but it's havier to process
			});
		});
	}

	getFiltersFromRow(row) {
		const final = {};
		for (const filterSelector of this.selectors) {
			const rowValue = row[filterSelector];
			if (
				rowValue &&
				rowValue !== "" &&
				["string", "number"].includes(typeof rowValue)
			) {
				final[filterSelector] = rowValue.toString().trim();
			}
		}
		return final;
	}

	createFilterItemsFromRow(row) {
		const filtersFromRow = this.getFiltersFromRow(row);
		for (const [filterKey, value] of Object.entries(filtersFromRow)) {
			if (row[filterKey] !== undefined || row[filterKey] !== null) {
				this.addItemToFilter(filterKey, value);
			}
		}
	}
}
