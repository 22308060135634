<template>
	<nav
		class="pagination is-centered"
		role="navigation"
		aria-label="pagination"
	>
		<ul
			class="pagination-list"
			style="list-style: none"
			v-if="isServerPagination"
		>
			<li />
			<li v-if="hasPrevPage" @click="goTo(prevPage)">
				<a
					class="pagination-link"
					:aria-label="`Goto page ${prevPage}`"
					>{{ $t("components.paginator.previous") }}</a
				>
			</li>

			<li>
				<a class="pagination-link is-current">{{ page }}</a>
			</li>

			<li v-if="hasNextPage" @click="goTo(nextPage)">
				<a
					class="pagination-link"
					:aria-label="`Goto page ${nextPage}`"
				>
					{{ $t("components.paginator.next") }}
				</a>
			</li>
		</ul>
		<ul
			class="pagination-list"
			style="list-style: none"
			v-else
			v-show="totalPages >= 2"
		>
			<li />
			<li v-if="hasPrevPage" @click="goTo(prevPage)">
				<a
					class="pagination-link"
					:aria-label="`Goto page ${prevPage}`"
				>
					{{ $t("components.paginator.previous") }}
				</a>
			</li>
			<!-- 1 -->
			<li
				@click="goTo(1)"
				:data-testid="
					currentPage === 1 ? 'paginator-current-page' : null
				"
			>
				<a
					class="pagination-link"
					:class="{ 'is-current': currentPage === 1 }"
					aria-label="Goto page 1"
				>
					1
				</a>
			</li>
			<!-- ... -->
			<li v-if="allPagesInPaginator.showEllipsesBefore">
				<span class="pagination-ellipsis">&hellip;</span>
			</li>

			<!-- visible pages -->
			<template v-for="pageItem of allPagesInPaginator.pages">
				<li
					:data-testid="
						currentPage === pageItem
							? 'paginator-current-page'
							: null
					"
					v-if="hasPage(pageItem)"
					:key="`prev_page_${pageItem}`"
					@click="goTo(pageItem)"
				>
					<a
						class="pagination-link"
						:class="{ 'is-current': currentPage === pageItem }"
						:aria-label="`Goto page ${pageItem}`"
					>
						{{ pageItem }}
					</a>
				</li>
			</template>

			<!-- ... -->
			<li v-if="allPagesInPaginator.showEllipsesAfter">
				<span class="pagination-ellipsis">&hellip;</span>
			</li>

			<!-- lastPage  -->
			<li
				v-if="totalPages && totalPages >= 2"
				:data-testid="
					currentPage === totalPages ? 'paginator-current-page' : null
				"
				@click="goTo(totalPages)"
			>
				<a
					class="pagination-link"
					:class="{ 'is-current': currentPage === totalPages }"
					:aria-label="`Goto page ${totalPages}`"
				>
					{{ totalPages }}
				</a>
			</li>

			<li
				v-if="hasNextPage"
				@click="goTo(nextPage)"
				data-testid="paginator-next-page"
			>
				<a
					class="pagination-link"
					:aria-label="`Goto page ${nextPage}`"
				>
					{{ $t("components.paginator.next") }}
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "Paginator",

	props: {
		page: {
			type: Number,
			default: 1
		},
		totalPages: {
			type: [Number, null],
			default: null,
			validator: (value) => {
				return value === null || Number.isInteger(parseInt(value));
			}
		},
		isServerPagination: {
			type: Boolean,
			default: false
		},
		serverPaginationHasNextPage: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {};
	},

	emits: ["changedPage"],
	methods: {
		goTo(page) {
			this.$emit("changedPage", page);
		},

		hasPage(page) {
			return Number(page) > 0 && Number(page) <= this.totalPages;
		}
	},

	computed: {
		totalPagesDisplayPaginator() {
			return 7;
		},

		allPagesInPaginator() {
			if (
				this.totalPages - 2 >= 1 &&
				this.totalPages <= this.totalPagesDisplayPaginator + 2 // + 2 => first1 and last page
			) {
				const pages = Array(this.totalPages - 2).fill(null);
				return {
					pages: pages
						.map((_, index) => {
							const page = index + 2;
							return this.hasPage(page) ? page : null;
						})
						.filter(Boolean),
					showEllipsesBefore: false,
					showEllipsesAfter: false
				};
			}

			const qttPagesPerSide = Math.floor(
				this.totalPagesDisplayPaginator / 2
			);

			const firstPage =
				this.currentPage - qttPagesPerSide < 2
					? 2
					: this.currentPage - qttPagesPerSide;
			const pages = Array(this.totalPagesDisplayPaginator)
				.fill(null)
				.map((_, index) => {
					const page = firstPage + index;
					return this.hasPage(page) && page !== this.totalPages
						? page
						: null;
				})
				.filter(Boolean);
			return {
				pages,
				showEllipsesBefore:
					!pages.includes(2) && pages.length >= qttPagesPerSide,
				showEllipsesAfter:
					!pages.includes(this.totalPages - 1) && pages.length > 1
			};
		},

		hasNextPage() {
			if (this.isServerPagination) {
				return this.serverPaginationHasNextPage;
			}

			return this.nextPage <= this.totalPages;
		},

		nextPage() {
			return this.page + 1;
		},

		hasPrevPage() {
			return this.prevPage > 0;
		},

		prevPage() {
			return this.page - 1;
		},

		currentPage() {
			return this.page;
		}
	}
};
</script>

<style lang="scss" scoped>
.pagination {
	margin-top: -150px;
	padding-bottom: 30px;
}
.is-current {
	font-weight: $weight-semibold;
}
</style>
