export default class LogMonitoring {
	constructor(isEnabled = false) {
		this.isEnabled = isEnabled;
		// Singleton to avoid multiple instances
		if (LogMonitoring.instance) {
			return LogMonitoring.instance;
		}

		if (isEnabled) {
			this._initMonitoring();
		}

		LogMonitoring.instance = this;
	}

	// implementation of this method should be done on the class that extends this one
	_initMonitoring() {
		// eslint-disable-next-line no-console
		console.error("Not implemented");
	}

	notify(...args) {
		// eslint-disable-next-line no-console
		console.error(...args);
	}
}
