<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
	>
		<ws-form-label
			v-if="label"
			:id="id"
			:disabled="disabled"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			<slot name="label">
				{{ label }}
			</slot>
		</ws-form-label>
		<label class="label-description" v-if="labelDescription">
			{{ labelDescription }}
		</label>
		<div class="control is-expanded inputField">
			<div
				class="input tag-input"
				:class="{ 'is-danger': !!error }"
				@click="() => focusInput()"
			>
				<div
					v-for="(tag, index) in tags"
					:key="tag"
					class="multiselect__tag"
				>
					{{ tag }}
					<i
						:data-testid="`remove_tag_${tag}`"
						aria-hidden="true"
						tabindex="1"
						class="multiselect__tag-icon"
						@click="removeTag(index)"
					/>
				</div>
				<input
					:aria-labelledby="id"
					:id="id"
					:data-testid="dataTestid"
					type="text"
					:placeholder="placeholder"
					:disabled="disabled"
					class="tag-input__text"
					ref="tagInputText"
					@keydown="addTag"
					@keydown.delete="removeLastTag"
					@blur="addTag"
				/>
			</div>
			<p
				class="help mb-0"
				style="margin-top: -10px"
				v-if="labelUnderInput"
				v-html="labelUnderInput"
			></p>
			<p class="help is-danger" style="margin-top: -10px" v-if="!!error">
				{{ error }}
			</p>
		</div>
	</div>
</template>
<script>
import { generateRandomId } from "@/helpers/functions.helper.js";

export default {
	name: "WsFormMultivaluesInput",

	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: String,
			default: ""
		},
		isInline: {
			type: Boolean,
			default: false
		},
		labelDescription: {
			type: String,
			default: ""
		},
		optional: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: [Array, null],
			default: null
		},
		placeholder: {
			type: [String, null],
			default: null
		},
		tooltip: {
			type: [String, null],
			default: null
		},
		tooltipPosition: {
			type: [String, null],
			default: "top"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		dataTestid: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			tags: []
		};
	},
	mounted() {
		if (!this.modelValue) {
			return [];
		}
		this.tags = [...this.modelValue] || [];
	},

	emits: ["update:modelValue", "change"],
	methods: {
		focusInput() {
			this.$refs.tagInputText?.focus();
		},
		addTag(event) {
			event.stopPropagation();
			if (
				import.meta.env.NODE_ENV === "test" &&
				["Enter", ",", ";"].indexOf(event.key) === -1 // needed for test environemnt
			) {
				return;
			}
			if (
				[
					0, //click out of the form
					32, //space
					13, // enter
					188, // ,
					186, // ;
					59 // , in Firefox
				].indexOf(event.which) === -1
			) {
				return;
			}
			event.preventDefault();
			var val = event.target.value.trim();
			if (val?.length > 0) {
				if (this.tags.indexOf(val) === -1) {
					this.tags.push(val);
				}
				event.target.value = "";
			}

			this.$emit("update:modelValue", this.tags);
			this.$emit("change", this.tags);
		},
		removeTag(index) {
			if (this.disabled) {
				return;
			}
			event.stopPropagation();
			this.tags.splice(index, 1);
			this.$emit("update:modelValue", this.tags);
			this.$emit("change", this.tags);
		},
		removeLastTag(event) {
			event.stopPropagation();
			if (event.target.value.length === 0) {
				this.removeTag(this.tags.length - 1);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.tag-input {
	width: 100%;
	font-size: 0.9em;
	min-height: 2.5em;
	box-sizing: border-box;
	padding: 0 10px;
	display: inline-block;
	height: auto;
}

.tag-input__tag {
	height: 30px;
	float: left;
	margin-right: 10px;
	background-color: $color-grey-400;
	margin-top: 10px;
	line-height: 30px;
	padding: 0 5px;
	border-radius: 5px;
}

.tag-input__tag > span {
	cursor: pointer;
	opacity: 0.75;
}

.tag-input__text {
	border: none;
	outline: none;
	font-size: 1rem;
	line-height: 1.5;
	background: none;
	height: calc(2.5em - 2px);
}
.multiselect__tag {
	margin-bottom: unset;
}

.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
	margin: 0;
	line-height: 40px;
}
</style>
