import axios from "axios";
const STATIC_URL = import.meta.env.VITE_STATIC_URL;
const API = import.meta.env.VITE_API;
import sha1 from "js-sha1";

const IS_TEST_ENV = import.meta.env.MODE === "test";

export const PARAM_NAME = "forceClient";
/**
 * key of object is part of the hostname company will use to access console (ex: console.sontay.com) AND the name of the file (logo-xxx, logofull-xxx, favicon-xxx)
 * value of object is the friendly name that will be used as title
 */
export const CLIENTS = {
	advizeo: "Advizeo",
	egain: "[e]gain",
	sontay: "Sontay",
	siemens: "Siemens",
	wattsense: "Wattsense"
};

const DEFAULT_PRODUCT_NAMES = {
	generic: "Box",
	genericPlural: "Boxes",
	box: "Tower",
	boxes: "Towers",
	hub: "Bridge",
	hubs: "Bridges"
};
const SIEMENS_PRODUCT_NAMES = {
	generic: "Box",
	genericPlural: "Boxes",
	box: "Connect Box",
	boxes: "Connect Boxes",
	hub: "Connect Box On Premise",
	hubs: "Connect Boxes On Premise"
};

export class ClientName {
	clientName = null;
	forcedClient = null;
	consoleTitle = null;
	stringsLoaded = false;
	productNames = DEFAULT_PRODUCT_NAMES;
	locationHash = null;

	constructor() {
		this.clientName = this.getClientName();
		this.locationHash = this.getLocationHash();
	}

	getClientName() {
		// needed for testing different window.location.hostname
		if (this.clientName && !IS_TEST_ENV) {
			return this.clientName;
		}

		if (!IS_TEST_ENV) {
			// check url query string to check if user forced a specific client
			// how to use it: console.s.wattsense.com?forceClient=siemens | ?forceClient=egain | ?forceClient=sontay
			const params = new URL(window.location).searchParams;
			if (params.has(PARAM_NAME)) {
				const forcedClient = params.get(PARAM_NAME);
				const availableClients = Object.keys(CLIENTS);
				if (availableClients.includes(forcedClient)) {
					this.forcedClient = forcedClient;
					return forcedClient;
				}
			}
		}

		const hostname = window.location.hostname;
		const clients = Object.keys(CLIENTS);
		for (const client of clients) {
			if (hostname.includes(client)) {
				return client;
			}
		}

		return "wattsense";
	}

	async getConsoleStrings() {
		if (this.stringsLoaded) {
			return this.productNames;
		}
		const defaultNames =
			this.clientName === "siemens"
				? SIEMENS_PRODUCT_NAMES
				: DEFAULT_PRODUCT_NAMES;

		return await axios
			.get(`${API}/console`)
			.then((res) => {
				this.productNames = { ...defaultNames, ...res?.data };
				this.stringsLoaded = true;
				this.consoleTitle =
					this.productNames.name || this.humanClientName;
				return this.productNames;
			})
			.catch(() => {
				this.consoleTitle = this.humanClientName;
				this.productNames = defaultNames;
				this.stringsLoaded = true;
				return this.productNames;
			});
	}

	async getConsoleTitle() {
		if (this.consoleTitle) {
			return this.consoleTitle;
		}
		await this.getConsoleStrings();
		return this.consoleTitle;
	}

	getLocationHash() {
		// needed for testing different window.location.hostname
		if (this.locationHash && !IS_TEST_ENV) {
			return this.locationHash;
		}
		return sha1(window.location.hostname).toUpperCase();
	}

	get humanClientName() {
		const client = this.getClientName();
		return CLIENTS[client] || "Wattsense";
	}

	async setFavicon() {
		const newFavicon = document.createElement("link");
		newFavicon.id = "favicon";
		newFavicon.type = "image/x-icon";
		newFavicon.rel = "icon";
		newFavicon.href = await getFaviconUrl();

		const oldIcon = document.getElementById("favicon");
		if (oldIcon) {
			document.head.removeChild(oldIcon);
		}
		document.head.appendChild(newFavicon);
	}

	async setTitle() {
		document.getElementsByTagName("title")[0].text =
			await this.getConsoleTitle();
	}
}

const client = new ClientName();

export const useClientName = () => ({
	clientName: client.getClientName(),
	productNames: client.productNames,
	setClientTitleAndFavicon: async () => {
		await client.setFavicon();
		await client.getConsoleStrings();
		await client.setTitle();
	}
});

export async function getFaviconUrl() {
	if (client.forcedClient) {
		return (
			import.meta.env.BASE_URL +
			`favicon-${client.forcedClient}.ico?=${Math.random()}`
		);
	}
	// Math.random() is to avoid browser displaying cached favicon
	return axios
		.get(`${STATIC_URL}/${client.getLocationHash()}/favicon`)
		.then(() => {
			return `${STATIC_URL}/${client.getLocationHash()}/favicon?=${Math.random()}`;
		})
		.catch(() => {
			return (
				import.meta.env.BASE_URL +
				`favicon-${client.getClientName()}.ico?=${Math.random()}`
			);
		});
}
export async function getBigLogoUrl() {
	if (client.forcedClient) {
		return new URL(
			`../../assets/imgs/logos/logofull-${client.forcedClient}.svg`,
			import.meta.url
		).href;
	}
	return axios
		.get(`${STATIC_URL}/${client.getLocationHash()}/logo_full`)
		.then(() => {
			return `${STATIC_URL}/${client.getLocationHash()}/logo_full`;
		})
		.catch(async () => {
			return new URL(
				`../../assets/imgs/logos/logofull-${client.getClientName()}.svg`,
				import.meta.url
			).href;
		});
}
export async function getSmallLogoUrl() {
	if (client.forcedClient) {
		return new URL(
			`../../assets/imgs/logos/logo-${client.forcedClient}.svg`,
			import.meta.url
		).href;
	}
	return axios
		.get(`${STATIC_URL}/${client.getLocationHash()}/logo_collapsed`)
		.then(() => {
			return `${STATIC_URL}/${client.getLocationHash()}/logo_collapsed`;
		})
		.catch(async () => {
			return new URL(
				`../../assets/imgs/logos/logo-${client.getClientName()}.svg`,
				import.meta.url
			).href;
		});
}

export default {
	install(app) {
		app.config.globalProperties.$clientName = client.getClientName();
		app.config.globalProperties.$staticImgUrl = `${STATIC_URL}/${client.getLocationHash()}`;
		app.config.globalProperties.$setClientTitleAndFavicon = async () => {
			await client.setFavicon();
			await client.getConsoleStrings();
			await client.setTitle();
		};
	}
};
