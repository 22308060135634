<template>
	<div
		data-testid="dropdown"
		v-click-outside="closeDropdown"
		class="dropdown"
		:class="{
			'is-active': showDropdown,
			'plain-text': plainText
		}"
	>
		<div
			class="dropdown-trigger"
			ref="dropdown"
			@click.stop="showDropdown = !showDropdown"
			role="dropdown-menu"
		>
			<slot name="trigger">
				<ws-button
					is-primary
					:is-outlined="outlined"
					:is-small="size === 'small'"
					:is-normal="size === 'normal'"
					:is-medium="size === 'medium'"
					:is-large="size === 'large'"
					:disabled="disabled"
					:data-testid="dataTestid"
					aria-haspopup="true"
					aria-controls="dropdown-menu"
					:aria-label="ariaLabel"
					icon-position="right"
				>
					<span v-if="title || $slots.title">
						<slot name="title"> {{ title }} </slot>
					</span>
					<template v-if="showArrow" #icon>
						<ws-icon :size="iconSize" :icon="icon" />
					</template>
				</ws-button>
			</slot>
		</div>
		<div
			class="dropdown-menu"
			:style="`position: ${computePositionStrategy};`"
			role="menu"
			ref="dropdownMenu"
		>
			<div class="dropdown-content">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";
import {
	computePosition,
	flip,
	offset,
	getOverflowAncestors
} from "@floating-ui/dom";
export default {
	name: "WsDropdown",

	setup() {
		const { notify: logNotifier } = useLogMonitoring();

		return { logNotifier };
	},

	props: {
		title: {
			type: String,
			default: ""
		},
		showArrow: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dataTestid: {
			type: String,
			default: null
		},
		ariaLabel: {
			type: String,
			default: "Dropdown Menu"
		},
		icon: {
			type: String,
			default: "angle-down"
		},
		outlined: {
			type: Boolean,
			default: true
		},
		plainText: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: "normal",
			validate: function (value) {
				return ["small", "normal", "medium", "large"].includes(value);
			}
		},
		// allow floating-ui to position dropdown-menu on top of the dropdown-button if there's no space below
		allowFlipYAxis: {
			type: Boolean,
			default: true
		},
		flipYAxis: {
			type: Array,
			default: () => ["top-start", "top-end"]
		},
		// css-position for dropdown-menu: absolute or fixed
		computePositionStrategy: {
			type: String,
			default: "fixed"
		},
		// initial placement of dropdown
		placement: {
			type: String,
			default: "bottom-start",
			validate: function (value) {
				return [
					"bottom-start",
					"bottom-end",
					"top-start",
					"top-end"
				].includes(value);
			}
		}
	},

	emits: ["toggle-dropdown", "scrolling"],
	watch: {
		showDropdown: function (_newStatus) {
			this.$emit("toggle-dropdown", _newStatus);
		},

		dropdownRef: function (_ref) {
			// check scroll only when dropdown-menu is position fixed, in order to update dropdown-menu position when a scroll happens
			if (_ref && this.computePositionStrategy === "fixed") {
				getOverflowAncestors(_ref).forEach((el) => {
					this.elementsToRemoveEventListener.push(el);
					el.addEventListener(
						"scroll",
						this.updatePositionDropdownMenu
					);
					el.addEventListener(
						"resize",
						this.updatePositionDropdownMenu
					);
				});
			}
		}
	},

	mounted() {
		if (this.wsIcon) {
			this.logNotifier(
				"[font-awesome-icon] ws-dropdown is using ws-icon prop while it's depracted. Use icon only."
			);
		}
	},

	beforeUnmount() {
		this.elementsToRemoveEventListener.forEach((el) => {
			el.removeEventListener("scroll", this.updatePositionDropdownMenu);
			el.removeEventListener("resize", this.updatePositionDropdownMenu);
		});
	},

	data() {
		return {
			showDropdown: false,

			// those two vars below are necessary to position dropdown-menu in a good positon relative to button
			dropdownRef: null, // reference for dropdown button
			dropdownMenuRef: null, // reference for dropdown-menu
			elementsToRemoveEventListener: [] // all parents from dropdown will receive an eventListener('scroll'|'resize') to move dropdown
		};
	},

	updated() {
		if (this.showDropdown) {
			this.updatePositionDropdownMenu();
		}
	},

	computed: {
		iconSize() {
			switch (this.size) {
				case "small":
					return "sm";
				case "large":
					return "lg";
				case "medium":
				case "normal":
				default:
					return "1x";
			}
		}
	},
	methods: {
		updatePositionDropdownMenu() {
			if (!this.showDropdown) {
				return;
			}
			if (!this.dropdownRef) {
				this.dropdownRef = this.$refs.dropdown;
			}
			if (!this.dropdownMenuRef) {
				this.dropdownMenuRef = this.$refs.dropdownMenu;
			}

			// required on test to make sure this method is being called when scrolling
			if (import.meta.env.NODE_ENV === "test") {
				this.$emit("scrolling");
			}

			computePosition(this.dropdownRef, this.dropdownMenuRef, {
				placement: this.placement ?? "bottom-start",
				strategy: this.computePositionStrategy,
				middleware: [
					// flip dropdown-menu to make it always visible to user (only bottom and top)
					flip({
						mainAxis: this.allowFlipYAxis,
						fallbackPlacements: [
							"bottom-start",
							"bottom-end",
							...[
								this.allowFlipYAxis
									? this.flipYAxis ?? ["top-start", "top-end"]
									: []
							]
						].flatMap((i) => i)
					}),
					// if there's a margin under dropdown, move up dropdown-menu to fill the gap margin will create
					offset(({ placement }) => {
						const offset = Number(
							getComputedStyle(
								this.dropdownRef?.children[0]
							)?.marginBottom?.replace(/[A-z]/gi, "") || 0
						);
						return {
							mainAxis:
								placement.search("bottom") >= 0 ? -offset : 0
						};
					})
				]
			}).then(({ x, y }) => {
				Object.assign(this.dropdownMenuRef.style, {
					left: `${x}px`,
					top: `${y}px`
				});
			});
		},

		closeDropdown() {
			this.showDropdown = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown {
	.button {
		min-width: 40px;
	}
	&.plain-text {
		button.button {
			border: 0 none !important;
			color: $body-color !important;
			padding: 0;
			height: auto;
			font-weight: inherit;

			&:hover,
			&:active,
			&:focus {
				background: inherit !important;
				outline: none !important;
				box-shadow: none !important;
				color: $dark !important;
			}
		}
	}
	// .svg-inline--fa {
	// 	// width: 0.375em;
	// 	max-height: 100%;
	// }
}
</style>
