<template>
	<div data-testid="ws-loading-spinner" v-show="showSpinner">
		<div class="bg" />
		<div class="lds-ring">
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
</template>

<script setup>
import { EventBus } from "@/eventbus.js";
import { ref, computed } from "vue";

const showState = ref(false);
const dontShow = ref(false);
const props = defineProps({
	useXhr: {
		type: Boolean,
		default: true
	},
	show: {
		type: Boolean,
		default: false
	}
});

const showSpinner = computed(() => {
	// ignore if is using xhr eventbus method to avoid conflict between axios showing or not with prop
	if (props.useXhr) {
		return showState.value;
	}
	return props.show;
});

if (props.useXhr) {
	EventBus.$on("xhr", (status) => {
		toggleStatus(status);
	});
	EventBus.$on("dontShowLoading", (status = true) => {
		dontShow.value = status;
	});
}

function toggleStatus(status) {
	if (dontShow.value === true) {
		return;
	}
	showState.value = status;
}
</script>

<style lang="scss" scoped>
$size: 64px;
$color: $white;

.bg {
	z-index: 9998;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba($black, 0.3);
}

.lds-ring {
	z-index: 9999;
	position: fixed;
	top: 30%;
	left: 50%;
	display: inline-block;
	width: $size;
	height: $size;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border: 6px solid $color;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
