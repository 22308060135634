<template>
	<div
		:data-testid="dataTestid"
		class="accordion-wrapper"
		:class="{
			'is-boxed': props.isBoxed,
			'is-checkbox': props.useCheckbox
		}"
	>
		<div
			class="accordion-title"
			:class="{
				'is-danger': props.isDanger,
				'is-info': props.isInfo,
				'is-success': props.isSuccess,
				'is-primary': props.isPrimary,
				'is-warning': props.isWarning,
				collapsed: !showContent,
				disabled: props.disabled
			}"
			@click="!props.disabled && !props.useCheckbox && toggleContent()"
		>
			<span
				v-if="!props.useCheckbox"
				class="is-flex is-align-items-center"
			>
				<span class="iconaccordion-handle" v-if="props.isBoxed">
					<ws-icon size="sm" :icon="iconForAccordion" />
				</span>
				<slot name="title">
					<span :class="titleClass">
						{{ title }}
					</span>
				</slot>
				<span class="iconaccordion-handle" v-if="!props.isBoxed">
					<ws-icon size="sm" :icon="iconForAccordion" />
				</span>
			</span>
			<span v-else class="is-flex is-align-items-center">
				<ws-form-checkbox
					v-model="showContent"
					:tooltip="tooltip"
					:disabled="props.disabled"
					:data-testid="`${props.dataTestid}-checkbox`"
				>
					<slot name="title">
						{{ title }}
					</slot>
				</ws-form-checkbox>
			</span>
		</div>
		<div
			v-show="showContent"
			class="accordion-content"
			:class="{
				'is-danger': props.isDanger,
				'is-info': props.isInfo,
				'is-success': props.isSuccess,
				'is-primary': props.isPrimary,
				'is-warning': props.isWarning
			}"
		>
			<slot />
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
const emits = defineEmits(["opened", "closed"]);
const props = defineProps({
	title: {
		type: String,
		default: null
	},
	titleClass: {
		type: String,
		default: null
	},
	mountOpened: {
		type: Boolean,
		default: false
	},
	isDanger: {
		type: Boolean,
		default: false
	},
	isInfo: {
		type: Boolean,
		default: false
	},
	isSuccess: {
		type: Boolean,
		default: false
	},
	isPrimary: {
		type: Boolean,
		default: false
	},
	isWarning: {
		type: Boolean,
		default: false
	},
	isBoxed: {
		type: Boolean,
		default: false
	},
	useCheckbox: {
		type: Boolean,
		default: false
	},
	tooltip: {
		type: String,
		default: null
	},
	disabled: {
		type: Boolean,
		default: false
	},
	dataTestid: {
		type: String,
		default: "ws-accordion"
	}
});

const showContent = ref(props.mountOpened || false);
const iconForAccordion = computed(() => {
	return showContent.value ? "caret-down" : "caret-right";
});

function toggleContent() {
	showContent.value = !showContent.value;
	if (showContent.value) {
		emits("opened");
	} else {
		emits("closed");
	}
}

function forceShowContent() {
	showContent.value = true;
	emits("opened");
}

defineExpose({
	forceShowContent
});
</script>

<style lang="scss" scoped>
.accordion-wrapper.is-boxed {
	width: 100%;
	border: 1px solid $color-grey-300;
	border-radius: 7px !important;
	margin-bottom: 1em;

	.accordion-title {
		padding: 1em;
		margin-bottom: 0;
		font-weight: 600;
		cursor: pointer;

		span.icon.is-small {
			float: right !important;
		}
	}
	.accordion-handle {
		width: 1rem;
		margin-right: 1rem;
	}

	.accordion-content,
	.accordion-title {
		&.is-danger {
			background-color: $color-danger-50;
		}
		&.is-info {
			background-color: $color-info-50;
		}
		&.is-success {
			background-color: $color-success-50;
		}
		&.is-primary {
			background-color: $color-primary-50;
		}
		&.is-warning {
			background-color: $color-warning-50;
		}
	}
	.accordion-content {
		margin-top: 0;
		padding: 0rem 1rem 1rem 3rem;
	}
}
.accordion-wrapper:not(.is-boxed) {
	.accordion-title {
		&.disabled,
		&.disabled > span {
			cursor: not-allowed;
		}

		display: flex-inline;
		font-size: $size-6;
		font-weight: $weight-normal;
		color: $default-text-color;

		&.is-danger {
			color: $color-danger-600;
		}
		&.is-info {
			color: $color-info-600;
		}
		&.is-success {
			color: $color-success-600;
		}
		&.is-primary {
			color: $color-primary-600;
		}
		&.is-warning {
			color: $color-warning-600;
		}
	}

	&:not(.is-checkbox) {
		cursor: pointer;
	}
	.accordion-content {
		margin-top: 10px;
	}
}
</style>
