<template>
	<div class="ws-data-table-header">
		<div class="columns is-multiline is-marginless">
			<div
				class="pl-0 column is-6-widescreen is-12 is-flex"
				:class="{
					'is-marginless is-paddingless-touch': !$slots.default,
					'is-12-widescreen': hideSearch && filters.length == 0
				}"
			>
				<slot name="header-left" />
			</div>
			<div
				class="px-0 column is-6-widescreen is-12 is-flex"
				:class="{
					'is-marginless is-paddingless':
						hideSearch && filters.length == 0
				}"
			>
				<div
					class="search-bar is-inline-flex"
					:class="{ 'mr-2': $slots['header-right'] }"
				>
					<ws-search-field
						v-if="!hideSearch"
						:placeholder="searchPlaceholder"
						@update:model-value="$emit('search', $event)"
					/>

					<ws-button
						v-if="filters.length > 0"
						is-primary
						is-outlined
						name="button"
						@click="showFilters = !showFilters"
					>
						<template #icon>
							<ws-icon icon="filter" />
						</template>
						<span>{{ $t("filters") }} </span>
					</ws-button>
				</div>
				<slot name="header-right" />
			</div>
		</div>

		<div class="filters" v-show="showFilters">
			<div class="columns is-multiline" v-if="filters.length > 0">
				<div
					:class="{
						'column is-3 is-12-touch': !stacked,
						'column is-12 is-12-touch': stacked
					}"
					v-for="filter of filters"
					:key="filter.selector"
				>
					<filter-item
						:label="filter.name"
						:items="filter.items"
						@selected-filter="
							selectedFilter($event, filter.selector)
						"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Filter from "./filter";

export default {
	props: {
		searchPlaceholder: {
			type: String,
			default: "Search"
		},
		showCollapseExpandButtons: {
			type: Boolean,
			default: false
		},
		hideSearch: {
			type: Boolean,
			default: false
		},

		filters: {
			type: Array,
			default: () => []
		},
		stacked: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			showFilters: false
		};
	},

	emits: ["search", "selectedFilter", "expandedRows", "collapsedRows"],
	methods: {
		selectedFilter(value, selector) {
			this.$emit("selectedFilter", {
				selector,
				value
			});
		}
	},

	components: {
		"filter-item": Filter
	}
};
</script>

<style lang="scss" scoped>
.ws-data-table-header {
	width: 100%;

	@include touch() {
		.is-paddingless-touch {
			padding: 0 !important;
		}
	}
	.columns > div {
		align-items: flex-end;

		:slotted(.field) {
			margin-bottom: 0 !important;
		}
	}

	.search-bar {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		width: 100%;

		.field {
			margin-bottom: 0;
		}

		.button {
			margin-left: 5px;
		}
	}

	.filters {
		padding-bottom: 1rem;
		background: $filters-background-color;
		margin-top: 0.5rem;
		margin-left: -1.75rem;
		margin-right: -1.75rem;

		.columns {
			justify-content: flex-end;
			margin-left: 0;
			margin-right: 0;
			padding-right: 1.75rem;
			padding-left: 1.75rem;

			.column:last-child {
				padding-right: 0;
			}

			@include touch {
				.column {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
}
</style>
