<template>
	<div
		class="modal is-active"
		v-show="props.show || showModal"
		v-if="showModal"
		data-testid="modal-confirm"
		role="dialog"
		aria-labelledby="dialogTitle"
		aria-describedby="dialogTitle"
	>
		<div class="modal-background" />
		<div class="modal-content">
			<div class="dialogWrapper" ref="dialogWrapper">
				<div
					class="dialogHeader is-flex is-justify-content-space-between"
					v-if="!!slots.title || props.title || props.showCloseButton"
				>
					<div class="dialogTitle word-wrap" id="dialogTitle">
						<slot name="title"> {{ props.title }} </slot>
					</div>
					<ws-button
						is-grey
						is-subtle
						is-small
						v-if="props.showCloseButton"
						@click="cancel"
						@keyup.enter="cancel"
						tabindex="0"
					>
						<template #icon>
							<ws-icon icon="close" />
						</template>
					</ws-button>
				</div>

				<div class="dialogBody word-wrap" id="dialogBody">
					<slot>
						{{ props.body }}
					</slot>
				</div>

				<div class="dialogFooter">
					<div class="footerLeft">
						<slot name="footer-left" />
					</div>
					<div class="footerRight">
						<slot
							name="buttons"
							:cancel="cancel"
							:confirm="confirm"
						>
							<ws-button
								@click="cancel"
								is-grey
								data-testid="ws-confirm-dialog-cancel-btn"
								v-if="props.showCancel"
							>
								<span>
									{{ getCancelLabel }}
								</span>
							</ws-button>
							<ws-button
								:is-success="props.type === 'success'"
								:is-warning="props.type === 'warning'"
								:is-danger="props.type === 'danger'"
								:is-info="props.type === 'info'"
								:is-primary="
									props.type === 'primary' ||
									props.type === 'default'
								"
								@click="confirm"
								data-testid="ws-confirm-dialog-confirm-btn"
								v-if="showConfirm"
							>
								<span>
									{{ getConfirmLabel }}
								</span>
							</ws-button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
const emits = defineEmits(["cancel", "confirm"]);
const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		default: "warning",
		validation: function (value) {
			return [
				// default = primary confirm button #FE-1021
				"default",
				"primary",
				"info",
				"success",
				"warning",
				"danger"
			].includes(value);
		}
	},
	title: {
		type: String,
		default: ""
	},
	body: {
		type: String,
		default: null
	},
	showCloseButton: {
		type: Boolean,
		default: true
	},
	showCancel: {
		type: Boolean,
		default: true
	},
	showConfirm: {
		type: Boolean,
		default: true
	},
	confirmLabel: {
		type: String,
		default: null
	},
	cancelLabel: {
		type: String,
		default: null
	}
});
defineExpose({ open });

import { ref, computed, watch, nextTick, useSlots } from "vue";
import { useI18n } from "vue-i18n";
import { useTrapFocus } from "@/composables/useTrapFocus.js";

const slots = useSlots();
const { t: $t } = useI18n();
const { setTrapFocus } = useTrapFocus();
const dialogWrapper = ref(null);
const showModal = ref(false);
const resolve = ref(null);

watch(
	() => props.show,
	(newValue) => {
		showModal.value = newValue;
	},
	{ immediate: true }
);
watch(
	() => showModal.value,
	async (_newValue) => {
		// when modal is visible, set Trap focus + listen for ESC keyboard input to cancel it
		if (_newValue) {
			await nextTick();
			setTrapFocus(dialogWrapper?.value, cancel);
		}
	},
	{ immediate: true }
);

const getConfirmLabel = computed(() => {
	return props.confirmLabel || $t("confirm");
});

const getCancelLabel = computed(() => {
	return props.cancelLabel || $t("cancel");
});

async function open() {
	return new Promise((_resolve) => {
		showModal.value = true;
		resolve.value = _resolve;
	});
}

const cancel = (arg = null) => {
	emits("cancel", arg);
	if (resolve.value) {
		showModal.value = false;
		return resolve.value(false);
	}
};

const confirm = (arg = null) => {
	emits("confirm", arg);
	if (resolve.value) {
		showModal.value = false;
		return resolve.value(true);
	}
};
</script>

<style lang="scss" scoped>
$color-background-keyboard-buttons: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
$color-keyboard-buttons: #969faf;
$box-shadow-keyboard-buttons: inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff,
	0 1px 2px 1px rgba(30, 35, 90, 0.4);

.title:not(.is-spaced) + .subtitle {
	margin-top: 0;
}

.word-wrap {
	word-wrap: break-word;
}

.dialogWrapper {
	background-color: $white;
	color: $color-grey-700;
	padding: 1rem;
	border-radius: 6px;
	border: none;

	.dialogHeader {
		width: 100%;
		line-height: 2rem;
		margin-bottom: 1rem;

		.dialogTitle {
			font-weight: $weight-semibold;
			font-size: $size-5;
		}
	}
	.dialogBody {
		margin-bottom: 1rem;
	}
	.dialogFooter {
		width: 100%;

		// is-flex is-justify-content-space-between
		display: flex;
		justify-content: space-between;
		@include mobile {
			display: block;
			.footerLeft {
				margin-bottom: 1rem;
			}
		}

		.footerLeft {
			// to vertically align footerLeft & footerRight
			display: flex;
			align-items: center;
		}
		.footerRight {
			display: flex;
			flex-direction: row;
			.button:not(:first-child) {
				margin-left: 1rem;
			}
			@include mobile {
				flex-direction: column;
				align-items: flex-end;
				.button {
					margin-left: 0;
				}
				.button:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}
	}
}

.keyboard-button {
	font-size: $size-7;
	font-weight: $weight-light;
	margin-left: 10px;
	background: $color-background-keyboard-buttons;
	border-radius: 3px;
	box-shadow: $box-shadow-keyboard-buttons;
	color: $color-keyboard-buttons;
	display: flex;
	height: 20px;
	justify-content: center;
	align-items: center;
	padding: 3px;
}
</style>
