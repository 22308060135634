import UnitsService from "@/services/v1/Units.service.js";
import { $log } from "@/plugins/logger/logger.plugin.js";

export default {
	data() {
		return {
			availableUnitsMixin: []
		};
	},

	async created() {
		try {
			this.availableUnitsMixin = await UnitsService.getUnits();
		} catch (err) {
			$log.error("Failed getting units", err);
			this.availableUnitsMixin = [];
		}
	},

	computed: {
		$_availableUnits() {
			return this.availableUnitsMixin;
		}
	}
};
