<template>
	<video-player
		ref="videoPlayerRef"
		fluid
		:src="props.src"
		:poster="props.poster"
		:controls="props.controls"
		:loop="props.loop"
		:muted="props.muted"
		:volume="props.volume"
		not-supported-message="Your browser does not support video players"
	/>
</template>

<script setup>
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

const props = defineProps({
	src: {
		type: String,
		default: "",
		required: true
	},
	poster: {
		type: String,
		default: ""
	},
	controls: {
		type: Boolean,
		default: true
	},
	loop: {
		type: Boolean,
		default: false
	},
	muted: {
		type: Boolean,
		default: false
	},
	volume: {
		type: Number,
		default: 0.5
	}
});
</script>
