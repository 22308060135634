const colors = [
	"#FF8E74",
	"#FF6A47",
	"#FF4720",
	"#8C7B3A",
	"#665620",
	"#402D0C",
	"#FFB9FF",
	"#FF96FA",
	"#FF6FE2",
	"#A53BFF",
	"#8800C8",
	"#66008E",
	"#4FEF33",
	"#16B700",
	"#0C7300",
	"#C8C8C8",
	"#A5A5A5",
	"#7A7A7A",
	"#FF285A",
	"#FF0039",
	"#B20020",
	"#89D5FF",
	"#63B2FF",
	"#2B8BFF",
	"#FFE659",
	"#FCE329",
	"#FFC000"
];

const usedColors = [];
export function addToUsedColors(color) {
	if (usedColors.includes(color)) {
		return;
	}
	usedColors.push(color);

	// reset usedColors when is full
	if (usedColors.length === colors.length - 1) {
		usedColors.length = 0;
	}
}

export default colors;

export function getSingleColor(getUnusedColor = true) {
	let listOfColors = colors;
	if (getUnusedColor) {
		listOfColors = colors.filter((color) => !usedColors.includes(color));
	}
	const randomIndex = Math.floor(Math.random() * listOfColors.length);
	const color = listOfColors[randomIndex];
	if (getUnusedColor) {
		addToUsedColors(color);
	}

	return color;
}
