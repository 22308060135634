<template>
	<div>
		<div
			v-if="!showList"
			class="unit-text"
			:class="{ 'no-unit': !modelValue }"
			:data-testid="`${dataTestid}_text`"
			@click="showList = true"
			:title="$t('click-to-add-change-unit')"
		>
			{{ modelValue }}
		</div>
		<div v-else>
			<ws-form-select
				ref="selectUnit"
				v-model="selectedUnit"
				:data-testid="`${dataTestid}_selector`"
				class="unitSelector"
				:searchable="true"
				:options="$_availableUnits"
				:allow-null="true"
				@change="changedUnit"
				@close="hideList"
				@mounted="openSelectWithUnits"
			/>
		</div>
	</div>
</template>

<script>
import AvailableUnitsMixin from "@/mixins/availableUnits.mixin.js";

export default {
	mixins: [AvailableUnitsMixin],

	props: {
		modelValue: {
			type: [String, null],
			default: null
		},
		dataTestid: {
			type: String,
			default: () => "ws_unit_inline"
		}
	},
	data() {
		return {
			showList: false,
			selectedUnit: this.modelValue
		};
	},

	emits: ["update:modelValue"],
	methods: {
		changedUnit() {
			this.$nextTick(() => {
				this.$emit("update:modelValue", this.selectedUnit);
			});
		},

		hideList() {
			this.showList = false;
		},

		openSelectWithUnits() {
			this.$refs.selectUnit.openSelect();
		}
	}
};
</script>

<style lang="scss" scoped>
.unit-text {
	word-break: break-all;
	padding: 0 0.5rem;

	&:hover {
		cursor: pointer;
		background: $color-grey-300;
		border-radius: 6px;
	}
}

.no-unit {
	width: 80%;
	height: 20px;
}

.unitSelector {
	min-width: 10rem;
}
</style>
