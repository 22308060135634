export const NAME = "name";
export const DESCRIPTION = "description";
export const ACCESS_TYPE = "accessType";
export const REGISTER_ADDRESS = "registerAddress";
export const PRIMARY_FUNCTION = "function (1/2/3/4)";
export const SECONDARY_FUNCTION = "secondaryFunction (5/6/16)";
export const DATA_FORMAT = "dataFormat";
export const NB_RAW_REGISTERS = "numberOfRawRegisters";
export const SCALING_A = "scaling_a";
export const SCALING_B = "scaling_b";
export const ERRORS = "errors";

export const requiredCsvFileHeaders = [];

export const csvFileHeaders = [
	NAME,
	DESCRIPTION,
	ACCESS_TYPE,
	REGISTER_ADDRESS,
	PRIMARY_FUNCTION,
	SECONDARY_FUNCTION,
	DATA_FORMAT,
	NB_RAW_REGISTERS,
	SCALING_A,
	SCALING_B
];

export const allowedCsvFileHeaders = [...csvFileHeaders];

/**
 * Map template file header to Wattsense header (used in the code) - but only the different header names.
 * CSV file headers to Datatable headers
 */
export const mapTemplateHeaderToWattsenseHeader = {
	[REGISTER_ADDRESS]: "modbusRegisterAddress",
	[PRIMARY_FUNCTION]: "modbusFunction",
	[SECONDARY_FUNCTION]: "modbusSecondaryFunction",
	[DATA_FORMAT]: "modbusDataFormat",
	[NB_RAW_REGISTERS]: "modbusNumberOfRawRegisters",
	[SCALING_A]: "scaling_a",
	[SCALING_B]: "scaling_b"
};

export function wsHeaderFromTemplate(templateName) {
	return mapTemplateHeaderToWattsenseHeader[templateName] || templateName;
}

/**
 * Map Wattsense header (used in the code) to template file header - but only the different header names.
 * Datatable headers to CSV File headers
 */
export const mapWattsenseHeaderToTemplateHeader = Object.entries(
	mapTemplateHeaderToWattsenseHeader
).reduce(
	(acc, [key, value]) => ({
		...acc,
		[value]: key
	}),
	{}
);

export function templateHeaderFromWattsense(wsName) {
	return mapWattsenseHeaderToTemplateHeader[wsName] || wsName;
}

export function humanFunction(value) {
	if(!value.length) {
		return null;
	}

	let name;

	switch(value) {
		case '1':
			name = 'READ_COILS';
			break;
		case '2':
			name = 'READ_DISCRETE_INPUTS';
			break
		case '3':
			name = 'READ_HOLDING_REGISTERS';
			break
		case '4':
			name = 'READ_INPUT_REGISTERS';
			break
		case '5':
			name = 'WRITE_SINGLE_COIL';
			break
		case '6':
			name = 'WRITE_SINGLE_REGISTER';
			break
		case '16':
			name = 'WRITE_MULTIPLE_REGISTERS';
			break;
		default:
			throw new Error('Invalid function value')
	}
	return name;
}
/*
 * Convert CSV entries to property object for preview
 */
export function formatLine(line) {
	try {
		const formatted = {
			[NAME]: line.name,
			[DESCRIPTION]: line.description,
			[ACCESS_TYPE]: line.accessType?.toUpperCase(),
			[wsHeaderFromTemplate(REGISTER_ADDRESS)]: +line[REGISTER_ADDRESS],
			[wsHeaderFromTemplate(PRIMARY_FUNCTION)]: line[PRIMARY_FUNCTION],
			[`${wsHeaderFromTemplate(PRIMARY_FUNCTION)}Ws`]: humanFunction(line[PRIMARY_FUNCTION]),
			[wsHeaderFromTemplate(SECONDARY_FUNCTION)]: line[SECONDARY_FUNCTION],
			[`${wsHeaderFromTemplate(SECONDARY_FUNCTION)}Ws`]: humanFunction(line[SECONDARY_FUNCTION]),
			[wsHeaderFromTemplate(DATA_FORMAT)]: line[DATA_FORMAT],
			[wsHeaderFromTemplate(NB_RAW_REGISTERS)]: +line[NB_RAW_REGISTERS] || null,
			[wsHeaderFromTemplate(SCALING_A)]: line[SCALING_A],
			[wsHeaderFromTemplate(SCALING_B)]: line[SCALING_B]	
		}
		return formatted
	} catch(err) {
		throw Error('Missing fields on CSV')
	}
}

export function formatConfigToInsert(property) {
	return {
		[wsHeaderFromTemplate(REGISTER_ADDRESS)]: property.modbusRegisterAddress,
		[wsHeaderFromTemplate(PRIMARY_FUNCTION)]: property.modbusFunctionWs,
		[wsHeaderFromTemplate(SECONDARY_FUNCTION)]: property.modbusSecondaryFunctionWs,
		[wsHeaderFromTemplate(DATA_FORMAT)]: property.modbusDataFormat,
		[wsHeaderFromTemplate(NB_RAW_REGISTERS)]: property.modbusNumberOfRawRegisters,
	}
}