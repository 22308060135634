/**
 * Date Range Picker Component
 * Based on VueRangedatePicker
 * https://github.com/bliblidotcom/vue-rangedate-picker
 *
 * NB: Date/Time functionality is UTC-based
 */

import { generateRandomId } from "@/helpers/functions.helper.js";

const defaultConfig = { timepicker: false };
const defaultLang = "en";
const availableMonths = {
	en: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	],
	fr: [
		"Janvier",
		"Février",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Août",
		"Septembre",
		"Octobre",
		"Novembre",
		"Decembre"
	],
	de: [
		"Januar",
		"Februar",
		"Marsch",
		"April",
		"Kann",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember"
	]
};

const availableShortDays = {
	en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
	fr: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
	de: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"]
};

const defaultCaptions = {
	title: "Choose Dates",
	ok_button: "Apply"
};

const defaultStyle = {
	daysWeeks: "calendar_weeks",
	days: "calendar_days",
	daysSelected: "calendar_days_selected",
	daysInRange: "calendar_days_in-range",
	firstDate: "calendar_month_left",
	secondDate: "calendar_month_right",
	dateDisabled: "calendar_days--disabled"
};

export default {
	name: "rangedate-picker",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: [String, null],
			default: null
		},
		labelDescription: {
			type: [String, null],
			default: null
		},
		tooltip: {
			type: [String, null],
			default: null
		},
		tooltipPosition: {
			type: [String, null],
			default: "top"
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		configs: {
			type: Object,
			default: () => defaultConfig
		},
		emptyMsg: {
			type: String,
			default: "Select Range"
		},
		lang: {
			type: [String, null],
			default: defaultLang
		},
		months: {
			type: Array,
			default: () => null
		},
		shortDays: {
			type: Array,
			default: () => null
		},
		// options for captions are: title, ok_button
		captions: {
			type: Object,
			default: () => defaultCaptions
		},
		format: {
			type: String,
			default: "MMM DD, YYYY"
		},
		timeFormat: {
			type: String,
			default: "HH:mm"
		},
		style: {
			type: String,
			default: null
		},
		initRange: {
			type: Object,
			default: () => null
		},
		startActiveMonth: {
			type: Number,
			default: null
		},
		startActiveYear: {
			type: Number,
			default: null
		},
		rightToLeft: {
			type: Boolean,
			default: false
		}
	},

	emits: ["selected", "mounted"],

	data() {
		let displayDate = this.$dayjs();
		if (this.initRange?.end) {
			displayDate = this.$dayjs(this.initRange.end);
		} else if (this.initRange?.start) {
			displayDate = this.$dayjs(this.initRange.start);
		}

		const aMonthAgo = displayDate.subtract(1, "month");

		const activeMonthStart = this.startActiveMonth || aMonthAgo.month();
		const activeYearStart = this.startActiveYear || aMonthAgo.year();

		return {
			dateRange: this.initRange || {},
			selectedRange: {},
			numOfDays: 7,
			isFirstChoice: true,
			isOpen: false,
			showMonth: false,
			startTime: "00:00",
			endTime: "23:59",
			activeMonthStart,
			activeYearStart
		};
	},
	mounted() {
		this.$emit("mounted");
	},

	computed: {
		monthsLocale: function () {
			return this.months || availableMonths[this.lang];
		},
		shortDaysLocale: function () {
			return this.shortDays || availableShortDays[this.lang];
		},
		s: function () {
			return Object.assign({}, defaultStyle, this.style);
		},
		startMonthDay: function () {
			return new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, 1, 12)
			).getDay();
		},
		startNextMonthDay: function () {
			return new Date(
				Date.UTC(this.activeYearEnd, this.startNextActiveMonth, 1, 12)
			).getDay();
		},
		endMonthDate: function () {
			return new Date(
				Date.UTC(this.activeYearEnd, this.startNextActiveMonth, 0, 12)
			).getDate();
		},
		endNextMonthDate: function () {
			return new Date(
				Date.UTC(this.activeYearEnd, this.activeMonthStart + 2, 0, 12)
			).getDate();
		},
		startNextActiveMonth: function () {
			return this.activeMonthStart >= 11 ? 0 : this.activeMonthStart + 1;
		},
		activeYearEnd: function () {
			return this.activeMonthStart < 11
				? this.activeYearStart
				: this.activeYearStart + 1;
		}
	},

	methods: {
		toggleCalendar: function (forceClose = false) {
			// reset selected range, it's not applied anyway
			this.isOpen = forceClose ? false : !this.isOpen;
			this.showMonth = forceClose ? false : !this.showMonth;
		},

		isOneDaySelected() {
			return (
				this.$dayjs(this.selectedRange.start).dayOfYear() ===
				this.$dayjs(this.selectedRange.end).dayOfYear()
			);
		},

		getDateByFormat: function (date, format, lang) {
			if (!date) {
				return null;
			}

			return this.$dayjs(date)
				.locale(lang || this.lang)
				.format(format);
		},

		getDateString: function (date, format = this.format) {
			return this.getDateByFormat(date, format);
		},

		getTimeString: function (date, format = this.timeFormat) {
			return this.getDateByFormat(date, format);
		},

		getDayIndexInMonth: function (r, i, startMonthDay) {
			const date = this.numOfDays * (r - 1) + i;
			return date - startMonthDay;
		},

		getDayCell(r, i, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			// bound by > 0 and < last day of month
			return result > 0 && result <= endMonthDate ? `${result}` : "";
		},

		getNewDateRange(result, activeMonth, activeYear) {
			const newData = {};
			let key = "start";
			if (!this.isFirstChoice) {
				key = "end";
			} else {
				newData["end"] = null;
			}
			const resultDate = new Date(activeYear, activeMonth, result);
			if (!this.isFirstChoice && resultDate < this.dateRange.start) {
				this.isFirstChoice = false;
				return { start: resultDate, end: resultDate };
			}

			// toggle first choice
			this.isFirstChoice = !this.isFirstChoice;
			newData[key] = resultDate;
			if (key === "start") {
				newData["end"] = resultDate;
			}
			return newData;
		},

		selectFirstItem(r, i) {
			const result = this.getDayIndexInMonth(r, i, this.startMonthDay);
			this.dateRange = Object.assign(
				{},
				this.dateRange,
				this.getNewDateRange(
					result,
					this.activeMonthStart,
					this.activeYearStart
				)
			);
		},

		selectSecondItem(r, i) {
			const result = this.getDayIndexInMonth(
				r,
				i,
				this.startNextMonthDay
			);
			this.dateRange = Object.assign(
				{},
				this.dateRange,
				this.getNewDateRange(
					result,
					this.startNextActiveMonth,
					this.activeYearEnd
				)
			);
		},

		isDateSelected(r, i, key, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			if (result < 1 || result > endMonthDate) {
				return false;
			}

			let currDate = null;
			if (key === "first") {
				currDate = new Date(
					this.activeYearStart,
					this.activeMonthStart,
					result
				);
			} else {
				currDate = new Date(
					this.activeYearEnd,
					this.startNextActiveMonth,
					result
				);
			}
			return (
				(this.dateRange.start &&
					this.dateRange.start.getTime() === currDate.getTime()) ||
				(this.dateRange.end &&
					this.dateRange.end.getTime() === currDate.getTime())
			);
		},

		isDateInRange(r, i, key, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			if (result < 1 || result > endMonthDate) {
				return false;
			}

			let currDate = null;
			if (key === "first") {
				currDate = new Date(
					this.activeYearStart,
					this.activeMonthStart,
					result
				);
			} else {
				currDate = new Date(
					this.activeYearEnd,
					this.startNextActiveMonth,
					result
				);
			}
			return (
				this.dateRange.start &&
				this.dateRange.start.getTime() < currDate.getTime() &&
				this.dateRange.end &&
				this.dateRange.end.getTime() > currDate.getTime()
			);
		},
		isDateDisabled(r, i, key, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			const resultDate =
				key === "first"
					? new Date(
							this.activeYearStart,
							this.activeMonthStart,
							result
						)
					: new Date(
							this.activeYearEnd,
							this.startNextActiveMonth,
							result
						);

			if (
				this.configs.maxDate &&
				this.$dayjs(resultDate).isAfter(this.configs.maxDate)
			) {
				return true;
			}
			if (
				this.configs.minDate &&
				this.$dayjs(resultDate).isBefore(this.configs.minDate)
			) {
				return true;
			}

			// bound by > 0 and < last day of month
			return !(result > 0 && result <= endMonthDate);
		},

		goPrevYear() {
			const prevYear = new Date(
				Date.UTC(this.activeYearStart - 1, this.activeMonthStart, 15)
			);
			this.activeMonthStart = prevYear.getMonth();
			this.activeYearStart = prevYear.getFullYear();
		},

		goNextYear() {
			const nextYear = new Date(
				Date.UTC(this.activeYearStart + 1, this.activeMonthStart, 15)
			);
			this.activeMonthStart = nextYear.getMonth();
			this.activeYearStart = nextYear.getFullYear();
		},

		goPrevMonth() {
			const prevMonth = new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, 0)
			);
			this.activeMonthStart = prevMonth.getMonth();
			this.activeYearStart = prevMonth.getFullYear();
		},
		goNextMonth() {
			const nextMonth = new Date(
				Date.UTC(this.activeYearEnd, this.startNextActiveMonth, 15)
			);
			this.activeMonthStart = nextMonth.getMonth();
			this.activeYearStart = nextMonth.getFullYear();
		},

		setDateValue: function () {
			// Without time
			if (!this.configs.timepicker) {
				this.selectedRange = {
					start: this.dateRange.start,
					end: this.dateRange.end
				};
				// With time
			} else {
				const mStart = this.$dayjs(this.startTime, "HH:mm");
				const mEnd = this.$dayjs(this.endTime, "HH:mm");
				this.selectedRange = {
					start: this.$dayjs(this.dateRange.start)
						.hour(mStart.hour())
						.minute(mStart.minute())
						.toDate(),
					end: this.$dayjs(this.dateRange.end)
						.hour(mEnd.hour())
						.minute(mEnd.minute())
						.toDate()
				};
			}
			this.$emit("selected", this.selectedRange);
			this.toggleCalendar(true);
		},

		setDateRange(code, customRange = null) {
			const eod = this.$dayjs().toDate();
			switch (code) {
				case "last2Days": {
					this.dateRange.start = this.$dayjs()
						.subtract(1, "day")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "last7Days": {
					this.dateRange.start = this.$dayjs()
						.subtract(7, "day")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "last14Days": {
					this.dateRange.start = this.$dayjs()
						.subtract(14, "day")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "last30Days": {
					this.dateRange.start = this.$dayjs()
						.subtract(30, "day")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "last90Days": {
					this.dateRange.start = this.$dayjs()
						.subtract(90, "day")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "lastYear": {
					this.dateRange.start = this.$dayjs()
						.subtract(1, "year")
						.toDate();
					this.dateRange.end = eod;
					this.setDateValue();
					break;
				}
				case "custom":
				default: {
					const { dateStart = null, dateEnd = null } =
						customRange || {};
					if (!dateStart || !dateEnd) {
						break;
					}
					this.dateRange.start = dateStart;
					this.dateRange.end = dateEnd;
					this.setDateValue();
					break;
				}
			}
		},

		canSetDateRange(code) {
			if (!this.configs || !this.configs.minDate) {
				return true;
			}
			switch (code) {
				case "last2Days": {
					return this.$dayjs()
						.subtract(1, "day")
						.isAfter(this.configs.minDate);
				}
				case "last7Days": {
					return this.$dayjs()
						.subtract(7, "day")
						.isAfter(this.configs.minDate);
				}
				case "last14Days": {
					return this.$dayjs()
						.subtract(14, "day")
						.isAfter(this.configs.minDate);
				}
				case "last30Days": {
					return this.$dayjs()
						.subtract(30, "day")
						.isAfter(this.configs.minDate);
				}
				case "last90Days": {
					return this.$dayjs()
						.subtract(90, "day")
						.isAfter(this.configs.minDate);
				}
				case "lastYear": {
					return this.$dayjs()
						.subtract(1, "year")
						.isAfter(this.configs.minDate);
				}
				default: {
					break;
				}
			}
		},

		setInitDate: function (range) {
			this.dateRange.start = range.start;
			this.dateRange.end = range.end;

			this.selectedRange = {
				start: this.dateRange.start,
				end: this.dateRange.end
			};
		},

		resetTimeRange() {
			this.startTime = "00:00";
			this.endTime = "23:59";
		},

		checkTimeValues() {
			// for now, just make sure the values meet this format: X:Y
			// dayjs corrects even XY, XXYY and few other common issues with typed time
			const timeFormat = /[^\d:]/g;
			let corrected = this.startTime.replace(timeFormat, "").slice(0, 5);
			corrected = corrected.length > 1 ? corrected : "00:00";
			this.startTime = this.$dayjs(corrected, "HH:mm").format("HH:mm");
			corrected = this.endTime.replace(timeFormat, "").slice(0, 5);
			corrected = corrected.length > 1 ? corrected : "23:59";
			this.endTime = this.$dayjs(corrected, "HH:mm").format("HH:mm");
		},

		moveRangeDates(sense) {
			if (!["forward", "rewind"].includes(sense)) {
				return;
			}

			const startDate = this.$dayjs(this.dateRange.start);
			const endDate = this.$dayjs(this.dateRange.end);
			let rangeDates = endDate.diff(startDate, "days");
			if (sense === "forward") {
				rangeDates = rangeDates * -1;
			}

			this.dateRange.start = startDate
				.subtract(rangeDates, "days")
				.toDate();
			this.dateRange.end = endDate.subtract(rangeDates, "days").toDate();
			this.setDateValue();
			this.toggleCalendar(true);
		}
	}
};
