<template>
	<ws-view-title :back-to="goBack" class="mb-5">
		<slot name="title"></slot>
	</ws-view-title>
	<div class="content mb-0">
		<slot name="instructions"></slot>
	</div>
	<div class="my-4">
		<slot></slot>
	</div>

	<div class="title is-5">{{ $t("summary") }}</div>
	<slot name="summary">
		<p>
			{{ $t("no-data-to-show") }}
		</p>
	</slot>
	<slot name="actions"></slot>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
	routeBack: {
		type: Object,
		required: false,
		default: null
	}
})

const route = useRoute();
const router = useRouter();
function goBack() {
	if (route.query.backToEquipments) {
		router.push({
			name: "box-v1-settings-equipments"
		});
	} else if(props.routeBack) {
		router.push(props.routeBack);
	} else {
		router.back();
	}
}
</script>
