<template>
	<div class="ws-view-tabs">
		<slot name="notifications"></slot>
		<div class="tabs is-boxed" v-dragscroll.x="true">
			<ul>
				<slot />
			</ul>
		</div>
	</div>
</template>

<script>
import { dragscroll } from "vue-dragscroll";

export default {
	name: "WsViewTabs",
	directives: {
		dragscroll
	}
};
</script>

<style lang="scss" scoped>
.ws-view-tabs {
	// display: flex;
	margin: -1rem -1.25rem 0 -1.25rem;
	background-color: $color-grey-300;
	padding-top: 1.25rem;
	width: unset !important;

	.tabs {
		overflow: hidden;

		a {
			padding: none;
		}

		ul {
			border: none;
		}
	}
}
</style>
