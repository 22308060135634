import DataDogMonitor from "./datadog/DataDogMonitor.js";
import DataDogLogMonitor from "./datadog/DataDogLogMonitor.js";

export const SYMBOL_MONITORING = Symbol("ws-user-console-monitoring");
export const SYMBOL_LOG_MONITORING = Symbol("ws-user-console-log-monitoring");

export default {
	install(app, options = { isEnabled: false }) {
		const monitor = new DataDogMonitor(options.isEnabled);
		const logMonitor = new DataDogLogMonitor(options.isEnabled);

		app.config.globalProperties.$logMonitor = (...args) =>
			logMonitor.notify(...args);

		app.provide(SYMBOL_MONITORING, monitor);
		app.provide(SYMBOL_LOG_MONITORING, logMonitor);
	}
};
