<template>
	<div
		class="picker"
		@keydown.esc="closePicker"
		v-click-outside="closePicker"
	>
		<ws-button
			@click="togglePicker"
			:style="buttonStyle"
			is-small
			:disabled="disabled"
			data-testid="btn-color-picker"
		/>
		<div
			v-if="showPicker"
			aria-label="Compact color picker"
			class="ws-compact"
		>
			<ul class="ws-compact-colors" role="listbox">
				<li
					v-for="color in paletteColors"
					role="option"
					:aria-label="'color:' + color"
					:aria-selected="isColorSelected(color)"
					class="ws-compact-color-item"
					:key="color"
					:style="{ background: color }"
					@click="changedColor(color)"
					:data-testid="`color-${color}`"
				>
					<div
						class="ws-compact-dot"
						v-show="isColorSelected(color)"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import paletteColors, { getSingleColor } from "./palette.js";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: null
	},
	disabled: {
		type: Boolean,
		default: false
	}
});

const showPicker = ref(false);
const internalColor = ref(props.modelValue || getSingleColor(false));

const buttonStyle = computed(() => {
	return `
		height: 24px !important;
		width: 24px !important;
		background-color: ${internalColor.value} !important;
		border-color: ${internalColor.value} !important;
	`;
});

watchEffect(() => (internalColor.value = props.modelValue));

function isColorSelected(color) {
	return color === internalColor.value;
}

function changedColor(color) {
	internalColor.value = color;
	showPicker.value = false;
	emits("update:modelValue", color);
}

function togglePicker() {
	showPicker.value = !showPicker.value;
}

function closePicker() {
	showPicker.value = false;
}
</script>

<style lang="scss" scoped>
.picker {
	position: relative;
}

.ws-compact {
	padding-top: 5px;
	padding-left: 5px;
	width: 245px;
	border-radius: 2px;
	box-sizing: border-box;
	box-shadow: $shadows;
	background-color: $white;

	position: absolute;
	z-index: 9999;
	top: 27px;
	left: 1px;
	max-width: 66px;
}
.ws-compact-colors {
	overflow: hidden;
	padding: 0;
	margin: 0 !important;
}
.ws-compact-color-item {
	list-style: none;
	width: 15px;
	height: 15px;
	float: left;
	margin-right: 5px;
	margin-bottom: 5px;
	margin-top: 0 !important;
	position: relative;
	cursor: pointer;
}
.ws-compact-color-item--white {
	box-shadow: inset 0 0 0 1px $color-grey-400;
}

.ws-compact-dot {
	position: absolute;
	top: 5px;
	right: 5px;
	bottom: 5px;
	left: 5px;
	border-radius: 50%;
	opacity: 1;
	background: $white;
}
.high-contrast .ws-compact-dot {
	background: $black;
}
</style>
