<template>
	<div class="panel">
		<div class="panel-bg" />
		<transition name="slide-fade" mode="out-in" appear>
			<div class="panel-content content">
				<div class="container" ref="panelContainer">
					<panel-header @close="close()">
						<slot name="header-title" />
					</panel-header>

					<panel-main>
						<slot name="main" />
					</panel-main>

					<panel-footer>
						<template #above-buttons>
							<slot name="footer-above-buttons" />
						</template>
						<slot name="footer-buttons" />
					</panel-footer>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
const emits = defineEmits(["close"]);

import { EventBus } from "@/eventbus";
import PanelHeader from "./components/panel-header.vue";
import PanelMain from "./components/panel-main.vue";
import PanelFooter from "./components/panel-footer.vue";
import { useTrapFocus } from "@/composables/useTrapFocus.js";
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";

const panelContainer = ref(null);
const { setTrapFocus } = useTrapFocus();

onMounted(async () => {
	await nextTick();
	setTrapFocus(panelContainer?.value, close);

	// quick solution, not ideal
	// needed for panels that shows content after loading data
	// to have the focus inside the panel body (2nd focusable element) & not in the footer
	EventBus.$on("resetFocus", resetFocus);

	// // hide chat button on bottomright corner of the page to avoid overlaping save button
	// if (window.$zoho) {
	// 	window.$zoho?.salesiq?.floatbutton?.visible("hide");
	// }
});
onBeforeUnmount(() => {
	EventBus.$off("resetFocus", resetFocus);
	// 	// show back chat button
	// 	if (window.$zoho) {
	// 		window.$zoho?.salesiq?.floatbutton?.visible("show");
	// 	}
});

const close = () => {
	emits("close");
};
const resetFocus = () => {
	setTrapFocus(panelContainer?.value, close);
};

defineExpose({ close, resetFocus });
</script>

<style lang="scss" scoped>
.panel {
	z-index: 1001;

	&-bg {
		z-index: 1000;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: $white-o70;
	}
	&-content {
		z-index: 1002;
		position: absolute;
		top: 0;
		right: 0;
		width: 80%;
		height: 100%;
		background: $white;
		box-shadow: $shadows;

		@include touch {
			width: 100%;
		}

		.container {
			display: flex;
			flex-direction: column;
			height: 100%;
			max-width: 100% !important;
			// position: relative;
		}
	}
	.slide-fade-enter-active {
		transition: all 0.3s ease;
	}
	.slide-fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}
	.slide-fade-enter-from, .slide-fade-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(20px);
		opacity: 0;
	}
}
</style>
