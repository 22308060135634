<template>
	<div :data-testid="dataTestid">
		<div class="value" v-if="!showInput" @click="showInput = true">
			{{ value }}
		</div>
		<div class="input-value" v-else>
			<div class="field m-0">
				<div class="control">
					<input
						type="text"
						class="input"
						:class="{ 'is-danger': error !== null }"
						v-model="value"
						v-ws-focus
						@keypress.enter.prevent="save"
						@keyup.esc.prevent="cancel"
						:disabled="disabled"
					/>
				</div>
			</div>
			<div class="buttons is-right">
				<ws-button
					id="cancel-button"
					:data-testid="`${dataTestid}_cancel_btn`"
					is-small
					is-primary
					is-outlined
					@click="cancel"
				>
					<ws-icon icon="close" />
				</ws-button>
				<ws-button
					id="save-button"
					is-small
					is-primary
					:data-testid="`${dataTestid}_save_btn`"
					@click="save"
				>
					<ws-icon icon="check" />
				</ws-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modelValue: {
			type: [String, Number, null],
			default: null
		},
		dataTestid: {
			type: String,
			default: () => "ws_inline_edit"
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			showInput: false,
			value: this.modelValue,
			error: null
		};
	},

	emits: ["update:modelValue"],
	methods: {
		validate() {
			if (this.value.trim() === "") {
				throw new TypeError(
					this.$t("field-is-required", {
						field: this.$t("name")
					})
				);
			}

			return true;
		},

		save() {
			try {
				this.error = null;
				this.validate();
				this.$emit("update:modelValue", this.value);
				this.hideInput();
			} catch (err) {
				if (err instanceof TypeError) {
					this.error = err.message;
				}
			}
		},

		cancel() {
			this.hideInput();
			this.value = this.modelValue;
		},

		hideInput() {
			this.showInput = false;
			this.error = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.value {
	word-break: break-all;
	padding: 0 0.5rem;

	&:hover {
		cursor: pointer;
		background: $color-grey-300;
		border-radius: 6px;
	}
}
.input-value {
	min-width: 18.5rem;
	height: 40px;
	overflow: visible;

	input {
		padding: 0 0.5rem;
		background: $color-grey-100;
	}
}
.buttons {
	margin-top: 0.25rem;

	.button.is-primary.is-outlined:not(:hover):not(:focus) {
		background: $white;
	}
	.button {
		padding: 0.5rem;
	}
}
</style>
