<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
	>
		<ws-form-label
			v-if="label"
			:id="id"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			{{ label }}
		</ws-form-label>
		<div class="control is-expanded inputField">
			<slot name="message">
				<span v-html="message" />
			</slot>
		</div>
		<p class="help mb-0" v-if="labelUnderInput">
			{{ labelUnderInput }}
		</p>
		<p class="help is-danger" v-if="!!error">{{ error }}</p>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";
export default {
	name: "WsFormMessage",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: [String, null],
			default: null
		},
		message: {
			type: [String, null],
			default: null
		},
		isInline: {
			type: Boolean,
			default: false
		},
		optional: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		tooltip: {
			type: String,
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
	margin: 0;
	line-height: 1.2;
}
</style>
