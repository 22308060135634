<template>
	<div :data-testid="props.dataTestid" class="ws-tag" :class="props.type">
		<slot />
	</div>
</template>
<script setup>
const props = defineProps({
	type: {
		type: String,
		default: "default",
		required: true,
		validation: function (value) {
			return [
				"default",
				"info",
				"success",
				"primary",
				"warning",
				"danger"
			].includes(value);
		}
	},
	dataTestid: {
		type: String,
		default: "ws-tag"
	}
});
</script>
<style lang="scss" scoped>
.ws-tag {
	display: inline-block;
	border: 1px solid $color-grey-300;
	border-radius: 100px;
	padding: 0.5rem;
	height: 40px;
	text-wrap: nowrap;

	&.default {
		border-color: $color-grey-300;
	}
	&.info {
		border-color: $color-info-300;
	}
	&.success {
		border-color: $color-success-300;
	}
	&.primary {
		border-color: $color-primary-300;
	}
	&.warning {
		border-color: $color-warning-300;
	}
	&.danger {
		border-color: $color-danger-300;
	}
}
</style>
