<template>
	<div class="tabs-wrapper is-flex">
		<ws-button
			is-grey
			is-subtle
			@click="scrollLeft()"
			class="navigation-button mr-1"
			v-if="hasChevrons"
			v-tooltip="$t('previous')"
			:disabled="disableScrollLeft"
			data-testid="tabs-navigation-button-left"
		>
			<template #icon>
				<ws-icon icon="chevron" rotate="180" />
			</template>
		</ws-button>

		<div
			class="tabs"
			:class="{ 'no-scroll': hasChevrons }"
			ref="tabsRef"
			@scroll="updateWidths"
			data-testid="tabs-container"
		>
			<slot />
		</div>

		<ws-button
			is-grey
			is-subtle
			@click="scrollRight()"
			class="navigation-button ml-1"
			v-if="hasChevrons"
			v-tooltip="$t('next')"
			:disabled="disableScrollRight"
			data-testid="tabs-navigation-button-right"
		>
			<template #icon>
				<ws-icon icon="chevron" />
			</template>
		</ws-button>

		<slot name="addBtn" />
	</div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useI18n } from "vue-i18n";

const { error: $logError } = useLogger();
const { t: $t } = useI18n();
const tabsRef = ref(null);

const props = defineProps({
	showChevrons: {
		type: Boolean,
		default: false
	}
});

const availableWidth = ref(0);
const actualWidth = ref(0);
const scrollLeftPosition = ref(0);
let resizeObserver = null;

onMounted(() => {
	updateWidths();

	try {
		// This is needed to, when tabs names or length change, to add/remove chevrons
		resizeObserver = new ResizeObserver(updateWidths);
		resizeObserver.observe(tabsRef.value);
	} catch (err) {
		$logError(err);
	}
});

onBeforeUnmount(() => {
	try {
		resizeObserver?.disconnect();
	} catch (err) {
		$logError(err);
	}
});

const hasScroll = computed(() => {
	return availableWidth.value < actualWidth.value;
});
const hasChevrons = computed(() => {
	return props.showChevrons && hasScroll.value;
});

const disableScrollLeft = computed(() => {
	return scrollLeftPosition.value <= 0;
});
const disableScrollRight = computed(() => {
	return actualWidth.value - availableWidth.value <= scrollLeftPosition.value;
});

const updateWidths = () => {
	availableWidth.value = tabsRef.value?.clientWidth || 0;
	actualWidth.value = tabsRef.value?.scrollWidth || 0;
	scrollLeftPosition.value = tabsRef.value?.scrollLeft || 0;
};
const getChildrenPositions = () => {
	if (!tabsRef.value?.children || !tabsRef.value.children?.length) {
		return [];
	}

	let positions = []; // store childs offsetLeft
	for (const child of tabsRef.value.children) {
		positions.push(child.offsetLeft);
	}
	// first element should be at position 0, so offset the others
	const offset = positions[0];
	positions = positions.map((left) => left - offset);
	return positions;
};
const scrollRight = () => {
	const positions = getChildrenPositions().filter(
		(left) => left > tabsRef.value?.scrollLeft // filter out left items that should be hidden
	);
	tabsRef.value.scrollTo(positions[0] || actualWidth.value, 0);
};
const scrollLeft = () => {
	const positions = getChildrenPositions().filter(
		(offsetLeft) => offsetLeft < tabsRef.value?.scrollLeft // filter out right items that are visible
	);
	tabsRef.value.scrollTo(positions[positions.length - 1] || -100, 0);
};
const scrollToStart = () => {
	tabsRef.value.scrollBy(-actualWidth.value, 0);
};
const scrollToEnd = () => {
	setTimeout(() => {
		tabsRef.value?.scrollBy(actualWidth.value, 0);
	}, 100);
};
defineExpose({ scrollToEnd, scrollToStart });
</script>

<style lang="scss" scoped>
.tabs-wrapper {
	border-bottom: 1px solid $border-color;

	margin-bottom: 1rem;

	margin-right: -1rem;
	margin-left: -1.5rem;

	padding-right: 1rem;
	padding-left: 1.5rem;
}
.tabs {
	position: relative; // needed to calculate offsets correctly
	margin: 0 !important;
	justify-content: flex-start;
	font-weight: 600;
	font-size: 1rem;
	&.no-scroll {
		overflow: hidden;
	}
}
.navigation-button {
	margin-top: 1rem;
}
</style>
