<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
	>
		<ws-form-label
			v-if="label || $slots.label"
			:id="id"
			:disabled="disabled"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			<slot name="label">
				{{ label }}
			</slot>
		</ws-form-label>
		<p
			class="help"
			style="margin-bottom: 0.5em; margin-top: -0.5em"
			v-if="textUnderLabel || $slots.textUnderLabel"
		>
			<span v-if="textUnderLabel" v-html="textUnderLabel" />
			<slot name="textUnderLabel" />
		</p>

		<div
			class="control is-expanded inputField"
			:class="{
				'has-icons-left': !!error
			}"
		>
			<textarea
				v-model="content"
				:aria-labelledby="id"
				:id="id"
				:name="name"
				:data-testid="dataTestid"
				class="textarea"
				:class="{
					'is-danger': !!error,
					'is-small': size === 'small',
					'is-medium': size === 'medium',
					'is-large': size === 'large'
				}"
				:rows="rows"
				:placeholder="placeholder"
				:disabled="disabled"
				:required="required"
				@input="handleInput"
			></textarea>
			<p
				class="help mb-0"
				v-if="labelUnderInput || $slots.labelUnderInput"
			>
				<span v-if="labelUnderInput" v-html="labelUnderInput" />
				<slot name="labelUnderInput" />
			</p>
			<p class="help is-danger" v-if="!!error">{{ error }}</p>
		</div>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";
export default {
	name: "WsFormInput",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},

		size: {
			type: String,
			default: null,
			validator: function (value) {
				return ["small", "normal", "medium", "large"].includes(value);
			}
		},

		modelValue: {
			type: null,
			default: ""
		},

		dataTestid: {
			type: null,
			default: ""
		},

		label: {
			type: [String, null],
			default: null
		},
		optional: {
			type: Boolean,
			default: false
		},

		placeholder: {
			type: String,
			default: ""
		},
		name: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		tooltip: {
			type: String,
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		textUnderLabel: {
			type: String,
			default: null
		},
		isInline: {
			type: Boolean,
			default: false
		},
		rows: {
			type: Number,
			default: 10
		}
	},

	emits: ["update:modelValue"],
	data() {
		return {
			content: this.modelValue
		};
	},

	watch: {
		modelValue: function (_newValue) {
			this.content = _newValue;
		}
	},

	methods: {
		handleInput(el) {
			const value = el.target.value;
			return this.$emit("update:modelValue", value);
		}
	}
};
</script>

<style lang="scss" scoped>
textarea {
	padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
	min-height: 40px;
}
</style>
