<template>
	<footer class="panel-footer">
		<slot name="above-buttons" />
		<div class="buttons is-right">
			<slot />
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss" scoped>
footer.panel-footer {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	align-content: flex-end;
	padding: 1rem 1rem;
	width: 100%;
	border-top: 1px solid $border-color;
	background-color: $white;
	.buttons {
		margin-bottom: 0;
		button {
			margin-bottom: 0;
		}
	}
}
</style>
