export default class Monitoring {
	isEnabled = false;

	constructor(isEnabled = false) {
		this.isEnabled = isEnabled;
		// Singleton to avoid multiple instances
		if (Monitoring.instance) {
			return Monitoring.instance;
		}

		if (isEnabled) {
			this._initMonitoring();
		}

		Monitoring.instance = this;
	}

	// implementation of this method should be done on the class that extends this one
	_initMonitoring() {}

	// implementation of this method should be done on the class that extends this one
	setUser() {}
}
