import { ref, onBeforeUnmount } from "vue";
const focusableElements =
	'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const focusableContent = ref([]);
const firstFocusableElement = ref(null);
const lastFocusableElement = ref(null);
let escTrigger = null;
let container = null;

const isElementActive = (elm) => {
	return document.activeElement === elm;
};

export const useTrapFocus = () => {
	// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
	const listenToKeyboard = (event) => {
		if (event.defaultPrevented) {
			return; // Do nothing if event already handled
		}

		switch (event.code) {
			case "Escape":
				if (escTrigger) {
					escTrigger();
				}
				// Consume the event so it doesn't get handled twice
				event.preventDefault();
				break;

			case "Tab":
				if (event.shiftKey) {
					// if shift key pressed for shift + tab combination
					if (isElementActive(firstFocusableElement.value)) {
						lastFocusableElement.value?.focus(); // add focus for the last focusable element
						event.preventDefault();
					}
				} else {
					// if tab key is pressed
					if (isElementActive(lastFocusableElement.value)) {
						// if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.value?.focus(); // add focus for the first focusable element
						event.preventDefault();
					}
				}

				break;
			default:
				break;
		}
	};

	onBeforeUnmount(() => {
		window.removeEventListener("keydown", listenToKeyboard, true);
	});
	return {
		focusableContent,
		firstFocusableElement,
		lastFocusableElement,
		setTrapFocus: (_container, _escTrigger = null, startFocusAt = 1) => {
			window.removeEventListener("keydown", listenToKeyboard, true);
			escTrigger = _escTrigger;
			container = _container;
			focusableContent.value = Array.from(
				container?.querySelectorAll(focusableElements)
			).filter((elm) => {
				// filter out disabled elements
				return !elm.disabled;
			});

			firstFocusableElement.value =
				focusableContent.value?.length && focusableContent.value[0];
			lastFocusableElement.value =
				focusableContent.value?.length &&
				focusableContent.value[focusableContent.value.length - 1];

			// focus second (index 1) focusable element (not disabled)
			if (focusableContent.value?.length > startFocusAt) {
				focusableContent.value[startFocusAt]?.focus();
			} else if (firstFocusableElement.value) {
				firstFocusableElement.value?.focus();
			}

			window.addEventListener("keydown", listenToKeyboard, true);
		}
	};
};
