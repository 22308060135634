<template>
	<li ref="tabElement" :class="{ 'is-active': isActive }">
		<router-link v-bind="$attrs">
			<slot />
		</router-link>
	</li>
</template>

<script>
export default {
	name: "WsViewTabElement",

	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	},

	mounted() {
		this.$refs.tabElement.addEventListener(
			"dragstart",
			this.disableGhostElement,
			false
		);
	},

	beforeUnmount() {
		this.$refs.tabElement.removeEventListener(
			"dragstart",
			this.disableGhostElement,
			false
		);
	},

	methods: {
		disableGhostElement(event) {
			var crt = this.$el.cloneNode(true);
			crt.style.display = "none";
			document.body.appendChild(crt);
			event.dataTransfer.setDragImage(crt, 0, 0);
		}
	}
};
</script>

<style lang="scss" scoped>
li {
	&.is-active {
		a {
			color: $color-primary-500;
			z-index: 10;
			background: transparent !important;
			border: none;
			&:before {
				border-color: $background;
				background: $background;
			}
		}
	}

	&:first-child {
		margin-left: 1rem;
	}
	&:last-child {
		margin-right: 1rem;
	}

	a {
		font-weight: $weight-medium;
		background: transparent;
		border: none;
		position: relative;
		display: inline-block;
		padding: 1em 0.8em 0.5em 0.75em;
		color: inherit;
		text-decoration: none;
		margin: 0;
		padding-right: 20px;
		z-index: 9;
		&:before {
			content: "";
			position: absolute;
			top: 2px;
			right: 0;
			bottom: -2px;
			left: 0;
			z-index: -1;
			border: 0.1em solid $color-grey-200;
			border-bottom: none;
			border-radius: 10px 10px 0 0;
			background: $color-grey-200;
			transform: perspective(15px) rotateX(2deg);
			transform-origin: bottom left;
		}

		&:hover {
			color: $color-primary-500;
			background: transparent !important;
			&:before {
				border-color: $background;
				background: $background;
			}
		}

		&:focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
		}
	}
}
</style>
