<template>
	<ws-dropdown-item
		:icon="icon(status)"
		:icon-side="iconSide"
		:disabled="disabled"
		@click="!disabled && $emit('click')"
	>
		<slot v-if="!status" name="titleStatusOff">
			{{ titleStatusOff }}
		</slot>
		<slot v-else>
			<span>
				{{ title }}
			</span>
		</slot>
	</ws-dropdown-item>
</template>

<script>
export default {
	name: "WsDropdownItemToggle",

	emits: ["click"],

	props: {
		title: {
			type: String,
			default: ""
		},
		titleStatusOff: {
			type: String,
			default: null
		},
		iconSide: {
			type: String,
			default: "left",
			validator: (value) => {
				return ["left", "right"].includes(value);
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		status: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		icon(status) {
			if (status) {
				return "toggle-on";
			}

			return "toggle-off";
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown-item {
	.icon.right {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.text-icon-left {
		margin-left: 5px;
	}
}
</style>
