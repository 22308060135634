<template>
	<header class="panel-header">
		<div class="title">
			<slot />
		</div>
		<ws-button
			is-delete
			class="delete"
			aria-label="close"
			@click="close"
			v-if="showCloseHeader"
			data-testid="side-panel-close-btn"
		/>
	</header>
</template>

<script>
export default {
	props: {
		showCloseHeader: {
			type: Boolean,
			default: true
		}
	},

	emits: ["close"],
	methods: {
		close() {
			this.$emit("close");
		}
	}
};
</script>

<style lang="scss" scoped>
header.panel-header {
	display: flex;
	align-items: center;
	align-self: flex-start;
	justify-content: space-between;
	min-height: 70px;
	background: $color-primary-500;
	padding: 0 15px;
	width: 100%;

	.title {
		margin-bottom: 0;
		font-size: $size-4;
		color: $white;

		span {
			span {
				color: $color-grey-700;
			}
		}
	}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	header.panel-header {
		min-height: 70px;
		height: 70px;
	}
}
</style>
