<template>
	<ws-confirm-dialog
		:show="props.show"
		:type="props.type"
		:title="props.title"
		:body="props.body"
		:show-cancel="false"
		:confirm-label="props.confirmLabel"
		@confirm="confirm()"
		@cancel="confirm()"
	/>
</template>

<script setup>
const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		default: "warning",
		validation: function (value) {
			return ["default", "info", "success", "warning", "danger"].includes(
				value
			);
		}
	},
	title: {
		type: String,
		default: null
	},
	body: {
		type: String,
		default: null
	},
	confirmLabel: {
		type: String,
		default: "Confirm"
	}
});

const emits = defineEmits(["close"]);

function confirm() {
	emits("close");
}
</script>
