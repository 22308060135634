<template>
	<div class="columns is-multiline is-justify-content-center mt-4 p-4">
		<div class="is-flex-column has-text-centered is-align-items-center">
			<slot name="icon" />
			<div class="is-size-5 has-text-weight-semibold mt-4 mb-2">
				<slot name="header" />
			</div>
			<div class="is-size-6 px-30 mb-2" style="max-width: 800px">
				<slot name="description" />
			</div>
			<div
				v-if="$slots.button"
				class="column is-flex is-align-items-center"
			>
				<slot name="button" />
			</div>
		</div>
	</div>
</template>

<script setup></script>

<style scoped lang="scss"></style>
