<template>
	<div
		class="notification"
		:class="{
			[type]: true,
			'is-size-6': isNormal && !isSmall,
			'is-size-7': isSmall && !isNormal,
			'is-full-screen': isFullScreen,
			'is-normal': isNormal && !isSmall,
			'is-small': isSmall && !isNormal
		}"
	>
		<div class="notification-wrapper">
			<div class="notification-icon" v-if="showIcon && icon">
				<ws-icon :icon="icon" />
			</div>
			<div class="notification-content">
				<div class="notification-title" v-if="hasTitle">
					<slot name="title" />
				</div>
				<div class="notification-body">
					<slot />
				</div>
				<div class="notification-footer" v-if="hasFooter">
					<slot name="footer" />
				</div>
			</div>
			<div class="notification-close" v-if="showCloseButton">
				<ws-icon
					icon="close"
					size="sm"
					@click="closeNotification()"
					data-testid="button-close-notification"
				/>
			</div>
		</div>
	</div>
</template>

<script>
const defaultFalsePropDefinition = {
	type: Boolean,
	default: false
};
export default {
	name: "WsNotificationMessage",

	emits: ["closed"],

	props: {
		type: {
			type: String,
			default: "default",
			validation: function (value) {
				return [
					"default",
					"info",
					"success",
					"primary",
					"warning",
					"danger"
				].includes(value);
			}
		},

		showIcon: defaultFalsePropDefinition,
		showCloseButton: defaultFalsePropDefinition,
		// sizes
		isSmall: defaultFalsePropDefinition,
		isNormal: defaultFalsePropDefinition,

		// no spacing, no border, no border-radius
		isFullScreen: defaultFalsePropDefinition
	},

	computed: {
		hasTitle() {
			return !!this.$slots?.title;
		},
		hasFooter() {
			return !!this.$slots?.footer;
		},
		icon() {
			switch (this.type) {
				case "primary":
				case "success":
					return "circle-check";
				case "info":
					return "info";
				case "warning":
				case "danger":
					return "alert";
				default:
					return "lightbulb";
			}
		}
	},
	methods: {
		closeNotification() {
			this.$emit("closed");
			// // destroy the vue listeners, etc
			// this.$destroy();
			// remove the element from the DOM
			this.$el.parentNode.removeChild(this.$el);
		}
	}
};
</script>
<style lang="scss" scoped>
.notification {
	align-items: center;
	padding: 1rem;
	background-color: $color-grey-100;
	border: 1px solid $color-grey-300;
	border-radius: 6px !important;
	color: $color-grey-700;
	.notification-icon {
		color: $color-grey-700;
	}

	&.is-full-screen {
		margin: 0;
		border: none !important;
		border-radius: 0 !important;
	}
	&.is-normal .notification-icon {
		:deep(svg) {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
	&.is-small .notification-icon {
		:deep(svg) {
			width: 1rem;
			height: 1rem;
		}
	}

	&.primary {
		background-color: $color-primary-50;
		border: 1px solid $color-primary-200;
		.notification-icon {
			color: $color-primary-500;
		}
	}
	&.success {
		background-color: $color-success-50;
		border: 1px solid $color-success-200;
		.notification-icon {
			color: $color-success-500;
		}
	}
	&.info {
		background-color: $color-info-50;
		border: 1px solid $color-info-200;
		.notification-icon {
			color: $color-info-500;
		}
	}
	&.danger {
		background-color: $color-danger-50;
		border: 1px solid $color-danger-200;
		.notification-icon {
			color: $color-danger-500;
		}
	}
	&.warning {
		background-color: $color-warning-50;
		border: 1px solid $color-warning-200;
		.notification-icon {
			color: $color-warning-700;
		}
	}

	.notification-wrapper {
		width: 100%;
		max-width: 100%;
		display: flex;
		gap: 16px;

		.notification-content {
			width: 100%;
			overflow-x: auto;
			overflow-y: hidden;

			.notification-title {
				font-weight: 600;
				margin-bottom: 0.5rem;
			}
			.notification-footer {
				margin-top: 1rem;
			}
		}
		.notification-close {
			padding-left: 0.5rem;
			cursor: pointer;
		}
	}
}
</style>
