export const MANUFACTURER = "manufacturer";
export const MODEL = "model";
export const DEV_EUI = "devEUI";
export const APP_EUI = "appEUI";
export const APP_KEY = "appKey";
export const WATTSENSE_CODEC_ID = "productReference";
export const WATTSENSE_CODEC_EQUIPMENT_ID = "productEquipmentId";
export const GROUP = "group";
export const DESCRIPTION = "description";
export const ERRORS = "errors";
export const NAME = "name in console (let empty to use manufacturer & name)";

export const requiredCsvFileHeaders = [
	MANUFACTURER,
	MODEL,
	DEV_EUI,
	APP_EUI,
	APP_KEY
];

export const csvFileHeaders = [
	...requiredCsvFileHeaders.slice(0, 2),
	NAME,
	...requiredCsvFileHeaders.slice(2),
	DESCRIPTION,
	GROUP
];

export const allowedCsvFileHeaders = [
	...csvFileHeaders,
	WATTSENSE_CODEC_ID,
	WATTSENSE_CODEC_EQUIPMENT_ID,
	ERRORS
];

/**
 * Map template file header to Wattsense header (used in the code) - but only the different header names.
 * CSV file headers to Datatable headers
 */
export const mapTemplateHeaderToWattsenseHeader = {
	[NAME]: "name"
};

/**
 * Map Wattsense header (used in the code) to template file header - but only the different header names.
 * Datatable headers to CSV File headers
 */
export const mapWattsenseHeaderToTemplateHeader = Object.entries(
	mapTemplateHeaderToWattsenseHeader
).reduce(
	(acc, [key, value]) => ({
		...acc,
		[value]: key
	}),
	{}
);

export function wsHeaderFromTemplate(templateName) {
	return mapTemplateHeaderToWattsenseHeader[templateName] || templateName;
}

export function templateHeaderFromWattsense(wsName) {
	return mapWattsenseHeaderToTemplateHeader[wsName] || wsName;
}

export function formatLine(line) {
	return {
		[wsHeaderFromTemplate(MANUFACTURER)]: line.manufacturer,
		[wsHeaderFromTemplate(MODEL)]: line.model,
		[wsHeaderFromTemplate(NAME)]: line[NAME],
		[wsHeaderFromTemplate(DEV_EUI)]: line.devEUI,
		[wsHeaderFromTemplate(APP_EUI)]: line.appEUI,
		[wsHeaderFromTemplate(APP_KEY)]: line.appKey,
		[wsHeaderFromTemplate(DESCRIPTION)]: line.description,
		[wsHeaderFromTemplate(GROUP)]: line.group,
		[wsHeaderFromTemplate(WATTSENSE_CODEC_ID)]: line.productReference,
		[wsHeaderFromTemplate(WATTSENSE_CODEC_EQUIPMENT_ID)]:
			line[WATTSENSE_CODEC_EQUIPMENT_ID]
	};
}
