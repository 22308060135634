import { createI18n } from "vue-i18n";
import { useClientName } from "@/plugins/client-name/client-name.plugin.js";

import enJon from "./locales/en.json";
import deJon from "./locales/de.json";
import frJon from "./locales/fr.json";

const locale = "en";
const fallbackLocale = "en";

export const messages = {
	en: enJon,
	de: deJon,
	fr: frJon
};

function getProductNames() {
	const { productNames } = useClientName();
	return productNames;
}

/*
examples:
"@.clientname:_hub" => clientname($t('hub')) => clientname("hub") => Bridge
"@.clientname:_generic-plural" => clientname($t('_generic-plural')) => clientname("genericPlural") => Boxes
"boxes" => "@.clientname:_generic-plural" => clientname($t('_generic-plural')) => clientname("genericPlural") => Boxes
"Blablabalbla @.upper:{'hubs'}!!" => "Blablabalbla upper(@.clientname:_generic-plural)!!" =...> "Blablabalbla upper("Boxes")!!" => "Blablabalbla BOXES!!"
*/
export const modifiers = {
	/**
	 * "@.clientname:box|hub|boxes|hubs"
	 */
	clientname: (type) => {
		const productNames = getProductNames();
		return productNames[type] ?? productNames.generic;
	}
};

export const i18n = createI18n({
	globalInjection: true,
	messages,
	locale,
	fallbackLocale,
	modifiers: modifiers,
	warnHtmlMessage: false, // don't show warn when using HTML inside translations
	legacy: false,
	allowComposition: true
});

export const installi18n = {
	install(app) {
		app.use(i18n);
	}
};

export default i18n.global;
