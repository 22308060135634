<template>
	<div v-if="updateAvailable">
		<ws-notification-message type="info" :is-full-screen="true">
			{{ $t("banner.cache-refresher.body") }}
			<ws-text-link
				@click="refreshPage"
				:is-link="true"
				:show-icon="false"
			>
				{{ $t("banner.cache-refresher.click-to-refresh") }}
			</ws-text-link>
		</ws-notification-message>
	</div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Worker from "./worker.js?worker";
import { useLogger } from "@/plugins/logger/logger.plugin.js";

const { debug } = useLogger();
const FETCH_ROUTE = "fetch-latest-for-route-change";
const FETCH_VISIBILITY = "fetch-latest-for-visibility-change";

const updateAvailable = ref(false);

function refreshPage() {
	location.reload();
}

if (typeof Worker !== "undefined") {
	debug("worker available");
	const worker = new Worker();

	document.addEventListener("changedroute", () => {
		worker.postMessage(FETCH_ROUTE);
		// if the event comes from a route change and thre's an update available, refresh the page automatically to user to avoid risk of showing a stale page
		if (updateAvailable.value) {
			refreshPage();
		}
	});

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState === "visible") {
			worker.postMessage(FETCH_VISIBILITY);
		}
	});

	onMounted(() => {
		// worker will send message only when there's an update to be done
		worker.onmessage = (event) => {
			const { data } = event;
			if (!updateAvailable.value) {
				updateAvailable.value = true;
			}
			// if the event comes from a route change, refresh the page automatically to user to avoid risk of showing a stale page
			if (data === FETCH_ROUTE) {
				refreshPage();
			}
		};
	});

	onBeforeUnmount(() => {
		worker.terminate();
	});
} else {
	debug("Web Worker not available");
}
</script>
