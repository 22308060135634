import LogMonitoring from "../models/LogMonitoring.js";
import { datadogLogs } from "@datadog/browser-logs";

class DataDogLogMonitor extends LogMonitoring {
	constructor(isEnabled = false) {
		super(isEnabled);
	}

	_initMonitoring() {
		datadogLogs.init({
			clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
			site: "datadoghq.eu",
			service: "user-console",
			env: import.meta.env.VITE_DATADOG_ENV,
			version: import.meta.env.VERSION + "",
			forwardErrorsToLogs: true,
			forwardConsoleLogs: ["error"],
			sessionSampleRate: 100
		});
	}

	notify(...args) {
		if (this.isEnabled) {
			datadogLogs.logger.error(...args);
		} else {
			console.error(...args); // eslint-disable-line no-console
		}
	}
}

export default DataDogLogMonitor;
