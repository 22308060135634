<template>
	<div class="vue-treeselect">
		<div ref="treeselectContainerRef" />
		<div
			ref="treeselectAfterListSlotRef"
			class="treeselect__after-list-slot"
		>
			<slot />
		</div>
	</div>
</template>

<script>
// this file is the same from https://github.com/dipson88/vue-treeselectjs/blob/main/src/Treeselect.vue (but using a different version of treeselectjs)
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	toRaw,
	watch
} from "vue";
// this version is the main verison of https://github.com/dipson88/treeselectjs + https://github.com/dipson88/treeselectjs/pull/91 + https://github.com/dipson88/treeselectjs/pull/92 + https://github.com/WattSense/treeselectjs/tree/improve-search
import Treeselect from "./treeselectjs.js";

const keysWithoutRender = ["modelValue", "options", "id", "iconElements"];

export default defineComponent({
	name: "Treeselect",
	props: {
		modelValue: {
			type: [Array, Number, String],
			default: () => []
		},
		options: {
			type: Array,
			default: () => []
		},
		openLevel: {
			type: Number,
			default: 0
		},
		appendToBody: {
			type: Boolean,
			default: false
		},
		alwaysOpen: {
			type: Boolean,
			default: false
		},
		showTags: {
			type: Boolean,
			default: true
		},
		tagsCountText: {
			type: String,
			default: "elements selected"
		},
		clearable: {
			type: Boolean,
			default: true
		},
		searchable: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: "Select..."
		},
		grouped: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		emptyText: {
			type: String,
			default: "No results"
		},
		staticList: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		},
		ariaLabel: {
			type: String,
			default: ""
		},
		isSingleSelect: {
			type: Boolean,
			default: false
		},
		showCount: {
			type: Boolean,
			default: false
		},
		isGroupedValue: {
			type: Boolean,
			default: false
		},
		disabledBranchNode: {
			type: Boolean,
			default: false
		},
		direction: {
			type: String,
			default: "auto"
		},
		expandSelected: {
			type: Boolean,
			default: false
		},
		saveScrollPosition: {
			type: Boolean,
			default: true
		},
		isIndependentNodes: {
			type: Boolean,
			default: false
		},
		rtl: {
			type: Boolean,
			default: false
		},
		iconElements: {
			type: Object,
			default: () => ({})
		},
		showPlaceholderOnOpen: {
			type: Boolean,
			default: false
		},
		unselectOnClickSingleSelected: {
			type: Boolean,
			default: false
		},
		defaultPadding: {
			type: Number,
			default: null
		},
		zeroLevelItemPadding: {
			type: Number,
			default: null
		}
	},
	emits: [
		"input",
		"open",
		"close",
		"name-change",
		"search",
		"open-close-group",
		"update:modelValue"
	],
	setup(props, { emit }) {
		const treeselectContainerRef = ref(null);
		const treeselectAfterListSlotRef = ref(null);
		const treeselect = ref(null);

		const onInput = (modelValue) => {
			emit("update:modelValue", modelValue);
			emit("input", modelValue);
		};
		const onOpen = (value) => emit("open", value);
		const onClose = (value) => emit("close", value);
		const onNameChange = (name) => emit("name-change", name);
		const onSearch = (value) => emit("search", value);
		const onOpenCloseGroup = (groupId, isClosed) =>
			emit("open-close-group", {
				groupId,
				isClosed
			});

		const valueString = computed(() => JSON.stringify(props.modelValue));
		const optionsArrayString = computed(() =>
			JSON.stringify(props.options)
		);
		const iconsElementsString = computed(() =>
			JSON.stringify(props.iconElements)
		);

		watch(
			() => props,
			(newProps) => {
				if (treeselect.value) {
					const rawTreeselect = toRaw(treeselect.value);
					const rawProps = toRaw(newProps);
					let isAnyChanged = false;

					Object.keys(rawProps).forEach((key) => {
						// @ts-ignore
						const isTheSameValue =
							rawProps[key] === rawTreeselect[key];
						const isWithRender = keysWithoutRender.includes(key);

						if (!isWithRender && !isTheSameValue) {
							// @ts-ignore
							rawTreeselect[key] = rawProps[key];
							isAnyChanged = true;
						}
					});

					if (isAnyChanged) {
						rawTreeselect.mount();
					}
				}
			},
			{ deep: true }
		);

		watch(
			() => valueString.value,
			() => {
				if (treeselect.value) {
					const rawTreeselect = toRaw(treeselect.value);
					rawTreeselect.updateValue(props.modelValue);
				}
			}
		);

		watch(
			() => props.id,
			(newId) => {
				if (treeselect.value) {
					const rawTreeselect = toRaw(treeselect.value);
					const isTreeselectIdExist = rawTreeselect.id || newId;

					if (isTreeselectIdExist) {
						rawTreeselect.id = newId ?? "";
						rawTreeselect.mount();
					}
				}
			}
		);

		watch(
			() => optionsArrayString.value,
			() => {
				if (treeselect.value) {
					const rawTreeselect = toRaw(treeselect.value);
					rawTreeselect.options = props.options;
					rawTreeselect.mount();
				}
			}
		);

		watch(
			() => iconsElementsString.value,
			() => {
				if (treeselect.value) {
					const rawTreeselect = toRaw(treeselect.value);
					rawTreeselect.iconElements = {
						...rawTreeselect.iconElements,
						...props.iconElements
					};
					rawTreeselect.mount();
				}
			}
		);

		onMounted(() => {
			if (!treeselectContainerRef.value) {
				throw new Error("Treeselect container ref is not defined");
			}

			treeselect.value = new Treeselect({
				parentHtmlContainer: treeselectContainerRef.value,
				value: props.modelValue,
				options: props.options,
				openLevel: props.openLevel,
				appendToBody: props.appendToBody,
				alwaysOpen: props.alwaysOpen,
				showTags: props.showTags,
				tagsCountText: props.tagsCountText,
				clearable: props.clearable,
				searchable: props.searchable,
				placeholder: props.placeholder,
				grouped: props.grouped,
				disabled: props.disabled,
				emptyText: props.emptyText,
				staticList: props.staticList,
				id: props.id,
				ariaLabel: props.ariaLabel,
				isSingleSelect: props.isSingleSelect,
				showCount: props.showCount,
				isGroupedValue: props.isGroupedValue,
				disabledBranchNode: props.disabledBranchNode,
				direction: props.direction,
				expandSelected: props.expandSelected,
				saveScrollPosition: props.saveScrollPosition,
				isIndependentNodes: props.isIndependentNodes,
				rtl: props.rtl,
				inputCallback: onInput,
				openCallback: onOpen,
				closeCallback: onClose,
				nameChangeCallback: onNameChange,
				searchCallback: onSearch,
				openCloseGroupCallback: onOpenCloseGroup,
				// We need a HTMLElement as a prop here. It is an additional component at the end of list.
				// We gets HTMLElement from refs. Vue events work fine.
				listSlotHtmlComponent: treeselectAfterListSlotRef.value ?? null,
				iconElements: props.iconElements,
				showPlaceholderOnOpen: props.showPlaceholderOnOpen,
				unselectOnClickSingleSelected:
					props.unselectOnClickSingleSelected,
				defaultPadding: props.defaultPadding,
				zeroLevelItemPadding: props.zeroLevelItemPadding
			});
		});

		onUnmounted(() => {
			if (treeselect.value) {
				const rawTreeselect = toRaw(treeselect.value);
				rawTreeselect.destroy();
			}
		});

		return {
			treeselectContainerRef,
			treeselectAfterListSlotRef
		};
	}
});
</script>

<style lang="css" scoped>
@import "treeselectjs/dist/treeselectjs.css";

.vue-treeselect {
	display: block;
	width: 100%;
	box-sizing: border-box;
}
</style>
