import Monitoring from "../models/Monitoring.js";
import { datadogRum } from "@datadog/browser-rum";

class DataDogMonitoring extends Monitoring {
	_isUserSet = false;

	constructor(isEnabled = false) {
		super(isEnabled);
	}

	_initMonitoring() {
		datadogRum.init({
			applicationId: import.meta.env.VITE_DATADOG_APP_ID,
			clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
			site: "datadoghq.eu",
			service: "user-console",
			env: import.meta.env.VITE_DATADOG_ENV,
			version: import.meta.env.VERSION + "",
			sessionSampleRate: 100,
			sessionReplaySampleRate: 80,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: "mask-user-input",
			allowedTracingUrls: [/https:\/\/.*\.wattsense\.com\/.*/],
			traceSampleRate: 100
		});
	}

	setUser({ name, email }) {
		if (!this._isUserSet) {
			datadogRum.setUser({
				name,
				email
			});

			this._isUserSet = true;
		}
	}
}

export default DataDogMonitoring;
