<template>
	<div>
		<label
			class="label"
			:class="{
				'has-text-danger-500': props.error
			}"
		>
			<slot>
				{{ props.label }}
			</slot>
		</label>
		<ws-radio-input
			v-for="option of availableOptions"
			:key="option.value"
			v-model="modelValue"
			:input-value="option.value"
			:checked="option.value === modelValue"
			:required="props.required"
		>
			<div class="is-flex is-flex-direction-column">
				{{ option.label }}
				<div class="is-size-7" v-if="option.description">
					{{ option.description }}
				</div>
			</div>
		</ws-radio-input>
		<p class="help is-danger" v-if="!!props.error">
			{{ props.error }}
		</p>
	</div>
</template>

<script setup>
import { watch, watchEffect, computed, ref } from "vue";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: null,
		required: true,
		default: null
	},
	label: {
		type: String,
		default: null
	},
	error: {
		type: String,
		default: null
	},
	/** Accepts 2 types: array of strings OR array of objects {label: string, value: any} */
	options: {
		type: Array,
		required: true,
		validation: (value) => {
			if (!Array.isArray(value)) {
				return false;
			}
			for (const option of value) {
				if (typeof option === "string") {
					continue;
				} else if (typeof option === "object") {
					try {
						const keys = Object.keys(option);
						if (keys.includes("label") && keys.includes("value")) {
							continue;
						}

						return false;
					} catch (err) {
						return false;
					}
				}
			}

			return true;
		}
	},
	required: {
		type: Boolean,
		default: false
	}
});

const modelValue = ref(props.modelValue ?? null);
watchEffect(() => (modelValue.value = props.modelValue));
watch(modelValue, (newValue) => {
	emits("update:modelValue", newValue);
});

const availableOptions = computed(() => {
	const options = props.options;
	const final = [];
	for (const option of options) {
		if (typeof option === "string") {
			final.push({
				label: option,
				value: option
			});
		} else {
			final.push(option);
		}
	}

	return final;
});
</script>
