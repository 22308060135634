<template>
	<div
		class="dropzone ws-csv-dropzone"
		:class="{ 'dropzone-no-area': hasErrors || success }"
		:id="id"
		data-testid="ws-csv-dropzone"
	>
		<div class="dz-message px-2 py-4">
			<div class="dz-input">
				<template v-if="success">
					<ws-icon
						icon="circle-check"
						size="6x"
						class="has-text-success"
					></ws-icon>
					<p class="mb-1 is-size-5 has-text-weight-semibold">
						{{ $t("import-success") }}
					</p>
				</template>
				<template v-else-if="hasErrors">
					<ws-icon
						icon="alert"
						size="6x"
						class="has-text-danger"
					></ws-icon>
					<p class="mb-1 is-size-5 has-text-weight-semibold">
						{{ $t("import-again") }}
					</p>
				</template>
				<template v-else>
					<ws-icon
						icon="upload"
						size="6x"
						class="mb-2 has-text-grey"
					></ws-icon>
					<p class="mb-1">
						{{ $t("drag-drop-csv") }}
					</p>
					<p class="is-lowercase">{{ $t("or") }}</p>
				</template>

				<ws-form-file-input
					class="mx-auto my-4"
					v-model="uploadedFile"
					:is-primary="!uploadedFile"
					disabled
					can-delete
					placeholder=""
					:hide-file-name="!uploadedFile"
					use-button
				/>
				<slot name="errors"></slot>
				<div
					class="mb-2 is-size-7"
					:class="{ 'has-text-danger': errorFormat }"
				>
					{{ $t("csv-file-format") }}
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from "vue";
import Dropzone from "dropzone";
import "dropzone/dist/min/dropzone.min.css";
import { generateRandomId } from "@/helpers/functions.helper.js";
import { useWsDropzone } from "./helpers/useWsDropzone.js";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const emit = defineEmits(["failed", "uploaded-list"]);
const props = defineProps({
	protocol: {
		type: String,
		required: true
	},
	success: {
		type: Boolean,
		required: false,
		default: false
	}
});

onMounted(() => {
	Dropzone.autoDiscover = false;
	const dropzoneConfig = {
		maxFiles: 1,
		maxFilesize: 1,
		dictDefaultMessage: $t("drag-file-here"),
		dictFileTooBig: $t("file-too-big"),
		acceptedFiles: "*",
		url: "/",
		autoProcessQueue: false
	};
	myDropzone.value = new Dropzone(`div#${id.value}`, dropzoneConfig);
	myDropzone.value.on("addedfile", (file) => {
		if (file?.size > dropzoneConfig.maxFilesize * 1024 * 1024) {
			const errorMessage = $t("file-too-big-size", {
				sizeMB: dropzoneConfig.maxFilesize
			});
			toast.error(errorMessage);
			emit("failed", errorMessage);
			myDropzone.value.removeFile(file);
			return;
		}
		uploadedFile.value = file;
		fileSelected(file);
		myDropzone.value.removeFile(file);
	});
});

const {
	validateCsvHeader,
	guessCsvHeaderDelimiter,
	csv2Array,
	array2WattsenseArray,
	prepareCsvHeaderItem,
	requiredCsvFileHeaders
} = useWsDropzone({ protocol: props.protocol });
const { t: $t } = useI18n();
const toast = useToast();

const uploadedFile = ref(null);
const myDropzone = ref(null);
const errorFormat = ref(false);
const hasErrors = ref(false);
const id = computed(() => {
	return `ws-dropzone-${generateRandomId()}`;
});

watch(uploadedFile, (newFile) => {
	if (!newFile) {
		hasErrors.value = false;
		errorFormat.value = false;
		emit("failed", null);
	}
});

function fileSelected(file) {
	errorFormat.value = false;
	hasErrors.value = false;
	const reader = new FileReader();
	reader.onload = (loadEvent) => {
		try {
			const delimiter = getDelimiterAndValidateHeader(
				loadEvent.target.result
			);
			const arr = csv2Array(loadEvent.target.result, delimiter);
			validateCsvArray(arr);
			const list = array2WattsenseArray(arr, props.protocol);
			emit("failed", null);
			emit("uploaded-list", list);
		} catch (err) {
			hasErrors.value = true;
			emit("failed", err?.message || "Missing fields on CSV");
		}
	};

	if (file.type === "text/csv" || file?.name?.endsWith(".csv")) {
		reader.readAsBinaryString(file);
		// reader.readAsText(file);
	} else {
		errorFormat.value = true;
		hasErrors.value = true;
		emit("failed", $t("wrong-file-format"));
	}
}

function getDelimiterAndValidateHeader(file) {
	try {
		const delimiter = guessCsvHeaderDelimiter(file);
		if (!delimiter) {
			throw new Error($t("wrong-file-header"));
		}

		if (validateCsvHeader(file, delimiter)) {
			return delimiter;
		}
		throw new Error($t("wrong-file-header"));
	} catch (err) {
		throw new Error(err);
	}
}

function validateCsvArray(list) {
	// normalize all header items
	const preparedRequiredCsvHeaders = requiredCsvFileHeaders.value.map(
		(headerItem) => prepareCsvHeaderItem(headerItem)
	);
	let row = 1;
	for (const item of list) {
		for (const [key, value] of Object.entries(item)) {
			const requiredKeyPresent = preparedRequiredCsvHeaders.includes(
				// normalize key(header) item to compare against required headers
				prepareCsvHeaderItem(key)
			);

			if (requiredKeyPresent && value.toString().trim() === "") {
				throw new Error(`Missing ${key} on line ${row}`);
			}
		}
		row += 1;
	}

	return true;
}
</script>
<style lang="scss" scoped>
.dropzone.ws-csv-dropzone {
	background-color: $color-grey-100;
	max-width: 580px;
	margin: 0 auto;
	border-color: $color-grey-300;
	border-style: dashed;
	border-radius: 0.5em;
	height: 100%;
	padding: 0;

	&.dropzone-no-area {
		background-color: $white;
		border: none;
	}

	.dz-message {
		.dz-input {
			margin: auto;
			max-width: 30em;

			.field {
				width: fit-content;
			}
		}
		.dz-button {
			max-width: 20em !important;
			white-space: pre-line;
			background: $color-primary-500;
			border-radius: 5em;
			width: 10em;
			height: 10em;
			padding: 1em;
		}
		background: $white-o70;
		align-items: center;
		margin: 0;
	}
}
</style>
