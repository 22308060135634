<template>
	<main
		class="panel-main"
		:class="{ 'overflow-visible': overflowVisible }"
		ref="scrollContainer"
		@scroll.passive="toggleShadow"
	>
		<span
			class="shadow-top"
			:class="{ isActive: shadow.top }"
			v-show="overflowVisible"
		/>
		<span
			class="shadow-bottom"
			:class="{ isActive: shadow.bottom }"
			v-show="overflowVisible"
		/>
		<slot />
	</main>
</template>

<script>
import { EventBus } from "@/eventbus";

export default {
	name: "WsPaneMain",

	data() {
		return {
			overflowVisible: false,
			shadow: {
				top: false,
				bottom: false
			}
		};
	},

	created() {
		EventBus.$on("ws-form-select-opened", this.changeOverflowVisible);
	},

	mounted() {
		this.toggleShadow();
	},

	unmounted() {
		EventBus.$off("ws-form-select-opened", this.changeOverflowVisible);
	},

	methods: {
		/**
		 * This will turn overflow to visible to show the entire vue-multiselect component
		 */
		changeOverflowVisible(status) {
			this.overflowVisible = status;
		},

		// Check if shadows are needed.
		toggleShadow() {
			const hasVerticalScrollbar =
				this.$refs.scrollContainer.clientHeight <
				this.$refs.scrollContainer.scrollHeight;
			const scrolledFromTop =
				this.$refs.scrollContainer.offsetHeight +
				this.$refs.scrollContainer.scrollTop;
			const scrolledToTop = this.$refs.scrollContainer.scrollTop === 0;
			const scrolledToBottom =
				scrolledFromTop >= this.$refs.scrollContainer.scrollHeight;
			this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
			this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
		}
	}
};
</script>

<style lang="scss" scoped>
main.panel-main {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	padding: 1.5rem 1rem;
	overflow-x: hidden;
	position: relative;
	width: 100%;
	// overflow: visible; // solution for the tooltips, but it breaks the panel

	&.overflow-visible {
		overflow-y: visible !important;
	}

	.shadow-top,
	.shadow-bottom {
		z-index: 1;
		position: fixed;
		width: 80%;
		border-radius: 6em;
		opacity: 0;
		transition: opacity 0.2s;
		pointer-events: none;
		@include touch {
			width: 100%;
			left: 0;
		}

		&.isActive {
			opacity: 1;
			display: block !important;
		}

		right: 0;
		height: 1em;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		background-image: linear-gradient(
			rgba(#555, 0.1) 0%,
			rgba(#fff, 0) 100%
		);
	}

	.shadow-top {
		top: 0;
		margin-top: 70px;
	}

	.shadow-bottom {
		bottom: 0;
		margin-bottom: 73px;
		transform: rotate(180deg);
	}
}
</style>
