<template>
	<div class="columns is-multiline is-align-content-flex-start">
		<div class="column is-half is-12-touch">
			<ws-form-select
				v-model="isRemote"
				@update:model-value="onChange"
				:label-under-input="$t('access-type-is-remote-help')"
				:options="availableRemoteTypes"
				:allow-null="false"
				required
				:error="!!props.error ? ' ' : null"
			>
				<template #label> {{ remoteToggleLabel }} </template>
			</ws-form-select>
		</div>
		<div class="column is-half is-12-touch" v-if="isRemote == 'yes'">
			<ws-form-select
				v-model="accessType"
				@update:model-value="onChange"
				:label="$t('access-type')"
				:options="availableAccessTypes"
				:allow-null="false"
				required
				:error="!!props.error ? ' ' : null"
			/>
		</div>
		<div
			class="column is-full help is-danger my-0 py-0"
			v-if="!!props.error"
		>
			{{ props.error }}
		</div>
	</div>
</template>
<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n({});

const accessType = ref("");
// ws-form-select weird behaviour with booleans & 0/1
const isRemote = ref("");
const emitters = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: null
	},
	protocol: {
		type: String,
		default: null
	},
	error: {
		type: String,
		default: null
	},
	isHub: {
		type: [Boolean, null],
		default: false
	},
	redirectionAccessType: {
		type: String,
		default: null
	}
});

const availableRemoteTypes = computed(() => {
	return [
		{
			label: $t("yes"),
			value: "yes"
		},
		{
			label: $t("no"),
			value: "no"
		}
	];
});
const availableAccessTypes = computed(() => {
	if (props.redirectionAccessType) {
		switch (props.redirectionAccessType) {
			case "REMOTE_READ_ONLY":
				return [
					{
						label: $t("write-only-desc"),
						value: "REMOTE_WRITE_ONLY"
					}
				];
			case "REMOTE_WRITE_ONLY":
				return [
					{
						label: $t("read-only-desc"),
						value: "REMOTE_READ_ONLY"
					}
				];

			case "REMOTE_READ_WRITE":
			default:
				return [
					{
						label: $t("read-only-desc"),
						value: "REMOTE_READ_ONLY"
					},
					{
						label: $t("write-only-desc"),
						value: "REMOTE_WRITE_ONLY"
					},
					{
						label: $t("read-and-write-desc"),
						value: "REMOTE_READ_WRITE"
					}
				];
		}
	}
	switch (props.protocol) {
		// REMOTE_READ_WRITE (technical preview): the property will read data and forward it to wattsense cloud and also takes data from wattsense cloud and write it to an equipment. For the moment, only modbus and bacnet protocols are accepted. This is an experimental feature.

		// KNX_LTE				Y
		// KNX_S				Y
		// MODBUS_SERIAL		Y
		// MODBUS_IP			Y
		// BACNET_IP			Y
		// LON_FT10				N
		// LON_IP852			N
		// LPB					Y
		// LORAWAN_V1_0			N
		// MBUS					Y
		// WMBUS				Y
		// KNX					Y
		// BACNET_IP_GATEWAY	N
		// MODBUS_IP_GATEWAY	N
		// PLUGIN_GATEWAY		Y
		// MQTT_GATEWAY			N

		case "LORAWAN_V1_0":
		case "LON_FT10":
		case "LON_IP852":
		case "MQTT_GATEWAY":
		case "MODBUS_IP_GATEWAY":
		case "BACNET_IP_GATEWAY":
			return [
				{
					label: $t("read-only-desc"),
					value: "REMOTE_READ_ONLY"
				},
				{
					label: $t("write-only-desc"),
					value: "REMOTE_WRITE_ONLY"
				}
			];

		default:
			return [
				{
					label: $t("read-only-desc"),
					value: "REMOTE_READ_ONLY"
				},
				{
					label: $t("write-only-desc"),
					value: "REMOTE_WRITE_ONLY"
				},
				{
					label: $t("read-and-write-desc"),
					value: "REMOTE_READ_WRITE"
				}
			];
	}
});

const remoteToggleLabel = computed(() => {
	return props.isHub
		? $t("access-type-is-remote-hub")
		: $t("access-type-is-remote");
});

watch(
	() => props.modelValue,
	(_new) => {
		const newValue = _new || "";
		accessType.value = newValue;
		if (newValue === "LOCAL") {
			isRemote.value = "no";
		} else if (newValue) {
			isRemote.value = "yes";
		} else {
			isRemote.value = null;
		}
		if (newValue !== _new) {
			emitters("update:modelValue", newValue);
		}
	},
	{ immediate: true }
);

const onChange = () => {
	let newValue = accessType.value;
	if (isRemote.value === "no" && accessType.value !== "LOCAL") {
		newValue = "LOCAL";
	} else if (
		isRemote.value === "yes" &&
		(accessType.value === "LOCAL" || !accessType.value)
	) {
		newValue = availableAccessTypes.value.length
			? availableAccessTypes.value[0]?.value
			: "REMOTE_READ_ONLY";
	}

	emitters("update:modelValue", newValue);
};
</script>
