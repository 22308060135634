import { naturalSorting } from "@/helpers/functions.helper.js";
import unitsJson from "./units.json";

export default {
	units: [],
	async getUnits() {
		if (this.units.length <= 0) {
			this.units = unitsJson.sort((unitA, unitB) =>
				naturalSorting(unitA.label, unitB.label)
			);
		}

		return Promise.resolve(this.units);
	}
};
