<template>
	<div class="field">
		<ws-form-label
			v-if="props.label"
			:id="props.id"
			:error="props.error"
			:optional="props.optional"
			:tooltip="props.tooltip"
			:tooltip-position="props.tooltipPosition"
		>
			{{ label }}
		</ws-form-label>

		<div class="control is-expanded">
			<div
				class="file is-fullwidth"
				:class="{
					'is-danger': !!props.error,
					'has-name': !props.hideFileName
				}"
			>
				<label
					class="file-label"
					:class="{ 'is-justify-content-center': props.hideFileName }"
				>
					<input
						:disabled="props.disabled"
						:id="props.id"
						:data-testid="props.dataTestid"
						:aria-labelledby="props.id"
						class="file-input"
						type="file"
						@blur="handleBlur"
						@click="handleFileBtn"
						@change="handleFileInput"
						:accept="props.accept"
					/>
					<ws-button
						v-if="useButton"
						class="file-btn"
						:class="{ 'is-focus-visible': btnFocused }"
						font-weight="normal"
						:is-primary="props.isPrimary"
						@blur="handleBlur"
						@click="handleFileBtn"
					>
						<template #icon>
							<ws-icon size="md" icon="upload" />
						</template>
						<template v-if="!content && !props.placeholder">
							{{ $t("choose-a-file") }}
						</template>
					</ws-button>
					<span
						v-else
						class="file-cta"
						:class="{
							'is-focus-visible': btnFocused,
							'has-file': content && content.name
						}"
					>
						<span class="file-icon">
							<ws-icon size="md" icon="upload" />
						</span>
						<span
							class="file-label"
							v-if="!content && !props.placeholder"
						>
							{{ $t("choose-a-file") }}
						</span>
					</span>
					<span v-if="!props.hideFileName" class="file-name is-flex">
						<div class="fileNameText">
							{{ (content && content.name) || props.placeholder }}
						</div>
						<ws-icon
							class="fileNameRemoveBtn"
							v-tooltip="$t('delete')"
							v-if="
								content &&
								content.name &&
								(!props.disabled || props.canDelete) &&
								!props.placeholder
							"
							icon="close"
							size="xs"
							@click.stop.prevent="
								(!props.disabled || props.canDelete) &&
									removeFile()
							"
							tabindex="0"
						/>
					</span>
				</label>
			</div>
		</div>
		<slot name="labelUnderInput">
			<p class="help mb-0" v-if="props.labelUnderInput">
				{{ props.labelUnderInput }}
			</p>
		</slot>
		<p class="help is-danger" v-if="!!props.error">{{ props.error }}</p>
	</div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { generateRandomId } from "@/helpers/functions.helper.js";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const { error: $logError } = useLogger();
const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	id: {
		type: String,
		default: () => {
			return generateRandomId();
		}
	},
	modelValue: {
		type: null,
		default: null,
		validator: (value) => {
			return value === null || value instanceof File;
		}
	},
	label: {
		type: String,
		default: null
	},
	optional: {
		type: Boolean,
		default: false
	},
	dataTestid: {
		type: String,
		default: ""
	},
	placeholder: {
		type: String,
		default: ""
	},
	disabled: {
		type: Boolean,
		default: false
	},
	error: {
		type: String,
		default: null
	},
	tooltip: {
		type: String,
		default: null
	},
	tooltipPosition: {
		type: String,
		default: "top"
	},
	labelUnderInput: {
		type: String,
		default: null
	},
	accept: {
		type: String,
		default: null
	},
	isPrimary: {
		type: Boolean,
		default: false
	},
	hideFileName: {
		type: Boolean,
		default: false
	},
	canDelete: {
		type: Boolean,
		default: false
	},
	useButton: {
		type: Boolean,
		required: false,
		default: false
	}
});

const content = ref(null);
const btnFocused = ref(false);
watchEffect(() => {
	content.value = props.modelValue;
	btnFocused.value = false;
});

async function handleFileInput($event) {
	btnFocused.value = false;
	try {
		if ($event.target?.files?.length > 0) {
			content.value = $event.target.files[0];
			emits("update:modelValue", content.value);
			$event.target.value = "";
		}
	} catch (err) {
		$logError("handleFileInput -> err", err);
		content.value = null;
	}
}

function handleBlur() {
	btnFocused.value = false;
}

function handleFileBtn() {
	btnFocused.value = true;
}

function removeFile() {
	content.value = null;
	emits("update:modelValue", null);
}
</script>

<style lang="scss" scoped>
.file-cta {
	border-radius: 0.25rem !important;
	padding-left: 0.5rem;
	padding-right: 0.5rem;

	&.is-focus-visible {
		outline-offset: 3px;
		outline: 2px auto $color-info-500;
	}

	&.has-file {
		width: 40px;
		padding: 0;
		justify-content: center;
		.file-icon {
			margin-right: 0;
		}
	}
}
.file-btn {
	background-color: $color-grey-200;
	border: none;
	&:hover {
		background-color: $grey-light;
	}
}

.file-label {
	overflow: unset;
}
.file-name {
	border: none;
	padding: 0.5rem;
	.fileNameText {
		max-width: calc(100% - 1.5rem);
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-right: 0.5rem;
	}
	.fileNameRemoveBtn {
		font-size: $size-6;
		padding: 0.5rem;
		border-radius: 0.25rem;
		&:hover {
			background: $color-grey-200;
		}
	}
}
</style>
