<template>
	<td
		:key="`ws_datatable_row_td_${column.selector}`"
		:style="column.width ? `width: ${column.width}` : ''"
		:class="[
			{
				'is-right': column.selector == 'actions'
			},
			tdClass
		]"
	>
		<slot />
	</td>
</template>

<script>
export default {
	name: "Row",
	props: {
		column: {
			type: Object,
			default: () => ({}),
			required: true
		},
		tdClass: {
			type: String,
			default: ""
		}
	}
};
</script>
