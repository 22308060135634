<template>
	<div class="field">
		<p class="control has-icons-left has-icons-right is-expanded">
			<input
				type="text"
				class="input"
				:placeholder="defaultPlaceholder"
				v-model="term"
				@input="search($event)"
				data-testid="search-field"
			/>
			<span class="icon is-left" @click="search">
				<ws-icon icon="search" size="md" />
			</span>
			<span class="icon is-right">
				<button
					role="button"
					type="button"
					class="delete is-small"
					@click="eraseSearch"
				/>
			</span>
		</p>
	</div>
</template>

<script setup>
import { nextTick, onUnmounted, ref, watch, computed } from "vue";
import EventBus from "@/eventbus.js";
import { useI18n } from "vue-i18n";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: [String, null],
		default: null
	},
	placeholder: {
		type: String,
		default: null
	},
	debounce: {
		type: Boolean,
		default: true
	}
});

const { t: $t } = useI18n();

const term = ref(props.modelValue);
watch(
	() => props.modelValue,
	(newValue) => {
		term.value = newValue;
		search();
	}
);

const defaultPlaceholder = computed(() => {
	return props.placeholder || $t("search-for-a-property");
});

EventBus.$on("ws-search-field-change-text", async (text) => {
	term.value = text;
	await search();
});

onUnmounted(() => {
	EventBus.$off("ws-search-field-change-text");
});

async function eraseSearch() {
	clearSearch();
	await search();
}

let timeout;
async function search() {
	if (props.debounce) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			emits("update:modelValue", term.value);
		}, 300);
	} else {
		await nextTick();
		emits("update:modelValue", term.value);
	}
}

function clearSearch() {
	term.value = null;
}
</script>

<style lang="scss" scoped>
.field {
	flex-grow: 1;
	margin-bottom: 0 !important;

	.icon {
		color: $color-grey-500 !important;
	}
}
</style>
