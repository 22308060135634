var pi = Object.defineProperty;
var mi = (l, e, t) =>
	e in l
		? pi(l, e, { enumerable: !0, configurable: !0, writable: !0, value: t })
		: (l[e] = t);
var r = (l, e, t) => (mi(l, typeof e != "symbol" ? e + "" : e, t), t),
	kt = (l, e, t) => {
		if (!e.has(l)) throw TypeError("Cannot " + t);
	};
var n = (l, e, t) => (
		kt(l, e, "read from private field"), t ? t.call(l) : e.get(l)
	),
	c = (l, e, t) => {
		if (e.has(l))
			throw TypeError(
				"Cannot add the same private member more than once"
			);
		e instanceof WeakSet ? e.add(l) : e.set(l, t);
	},
	f = (l, e, t, s) => (
		kt(l, e, "write to private field"), s ? s.call(l, t) : e.set(l, t), t
	);
var o = (l, e, t) => (kt(l, e, "access private method"), t);
const Bt = {
		arrowUp:
			'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 15l-6-6-6 6"/></svg>',
		arrowDown:
			'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>',
		arrowRight:
			'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>',
		attention:
			'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>',
		clear: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>',
		cross: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
		check: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>',
		partialCheck:
			'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 25" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg>'
	},
	I = (l, e) => {
		if (((e.innerHTML = ""), typeof l == "string")) e.innerHTML = l;
		else {
			const t = l.cloneNode(!0);
			e.appendChild(t);
		}
	},
	Ht = (l) => {
		const e = l ? { ...l } : {};
		return (
			Object.keys(Bt).forEach((t) => {
				e[t] || (e[t] = Bt[t]);
			}),
			e
		);
	},
	fi = (l) =>
		l.reduce(
			(e, { name: t }, s) => (
				(e += t), s < l.length - 1 && (e += ", "), e
			),
			""
		);
var A,
	E,
	H,
	v,
	pe,
	Mt,
	G,
	q,
	me,
	zt,
	fe,
	Rt,
	U,
	Z,
	T,
	B,
	Ce,
	Ft,
	be,
	jt,
	ge,
	Wt,
	Ee,
	Yt,
	ke,
	qt,
	we,
	Zt,
	ve,
	Kt,
	Le,
	$t,
	ye,
	Jt,
	Se,
	Xt,
	xe,
	Qt,
	Ae,
	es,
	Oe,
	ts,
	_e,
	ss,
	Ne,
	is,
	Te,
	ls,
	K,
	wt;
class Ci {
	constructor({
		value: e,
		showTags: t,
		tagsCountText: s,
		clearable: i,
		isAlwaysOpened: a,
		searchable: h,
		placeholder: d,
		disabled: C,
		isSingleSelect: m,
		id: b,
		ariaLabel: g,
		iconElements: k,
		showPlaceholderOnOpen: w,
		inputCallback: S,
		searchCallback: x,
		openCallback: V,
		closeCallback: z,
		keydownCallback: Y,
		focusCallback: gt,
		blurCallback: re,
		nameChangeCallback: Et
	}) {
		// Private methods
		c(this, pe);
		c(this, G);
		c(this, me);
		c(this, fe);
		c(this, U);
		c(this, T);
		c(this, Ce);
		c(this, be);
		c(this, ge);
		c(this, Ee);
		c(this, ke);
		c(this, we);
		c(this, ve);
		c(this, Le);
		c(this, ye);
		c(this, Se);
		c(this, xe);
		c(this, Ae);
		c(this, Oe);
		c(this, _e);
		c(this, Ne);
		c(this, Te);
		// Emits
		c(this, K);
		// Props
		r(this, "value");
		r(this, "showTags");
		r(this, "tagsCountText");
		r(this, "clearable");
		r(this, "isAlwaysOpened");
		r(this, "searchable");
		r(this, "placeholder");
		r(this, "disabled");
		r(this, "isSingleSelect");
		r(this, "id");
		r(this, "ariaLabel");
		r(this, "iconElements");
		r(this, "showPlaceholderOnOpen");
		// InnerState
		r(this, "isOpened");
		r(this, "searchText");
		r(this, "srcElement");
		// PrivateInnerState
		c(this, A, void 0);
		c(this, E, void 0);
		c(this, H, void 0);
		c(this, v, void 0);
		// Callbacks
		r(this, "inputCallback");
		r(this, "searchCallback");
		r(this, "openCallback");
		r(this, "closeCallback");
		r(this, "keydownCallback");
		r(this, "focusCallback");
		r(this, "blurCallback");
		r(this, "nameChangeCallback");
		(this.value = e),
			(this.showTags = t),
			(this.tagsCountText = s),
			(this.searchable = h),
			(this.placeholder = d),
			(this.clearable = i),
			(this.isAlwaysOpened = a),
			(this.disabled = C),
			(this.isSingleSelect = m),
			(this.id = b),
			(this.ariaLabel = g),
			(this.iconElements = k),
			(this.showPlaceholderOnOpen = w ?? !1),
			(this.isOpened = !1),
			(this.searchText = ""),
			f(this, A, o(this, ge, Wt).call(this)),
			f(this, E, o(this, ye, Jt).call(this)),
			f(this, H, o(this, Ae, es).call(this)),
			f(this, v, null),
			(this.inputCallback = S),
			(this.searchCallback = x),
			(this.openCallback = V),
			(this.closeCallback = z),
			(this.keydownCallback = Y),
			(this.focusCallback = gt),
			(this.blurCallback = re),
			(this.nameChangeCallback = Et),
			(this.srcElement = o(this, Ce, Ft).call(
				this,
				n(this, A),
				n(this, E),
				n(this, H)
			)),
			o(this, pe, Mt).call(this);
	}
	// Public methods
	focus() {
		setTimeout(() => n(this, E).focus(), 0);
	}
	blur() {
		this.isOpened && o(this, T, B).call(this),
			this.clearSearch(),
			n(this, E).blur();
	}
	updateValue(e) {
		(this.value = e), o(this, G, q).call(this), o(this, U, Z).call(this);
	}
	removeItem(e) {
		(this.value = this.value.filter((t) => t.id !== e)),
			o(this, K, wt).call(this),
			o(this, G, q).call(this),
			o(this, U, Z).call(this);
	}
	clear() {
		(this.value = []),
			o(this, K, wt).call(this),
			o(this, G, q).call(this),
			this.clearSearch();
	}
	openClose() {
		o(this, T, B).call(this);
	}
	clearSearch() {
		(this.searchText = ""),
			this.searchCallback(""),
			o(this, U, Z).call(this);
	}
}
(A = new WeakMap()),
	(E = new WeakMap()),
	(H = new WeakMap()),
	(v = new WeakMap()),
	(pe = new WeakSet()),
	(Mt = function () {
		o(this, G, q).call(this),
			o(this, U, Z).call(this),
			o(this, me, zt).call(this);
	}),
	(G = new WeakSet()),
	(q = function () {
		if (((n(this, A).innerHTML = ""), this.showTags)) {
			n(this, A).append(...o(this, Ee, Yt).call(this));
			const e = fi(this.value);
			this.nameChangeCallback(e);
		} else {
			const e = o(this, Le, $t).call(this);
			n(this, A).appendChild(e), this.nameChangeCallback(e.innerText);
		}
		n(this, A).appendChild(n(this, E));
	}),
	(me = new WeakSet()),
	(zt = function () {
		const e = [];
		(n(this, H).innerHTML = ""),
			this.clearable && e.push(o(this, Oe, ts).call(this)),
			this.isAlwaysOpened ||
				e.push(o(this, Ne, is).call(this, this.isOpened)),
			e.length && n(this, H).append(...e);
	}),
	(fe = new WeakSet()),
	(Rt = function () {
		if (!this.isAlwaysOpened && n(this, v)) {
			const e = this.isOpened
				? this.iconElements.arrowUp
				: this.iconElements.arrowDown;
			I(e, n(this, v));
		}
	}),
	(U = new WeakSet()),
	(Z = function () {
		var e;
		(e = this.value) != null && e.length
			? (n(this, E).removeAttribute("placeholder"),
			  this.srcElement.classList.remove(
					"treeselect-input--value-not-selected"
			  ))
			: (n(this, E).setAttribute("placeholder", this.placeholder),
			  this.srcElement.classList.add(
					"treeselect-input--value-not-selected"
			  )),
			this.searchable
				? this.srcElement.classList.remove(
						"treeselect-input--unsearchable"
				  )
				: this.srcElement.classList.add(
						"treeselect-input--unsearchable"
				  ),
			this.isSingleSelect
				? this.srcElement.classList.add(
						"treeselect-input--is-single-select"
				  )
				: this.srcElement.classList.remove(
						"treeselect-input--is-single-select"
				  ),
			(n(this, E).value = this.searchText);
	}),
	(T = new WeakSet()),
	(B = function () {
		(this.isOpened = !this.isOpened), o(this, fe, Rt).call(this);
		const e = n(this, A).querySelector(".treeselect-input__tags-count");
		this.isOpened
			? (this.showPlaceholderOnOpen &&
					this.isSingleSelect &&
					this.value.length &&
					(e == null || e.setAttribute("style", "display: none;"),
					n(this, E).setAttribute("placeholder", this.placeholder)),
			  this.openCallback())
			: (this.showPlaceholderOnOpen &&
					this.isSingleSelect &&
					this.value.length &&
					(e == null || e.setAttribute("style", "display: block;"),
					n(this, E).removeAttribute("placeholder")),
			  this.closeCallback());
	}),
	(Ce = new WeakSet()),
	(Ft = function (e, t, s) {
		const i = document.createElement("div");
		return (
			i.classList.add("treeselect-input"),
			i.setAttribute("tabindex", "-1"),
			i.addEventListener("mousedown", (a) =>
				o(this, be, jt).call(this, a)
			),
			i.addEventListener("focus", () => this.focusCallback(), !0),
			i.addEventListener("blur", () => this.blurCallback(), !0),
			e.appendChild(t),
			i.append(e, s),
			i
		);
	}),
	(be = new WeakSet()),
	(jt = function (e) {
		e.stopPropagation(),
			this.isOpened || o(this, T, B).call(this),
			this.focus();
	}),
	(ge = new WeakSet()),
	(Wt = function () {
		const e = document.createElement("div");
		return e.classList.add("treeselect-input__tags"), e;
	}),
	(Ee = new WeakSet()),
	(Yt = function () {
		return this.value.map((e) => {
			const t = document.createElement("div");
			t.classList.add("treeselect-input__tags-element"),
				t.setAttribute("tabindex", "-1"),
				t.setAttribute("tag-id", e.id.toString()),
				t.setAttribute("title", e.name);
			const s = o(this, we, Zt).call(this, e.name),
				i = o(this, ve, Kt).call(this);
			return (
				t.addEventListener("mousedown", (a) =>
					o(this, ke, qt).call(this, a, e.id)
				),
				t.append(s, i),
				t
			);
		});
	}),
	(ke = new WeakSet()),
	(qt = function (e, t) {
		e.preventDefault(),
			e.stopPropagation(),
			this.removeItem(t),
			this.focus();
	}),
	(we = new WeakSet()),
	(Zt = function (e) {
		const t = document.createElement("span");
		return (
			t.classList.add("treeselect-input__tags-name"),
			(t.textContent = e),
			t
		);
	}),
	(ve = new WeakSet()),
	(Kt = function () {
		const e = document.createElement("span");
		return (
			e.classList.add("treeselect-input__tags-cross"),
			I(this.iconElements.cross, e),
			e
		);
	}),
	(Le = new WeakSet()),
	($t = function () {
		const e = document.createElement("span");
		if (
			(e.classList.add("treeselect-input__tags-count"),
			!this.value.length)
		)
			return (e.textContent = ""), e.setAttribute("title", ""), e;
		const t =
			this.value.length === 1
				? this.value[0].name
				: `${this.value.length} ${this.tagsCountText}`;
		return (e.textContent = t), e.setAttribute("title", t), e;
	}),
	(ye = new WeakSet()),
	(Jt = function () {
		const e = document.createElement("input");
		return (
			e.classList.add("treeselect-input__edit"),
			this.id && e.setAttribute("id", this.id),
			(!this.searchable || this.disabled) &&
				e.setAttribute("readonly", "readonly"),
			this.disabled && e.setAttribute("tabindex", "-1"),
			this.ariaLabel.length &&
				e.setAttribute("aria-label", this.ariaLabel),
			e.addEventListener("keydown", (t) => o(this, Se, Xt).call(this, t)),
			e.addEventListener("input", (t) =>
				o(this, xe, Qt).call(this, t, e)
			),
			e
		);
	}),
	(Se = new WeakSet()),
	(Xt = function (e) {
		e.stopPropagation();
		const t = e.key;
		t === "Backspace" &&
			!this.searchText.length &&
			this.value.length &&
			!this.showTags &&
			this.clear(),
			t === "Backspace" &&
				!this.searchText.length &&
				this.value.length &&
				this.removeItem(this.value[this.value.length - 1].id),
			e.code === "Space" &&
				(!this.searchText || !this.searchable) &&
				o(this, T, B).call(this),
			(t === "Enter" || t === "ArrowDown" || t === "ArrowUp") &&
				e.preventDefault(),
			this.keydownCallback(e),
			t !== "Tab" && this.focus();
	}),
	(xe = new WeakSet()),
	(Qt = function (e, t) {
		e.stopPropagation();
		const s = this.searchText,
			i = t.value.trim();
		if (s.length === 0 && i.length === 0) {
			t.value = "";
			return;
		}
		if (this.searchable) {
			const a = e.target.value;
			this.searchCallback(a), this.isOpened || o(this, T, B).call(this);
		} else t.value = "";
		this.searchText = t.value;
	}),
	(Ae = new WeakSet()),
	(es = function () {
		const e = document.createElement("div");
		return e.classList.add("treeselect-input__operators"), e;
	}),
	(Oe = new WeakSet()),
	(ts = function () {
		const e = document.createElement("span");
		return (
			e.classList.add("treeselect-input__clear"),
			e.setAttribute("tabindex", "-1"),
			I(this.iconElements.clear, e),
			e.addEventListener("mousedown", (t) =>
				o(this, _e, ss).call(this, t)
			),
			e
		);
	}),
	(_e = new WeakSet()),
	(ss = function (e) {
		e.preventDefault(),
			e.stopPropagation(),
			(this.searchText.length || this.value.length) && this.clear(),
			this.focus();
	}),
	(Ne = new WeakSet()),
	(is = function (e) {
		f(this, v, document.createElement("span")),
			n(this, v).classList.add("treeselect-input__arrow");
		const t = e ? this.iconElements.arrowUp : this.iconElements.arrowDown;
		return (
			I(t, n(this, v)),
			n(this, v).addEventListener("mousedown", (s) =>
				o(this, Te, ls).call(this, s)
			),
			n(this, v)
		);
	}),
	(Te = new WeakSet()),
	(ls = function (e) {
		e.stopPropagation(),
			e.preventDefault(),
			this.focus(),
			o(this, T, B).call(this);
	}),
	(K = new WeakSet()),
	(wt = function () {
		this.inputCallback(this.value);
	});
const vt = (l, e, t, s) => {
		ki(e);
		const i = e.filter((a) => !a.disabled && l.some((h) => h === a.id));
		if (t && i.length) {
			i[0].checked = !0;
			return;
		}
		i.forEach((a) => {
			a.checked = !0;
			const h = Vt(a, e, s);
			a.checked = h;
		});
	},
	Vt = ({ id: l, checked: e }, t, s) => {
		const i = t.find((h) => h.id === l);
		if (!i) return !1;
		if (s) return (i.checked = i.disabled ? !1 : !!e), i.checked;
		const a = ns(!!e, i, t);
		return as(i, t), a;
	},
	ns = (l, e, t) => {
		if (!e.isGroup)
			return (
				(e.checked = e.disabled ? !1 : !!l),
				(e.isPartialChecked = !1),
				e.checked
			);
		const s = t.filter((d) => d.childOf === e.id);
		return !l || e.disabled || e.isPartialChecked
			? ((e.checked = !1),
			  (e.isPartialChecked = !1),
			  Lt(e, s, t),
			  e.checked)
			: os(s, t)
			? rs(s)
				? ((e.checked = !1),
				  (e.isPartialChecked = !1),
				  (e.disabled = !0),
				  e.checked)
				: ((e.checked = !1),
				  (e.isPartialChecked = !0),
				  s.forEach((d) => {
						ns(l, d, t);
				  }),
				  e.checked)
			: ((e.checked = !0),
			  (e.isPartialChecked = !1),
			  Lt(e, s, t),
			  e.checked);
	},
	as = (l, e) => {
		const t = e.find((s) => s.id === l.childOf);
		t && (bi(t, e), as(t, e));
	},
	bi = (l, e) => {
		const t = Ct(l, e);
		if (rs(t)) {
			(l.checked = !1), (l.isPartialChecked = !1), (l.disabled = !0);
			return;
		}
		if (gi(t)) {
			(l.checked = !0), (l.isPartialChecked = !1);
			return;
		}
		if (Ei(t)) {
			(l.checked = !1), (l.isPartialChecked = !0);
			return;
		}
		(l.checked = !1), (l.isPartialChecked = !1);
	},
	Lt = ({ checked: l, disabled: e }, t, s) => {
		t.forEach((i) => {
			(i.disabled = !!e || !!i.disabled),
				(i.checked = !!l && !i.disabled),
				(i.isPartialChecked = !1);
			const a = Ct(i, s);
			Lt({ checked: l, disabled: e }, a, s);
		});
	},
	os = (l, e) =>
		l.some((i) => i.disabled)
			? !0
			: l.some((i) => {
					if (i.isGroup) {
						const a = Ct(i, e);
						return os(a, e);
					}
					return !1;
			  }),
	rs = (l) => l.every((e) => !!e.disabled),
	gi = (l) => l.every((e) => !!e.checked),
	Ei = (l) => l.some((e) => !!e.checked || !!e.isPartialChecked),
	ki = (l) => {
		l.forEach((e) => {
			(e.checked = !1), (e.isPartialChecked = !1);
		});
	},
	wi = (l, e, t) => {
		const s = { level: 0, groupId: "" },
			i = cs(l, e, s.groupId, s.level);
		return Li(i, t);
	},
	cs = (l, e, t, s) =>
		l.reduce((i, a) => {
			var m;
			const h = !!((m = a.children) != null && m.length),
				d = s >= e && h,
				C = s > e;
			if (
				(i.push({
					id: a.value,
					name: a.name,
					childOf: t,
					isGroup: h,
					checked: !1,
					isPartialChecked: !1,
					level: s,
					isClosed: d,
					hidden: C,
					disabled: a.disabled ?? !1
				}),
				h)
			) {
				const b = cs(a.children, e, a.value, s + 1);
				i.push(...b);
			}
			return i;
		}, []),
	Ct = ({ id: l }, e) => e.filter((t) => t.childOf === l),
	vi = (l) => {
		const {
				ungroupedNodes: e,
				allGroupedNodes: t,
				allNodes: s
			} = l.reduce(
				(a, h) => (
					h.checked &&
						(a.allNodes.push(h),
						h.isGroup
							? a.allGroupedNodes.push(h)
							: a.ungroupedNodes.push(h)),
					a
				),
				{
					ungroupedNodes: [],
					allGroupedNodes: [],
					allNodes: []
				}
			),
			i = s.filter((a) => !t.some(({ id: h }) => h === a.childOf));
		return { ungroupedNodes: e, groupedNodes: i, allNodes: s };
	},
	Li = (l, e) => (
		l
			.filter((s) => !!s.disabled)
			.forEach(({ id: s }) => Vt({ id: s, checked: !1 }, l, e)),
		l
	),
	yi = {
		"Ⓐ": "A",
		Ａ: "A",
		À: "A",
		Á: "A",
		Â: "A",
		Ầ: "A",
		Ấ: "A",
		Ẫ: "A",
		Ẩ: "A",
		Ã: "A",
		Ā: "A",
		Ă: "A",
		Ằ: "A",
		Ắ: "A",
		Ẵ: "A",
		Ẳ: "A",
		Ȧ: "A",
		Ǡ: "A",
		Ä: "A",
		Ǟ: "A",
		Ả: "A",
		Å: "A",
		Ǻ: "A",
		Ǎ: "A",
		Ȁ: "A",
		Ȃ: "A",
		Ạ: "A",
		Ậ: "A",
		Ặ: "A",
		Ḁ: "A",
		Ą: "A",
		Ⱥ: "A",
		Ɐ: "A",
		Ꜳ: "AA",
		Æ: "AE",
		Ǽ: "AE",
		Ǣ: "AE",
		Ꜵ: "AO",
		Ꜷ: "AU",
		Ꜹ: "AV",
		Ꜻ: "AV",
		Ꜽ: "AY",
		"Ⓑ": "B",
		Ｂ: "B",
		Ḃ: "B",
		Ḅ: "B",
		Ḇ: "B",
		Ƀ: "B",
		Ƃ: "B",
		Ɓ: "B",
		"Ⓒ": "C",
		Ｃ: "C",
		Ć: "C",
		Ĉ: "C",
		Ċ: "C",
		Č: "C",
		Ç: "C",
		Ḉ: "C",
		Ƈ: "C",
		Ȼ: "C",
		Ꜿ: "C",
		"Ⓓ": "D",
		Ｄ: "D",
		Ḋ: "D",
		Ď: "D",
		Ḍ: "D",
		Ḑ: "D",
		Ḓ: "D",
		Ḏ: "D",
		Đ: "D",
		Ƌ: "D",
		Ɗ: "D",
		Ɖ: "D",
		Ꝺ: "D",
		Ǳ: "DZ",
		Ǆ: "DZ",
		ǲ: "Dz",
		ǅ: "Dz",
		"Ⓔ": "E",
		Ｅ: "E",
		È: "E",
		É: "E",
		Ê: "E",
		Ề: "E",
		Ế: "E",
		Ễ: "E",
		Ể: "E",
		Ẽ: "E",
		Ē: "E",
		Ḕ: "E",
		Ḗ: "E",
		Ĕ: "E",
		Ė: "E",
		Ë: "E",
		Ẻ: "E",
		Ě: "E",
		Ȅ: "E",
		Ȇ: "E",
		Ẹ: "E",
		Ệ: "E",
		Ȩ: "E",
		Ḝ: "E",
		Ę: "E",
		Ḙ: "E",
		Ḛ: "E",
		Ɛ: "E",
		Ǝ: "E",
		"Ⓕ": "F",
		Ｆ: "F",
		Ḟ: "F",
		Ƒ: "F",
		Ꝼ: "F",
		"Ⓖ": "G",
		Ｇ: "G",
		Ǵ: "G",
		Ĝ: "G",
		Ḡ: "G",
		Ğ: "G",
		Ġ: "G",
		Ǧ: "G",
		Ģ: "G",
		Ǥ: "G",
		Ɠ: "G",
		Ꞡ: "G",
		Ᵹ: "G",
		Ꝿ: "G",
		"Ⓗ": "H",
		Ｈ: "H",
		Ĥ: "H",
		Ḣ: "H",
		Ḧ: "H",
		Ȟ: "H",
		Ḥ: "H",
		Ḩ: "H",
		Ḫ: "H",
		Ħ: "H",
		Ⱨ: "H",
		Ⱶ: "H",
		Ɥ: "H",
		"Ⓘ": "I",
		Ｉ: "I",
		Ì: "I",
		Í: "I",
		Î: "I",
		Ĩ: "I",
		Ī: "I",
		Ĭ: "I",
		İ: "I",
		Ï: "I",
		Ḯ: "I",
		Ỉ: "I",
		Ǐ: "I",
		Ȉ: "I",
		Ȋ: "I",
		Ị: "I",
		Į: "I",
		Ḭ: "I",
		Ɨ: "I",
		"Ⓙ": "J",
		Ｊ: "J",
		Ĵ: "J",
		Ɉ: "J",
		"Ⓚ": "K",
		Ｋ: "K",
		Ḱ: "K",
		Ǩ: "K",
		Ḳ: "K",
		Ķ: "K",
		Ḵ: "K",
		Ƙ: "K",
		Ⱪ: "K",
		Ꝁ: "K",
		Ꝃ: "K",
		Ꝅ: "K",
		Ꞣ: "K",
		"Ⓛ": "L",
		Ｌ: "L",
		Ŀ: "L",
		Ĺ: "L",
		Ľ: "L",
		Ḷ: "L",
		Ḹ: "L",
		Ļ: "L",
		Ḽ: "L",
		Ḻ: "L",
		Ł: "L",
		Ƚ: "L",
		Ɫ: "L",
		Ⱡ: "L",
		Ꝉ: "L",
		Ꝇ: "L",
		Ꞁ: "L",
		Ǉ: "LJ",
		ǈ: "Lj",
		"Ⓜ": "M",
		Ｍ: "M",
		Ḿ: "M",
		Ṁ: "M",
		Ṃ: "M",
		Ɱ: "M",
		Ɯ: "M",
		"Ⓝ": "N",
		Ｎ: "N",
		Ǹ: "N",
		Ń: "N",
		Ñ: "N",
		Ṅ: "N",
		Ň: "N",
		Ṇ: "N",
		Ņ: "N",
		Ṋ: "N",
		Ṉ: "N",
		Ƞ: "N",
		Ɲ: "N",
		Ꞑ: "N",
		Ꞥ: "N",
		Ǌ: "NJ",
		ǋ: "Nj",
		"Ⓞ": "O",
		Ｏ: "O",
		Ò: "O",
		Ó: "O",
		Ô: "O",
		Ồ: "O",
		Ố: "O",
		Ỗ: "O",
		Ổ: "O",
		Õ: "O",
		Ṍ: "O",
		Ȭ: "O",
		Ṏ: "O",
		Ō: "O",
		Ṑ: "O",
		Ṓ: "O",
		Ŏ: "O",
		Ȯ: "O",
		Ȱ: "O",
		Ö: "O",
		Ȫ: "O",
		Ỏ: "O",
		Ő: "O",
		Ǒ: "O",
		Ȍ: "O",
		Ȏ: "O",
		Ơ: "O",
		Ờ: "O",
		Ớ: "O",
		Ỡ: "O",
		Ở: "O",
		Ợ: "O",
		Ọ: "O",
		Ộ: "O",
		Ǫ: "O",
		Ǭ: "O",
		Ø: "O",
		Ǿ: "O",
		Ɔ: "O",
		Ɵ: "O",
		Ꝋ: "O",
		Ꝍ: "O",
		Œ: "OE",
		Ƣ: "OI",
		Ꝏ: "OO",
		Ȣ: "OU",
		"Ⓟ": "P",
		Ｐ: "P",
		Ṕ: "P",
		Ṗ: "P",
		Ƥ: "P",
		Ᵽ: "P",
		Ꝑ: "P",
		Ꝓ: "P",
		Ꝕ: "P",
		"Ⓠ": "Q",
		Ｑ: "Q",
		Ꝗ: "Q",
		Ꝙ: "Q",
		Ɋ: "Q",
		"Ⓡ": "R",
		Ｒ: "R",
		Ŕ: "R",
		Ṙ: "R",
		Ř: "R",
		Ȑ: "R",
		Ȓ: "R",
		Ṛ: "R",
		Ṝ: "R",
		Ŗ: "R",
		Ṟ: "R",
		Ɍ: "R",
		Ɽ: "R",
		Ꝛ: "R",
		Ꞧ: "R",
		Ꞃ: "R",
		"Ⓢ": "S",
		Ｓ: "S",
		ẞ: "S",
		Ś: "S",
		Ṥ: "S",
		Ŝ: "S",
		Ṡ: "S",
		Š: "S",
		Ṧ: "S",
		Ṣ: "S",
		Ṩ: "S",
		Ș: "S",
		Ş: "S",
		Ȿ: "S",
		Ꞩ: "S",
		Ꞅ: "S",
		"Ⓣ": "T",
		Ｔ: "T",
		Ṫ: "T",
		Ť: "T",
		Ṭ: "T",
		Ț: "T",
		Ţ: "T",
		Ṱ: "T",
		Ṯ: "T",
		Ŧ: "T",
		Ƭ: "T",
		Ʈ: "T",
		Ⱦ: "T",
		Ꞇ: "T",
		Ꜩ: "TZ",
		"Ⓤ": "U",
		Ｕ: "U",
		Ù: "U",
		Ú: "U",
		Û: "U",
		Ũ: "U",
		Ṹ: "U",
		Ū: "U",
		Ṻ: "U",
		Ŭ: "U",
		Ü: "U",
		Ǜ: "U",
		Ǘ: "U",
		Ǖ: "U",
		Ǚ: "U",
		Ủ: "U",
		Ů: "U",
		Ű: "U",
		Ǔ: "U",
		Ȕ: "U",
		Ȗ: "U",
		Ư: "U",
		Ừ: "U",
		Ứ: "U",
		Ữ: "U",
		Ử: "U",
		Ự: "U",
		Ụ: "U",
		Ṳ: "U",
		Ų: "U",
		Ṷ: "U",
		Ṵ: "U",
		Ʉ: "U",
		"Ⓥ": "V",
		Ｖ: "V",
		Ṽ: "V",
		Ṿ: "V",
		Ʋ: "V",
		Ꝟ: "V",
		Ʌ: "V",
		Ꝡ: "VY",
		"Ⓦ": "W",
		Ｗ: "W",
		Ẁ: "W",
		Ẃ: "W",
		Ŵ: "W",
		Ẇ: "W",
		Ẅ: "W",
		Ẉ: "W",
		Ⱳ: "W",
		"Ⓧ": "X",
		Ｘ: "X",
		Ẋ: "X",
		Ẍ: "X",
		"Ⓨ": "Y",
		Ｙ: "Y",
		Ỳ: "Y",
		Ý: "Y",
		Ŷ: "Y",
		Ỹ: "Y",
		Ȳ: "Y",
		Ẏ: "Y",
		Ÿ: "Y",
		Ỷ: "Y",
		Ỵ: "Y",
		Ƴ: "Y",
		Ɏ: "Y",
		Ỿ: "Y",
		"Ⓩ": "Z",
		Ｚ: "Z",
		Ź: "Z",
		Ẑ: "Z",
		Ż: "Z",
		Ž: "Z",
		Ẓ: "Z",
		Ẕ: "Z",
		Ƶ: "Z",
		Ȥ: "Z",
		Ɀ: "Z",
		Ⱬ: "Z",
		Ꝣ: "Z",
		"ⓐ": "a",
		ａ: "a",
		ẚ: "a",
		à: "a",
		á: "a",
		â: "a",
		ầ: "a",
		ấ: "a",
		ẫ: "a",
		ẩ: "a",
		ã: "a",
		ā: "a",
		ă: "a",
		ằ: "a",
		ắ: "a",
		ẵ: "a",
		ẳ: "a",
		ȧ: "a",
		ǡ: "a",
		ä: "a",
		ǟ: "a",
		ả: "a",
		å: "a",
		ǻ: "a",
		ǎ: "a",
		ȁ: "a",
		ȃ: "a",
		ạ: "a",
		ậ: "a",
		ặ: "a",
		ḁ: "a",
		ą: "a",
		ⱥ: "a",
		ɐ: "a",
		ꜳ: "aa",
		æ: "ae",
		ǽ: "ae",
		ǣ: "ae",
		ꜵ: "ao",
		ꜷ: "au",
		ꜹ: "av",
		ꜻ: "av",
		ꜽ: "ay",
		"ⓑ": "b",
		ｂ: "b",
		ḃ: "b",
		ḅ: "b",
		ḇ: "b",
		ƀ: "b",
		ƃ: "b",
		ɓ: "b",
		"ⓒ": "c",
		ｃ: "c",
		ć: "c",
		ĉ: "c",
		ċ: "c",
		č: "c",
		ç: "c",
		ḉ: "c",
		ƈ: "c",
		ȼ: "c",
		ꜿ: "c",
		ↄ: "c",
		"ⓓ": "d",
		ｄ: "d",
		ḋ: "d",
		ď: "d",
		ḍ: "d",
		ḑ: "d",
		ḓ: "d",
		ḏ: "d",
		đ: "d",
		ƌ: "d",
		ɖ: "d",
		ɗ: "d",
		ꝺ: "d",
		ǳ: "dz",
		ǆ: "dz",
		"ⓔ": "e",
		ｅ: "e",
		è: "e",
		é: "e",
		ê: "e",
		ề: "e",
		ế: "e",
		ễ: "e",
		ể: "e",
		ẽ: "e",
		ē: "e",
		ḕ: "e",
		ḗ: "e",
		ĕ: "e",
		ė: "e",
		ë: "e",
		ẻ: "e",
		ě: "e",
		ȅ: "e",
		ȇ: "e",
		ẹ: "e",
		ệ: "e",
		ȩ: "e",
		ḝ: "e",
		ę: "e",
		ḙ: "e",
		ḛ: "e",
		ɇ: "e",
		ɛ: "e",
		ǝ: "e",
		"ⓕ": "f",
		ｆ: "f",
		ḟ: "f",
		ƒ: "f",
		ꝼ: "f",
		"ⓖ": "g",
		ｇ: "g",
		ǵ: "g",
		ĝ: "g",
		ḡ: "g",
		ğ: "g",
		ġ: "g",
		ǧ: "g",
		ģ: "g",
		ǥ: "g",
		ɠ: "g",
		ꞡ: "g",
		ᵹ: "g",
		ꝿ: "g",
		"ⓗ": "h",
		ｈ: "h",
		ĥ: "h",
		ḣ: "h",
		ḧ: "h",
		ȟ: "h",
		ḥ: "h",
		ḩ: "h",
		ḫ: "h",
		ẖ: "h",
		ħ: "h",
		ⱨ: "h",
		ⱶ: "h",
		ɥ: "h",
		ƕ: "hv",
		"ⓘ": "i",
		ｉ: "i",
		ì: "i",
		í: "i",
		î: "i",
		ĩ: "i",
		ī: "i",
		ĭ: "i",
		ï: "i",
		ḯ: "i",
		ỉ: "i",
		ǐ: "i",
		ȉ: "i",
		ȋ: "i",
		ị: "i",
		į: "i",
		ḭ: "i",
		ɨ: "i",
		ı: "i",
		"ⓙ": "j",
		ｊ: "j",
		ĵ: "j",
		ǰ: "j",
		ɉ: "j",
		"ⓚ": "k",
		ｋ: "k",
		ḱ: "k",
		ǩ: "k",
		ḳ: "k",
		ķ: "k",
		ḵ: "k",
		ƙ: "k",
		ⱪ: "k",
		ꝁ: "k",
		ꝃ: "k",
		ꝅ: "k",
		ꞣ: "k",
		"ⓛ": "l",
		ｌ: "l",
		ŀ: "l",
		ĺ: "l",
		ľ: "l",
		ḷ: "l",
		ḹ: "l",
		ļ: "l",
		ḽ: "l",
		ḻ: "l",
		ſ: "l",
		ł: "l",
		ƚ: "l",
		ɫ: "l",
		ⱡ: "l",
		ꝉ: "l",
		ꞁ: "l",
		ꝇ: "l",
		ǉ: "lj",
		"ⓜ": "m",
		ｍ: "m",
		ḿ: "m",
		ṁ: "m",
		ṃ: "m",
		ɱ: "m",
		ɯ: "m",
		"ⓝ": "n",
		ｎ: "n",
		ǹ: "n",
		ń: "n",
		ñ: "n",
		ṅ: "n",
		ň: "n",
		ṇ: "n",
		ņ: "n",
		ṋ: "n",
		ṉ: "n",
		ƞ: "n",
		ɲ: "n",
		ŉ: "n",
		ꞑ: "n",
		ꞥ: "n",
		ǌ: "nj",
		"ⓞ": "o",
		ｏ: "o",
		ò: "o",
		ó: "o",
		ô: "o",
		ồ: "o",
		ố: "o",
		ỗ: "o",
		ổ: "o",
		õ: "o",
		ṍ: "o",
		ȭ: "o",
		ṏ: "o",
		ō: "o",
		ṑ: "o",
		ṓ: "o",
		ŏ: "o",
		ȯ: "o",
		ȱ: "o",
		ö: "o",
		ȫ: "o",
		ỏ: "o",
		ő: "o",
		ǒ: "o",
		ȍ: "o",
		ȏ: "o",
		ơ: "o",
		ờ: "o",
		ớ: "o",
		ỡ: "o",
		ở: "o",
		ợ: "o",
		ọ: "o",
		ộ: "o",
		ǫ: "o",
		ǭ: "o",
		ø: "o",
		ǿ: "o",
		ɔ: "o",
		ꝋ: "o",
		ꝍ: "o",
		ɵ: "o",
		œ: "oe",
		ƣ: "oi",
		ȣ: "ou",
		ꝏ: "oo",
		"ⓟ": "p",
		ｐ: "p",
		ṕ: "p",
		ṗ: "p",
		ƥ: "p",
		ᵽ: "p",
		ꝑ: "p",
		ꝓ: "p",
		ꝕ: "p",
		"ⓠ": "q",
		ｑ: "q",
		ɋ: "q",
		ꝗ: "q",
		ꝙ: "q",
		"ⓡ": "r",
		ｒ: "r",
		ŕ: "r",
		ṙ: "r",
		ř: "r",
		ȑ: "r",
		ȓ: "r",
		ṛ: "r",
		ṝ: "r",
		ŗ: "r",
		ṟ: "r",
		ɍ: "r",
		ɽ: "r",
		ꝛ: "r",
		ꞧ: "r",
		ꞃ: "r",
		"ⓢ": "s",
		ｓ: "s",
		ß: "s",
		ś: "s",
		ṥ: "s",
		ŝ: "s",
		ṡ: "s",
		š: "s",
		ṧ: "s",
		ṣ: "s",
		ṩ: "s",
		ș: "s",
		ş: "s",
		ȿ: "s",
		ꞩ: "s",
		ꞅ: "s",
		ẛ: "s",
		"ⓣ": "t",
		ｔ: "t",
		ṫ: "t",
		ẗ: "t",
		ť: "t",
		ṭ: "t",
		ț: "t",
		ţ: "t",
		ṱ: "t",
		ṯ: "t",
		ŧ: "t",
		ƭ: "t",
		ʈ: "t",
		ⱦ: "t",
		ꞇ: "t",
		ꜩ: "tz",
		"ⓤ": "u",
		ｕ: "u",
		ù: "u",
		ú: "u",
		û: "u",
		ũ: "u",
		ṹ: "u",
		ū: "u",
		ṻ: "u",
		ŭ: "u",
		ü: "u",
		ǜ: "u",
		ǘ: "u",
		ǖ: "u",
		ǚ: "u",
		ủ: "u",
		ů: "u",
		ű: "u",
		ǔ: "u",
		ȕ: "u",
		ȗ: "u",
		ư: "u",
		ừ: "u",
		ứ: "u",
		ữ: "u",
		ử: "u",
		ự: "u",
		ụ: "u",
		ṳ: "u",
		ų: "u",
		ṷ: "u",
		ṵ: "u",
		ʉ: "u",
		"ⓥ": "v",
		ｖ: "v",
		ṽ: "v",
		ṿ: "v",
		ʋ: "v",
		ꝟ: "v",
		ʌ: "v",
		ꝡ: "vy",
		"ⓦ": "w",
		ｗ: "w",
		ẁ: "w",
		ẃ: "w",
		ŵ: "w",
		ẇ: "w",
		ẅ: "w",
		ẘ: "w",
		ẉ: "w",
		ⱳ: "w",
		"ⓧ": "x",
		ｘ: "x",
		ẋ: "x",
		ẍ: "x",
		"ⓨ": "y",
		ｙ: "y",
		ỳ: "y",
		ý: "y",
		ŷ: "y",
		ỹ: "y",
		ȳ: "y",
		ẏ: "y",
		ÿ: "y",
		ỷ: "y",
		ẙ: "y",
		ỵ: "y",
		ƴ: "y",
		ɏ: "y",
		ỿ: "y",
		"ⓩ": "z",
		ｚ: "z",
		ź: "z",
		ẑ: "z",
		ż: "z",
		ž: "z",
		ẓ: "z",
		ẕ: "z",
		ƶ: "z",
		ȥ: "z",
		ɀ: "z",
		ⱬ: "z",
		ꝣ: "z",
		Ά: "Α",
		Έ: "Ε",
		Ή: "Η",
		Ί: "Ι",
		Ϊ: "Ι",
		Ό: "Ο",
		Ύ: "Υ",
		Ϋ: "Υ",
		Ώ: "Ω",
		ά: "α",
		έ: "ε",
		ή: "η",
		ί: "ι",
		ϊ: "ι",
		ΐ: "ι",
		ό: "ο",
		ύ: "υ",
		ϋ: "υ",
		ΰ: "υ",
		ώ: "ω",
		ς: "σ",
		"’": "'"
	},
	Si = (l) =>
		l
			.split("")
			.map((e) => yi[e] || e)
			.join(""),
	yt = (l) =>
		Si(l).toLowerCase().replace(/[\W_]/gim, " ").replace(/\s+/g, " "),
	bt = (l, { id: e, isClosed: t }) => {
		Ct({ id: e }, l).forEach((i) => {
			(i.hidden = t ?? !1),
				i.isGroup && !i.isClosed && bt(l, { id: i.id, isClosed: t });
		});
	},
	xi = (l) => {
		l.filter(
			(e) => e.isGroup && !e.disabled && (e.checked || e.isPartialChecked)
		).forEach((e) => {
			(e.isClosed = !1), bt(l, e);
		});
	},
	Ai = (l, e) => {
		const t = Oi(l, e);
		l.forEach((s) => {
			t.some(({ id: a }) => a === s.id)
				? (s.isGroup && ((s.isClosed = !1), bt(l, s)), (s.hidden = !1))
				: (s.hidden = !0);
		});
	},
	Oi = (l, e) => {
		const t = yt(e);
		return l.reduce((s, i) => {
			if (yt(i.name).includes(t)) {
				if ((s.push(i), i.isGroup)) {
					const h = hs(i.id, l);
					s.push(...h);
				}
				if (i.childOf) {
					const h = ds(i.childOf, l);
					s.push(...h);
				}
			}
			return s;
		}, []);
	},
	hs = (l, e) =>
		e.reduce(
			(t, s) => (
				s.childOf === l &&
					(t.push(s), s.isGroup && t.push(...hs(s.id, e))),
				t
			),
			[]
		),
	ds = (l, e) =>
		e.reduce(
			(t, s) => (
				s.id === l &&
					(t.push(s), s.childOf && t.push(...ds(s.childOf, e))),
				t
			),
			[]
		),
	_i = (l) => {
		const { duplications: e } = l.reduce(
			(t, s) => (
				t.allItems.some((i) => i.toString() === s.id.toString()) &&
					t.duplications.push(s.id),
				t.allItems.push(s.id),
				t
			),
			{
				duplications: [],
				allItems: []
			}
		);
		e.length &&
			console.error(
				`Validation: You have duplicated values: ${e.join(
					", "
				)}! You should use unique values.`
			);
	},
	Ni = (l, e, t, s, i, a, h, d, C, m, b, g) => {
		vt(l, e, i, C), d && h && xi(e), he(e, t, s, a, m, b, g);
	},
	he = (l, e, t, s, i, a, h) => {
		l.forEach((d) => {
			const C = e.querySelector(`[input-id="${d.id}"]`),
				m = N(C);
			(C.checked = d.checked),
				Ti(d, m, s),
				Ii(d, m),
				Pi(d, m),
				Di(d, m, t),
				Vi(d, m),
				Hi(d, m, l, i, a, h),
				Bi(d, C, t);
		}),
			Gi(l, e);
	},
	Ti = (l, e, t) => {
		l.checked
			? e.classList.add("treeselect-list__item--checked")
			: e.classList.remove("treeselect-list__item--checked"),
			Array.isArray(t) && t[0] === l.id && !l.disabled
				? e.classList.add("treeselect-list__item--single-selected")
				: e.classList.remove("treeselect-list__item--single-selected");
	},
	Ii = (l, e) => {
		l.isPartialChecked
			? e.classList.add("treeselect-list__item--partial-checked")
			: e.classList.remove("treeselect-list__item--partial-checked");
	},
	Pi = (l, e) => {
		l.disabled
			? e.classList.add("treeselect-list__item--disabled")
			: e.classList.remove("treeselect-list__item--disabled");
	},
	Di = (l, e, t) => {
		if (l.isGroup) {
			const s = e.querySelector(".treeselect-list__item-icon"),
				i = l.isClosed ? t.arrowRight : t.arrowDown;
			I(i, s),
				l.isClosed
					? e.classList.add("treeselect-list__item--closed")
					: e.classList.remove("treeselect-list__item--closed");
		}
	},
	Vi = (l, e) => {
		l.hidden
			? e.classList.add("treeselect-list__item--hidden")
			: e.classList.remove("treeselect-list__item--hidden");
	},
	Bi = (l, e, t) => {
		const i = e.parentNode.querySelector(
			".treeselect-list__item-checkbox-icon"
		);
		l.checked
			? I(t.check, i)
			: l.isPartialChecked
			? I(t.partialCheck, i)
			: (i.innerHTML = "");
	},
	Hi = (l, e, t, s, i, a) => {
		if (l.level === 0) {
			const d = t.some((b) => b.isGroup && b.level === l.level),
				C = !l.isGroup && d ? i : a,
				m = (l.isGroup ? a : C) + "px";
			s ? (e.style.paddingRight = m) : (e.style.paddingLeft = m);
		} else {
			const d = l.isGroup
				? `${a + l.level * i}px`
				: `${a + l.level * i + i}px`;
			s ? (e.style.paddingRight = d) : (e.style.paddingLeft = d);
		}
		e.setAttribute("level", l.level.toString()),
			e.setAttribute("group", l.isGroup.toString());
	},
	Gi = (l, e) => {
		const t = l.some((i) => !i.hidden),
			s = e.querySelector(".treeselect-list__empty");
		t
			? s.classList.add("treeselect-list__empty--hidden")
			: s.classList.remove("treeselect-list__empty--hidden");
	},
	N = (l) => l.parentNode.parentNode,
	Gt = (l, e) => e.find((t) => t.id.toString() === l),
	Ui = (l) => N(l).querySelector(".treeselect-list__item-icon"),
	Mi = (l, e) => {
		e &&
			Object.keys(e).forEach((t) => {
				const s = e[t];
				typeof s == "string" && l.setAttribute(t, s);
			});
	};
var M,
	P,
	L,
	$,
	Ie,
	us,
	Pe,
	ps,
	De,
	ms,
	Ve,
	fs,
	Be,
	Cs,
	He,
	bs,
	J,
	St,
	Ge,
	gs,
	Ue,
	Es,
	Me,
	ks,
	X,
	xt,
	ze,
	ws,
	Re,
	vs,
	Fe,
	Ls,
	je,
	ys,
	We,
	Ss,
	Ye,
	xs,
	qe,
	As,
	Ze,
	Os,
	Ke,
	_s,
	$e,
	Ns,
	Je,
	Ts,
	Q,
	At,
	ee,
	Ot,
	Xe,
	Is;
class zi {
	constructor({
		options: e,
		value: t,
		openLevel: s,
		listSlotHtmlComponent: i,
		emptyText: a,
		isSingleSelect: h,
		iconElements: d,
		unselectOnClickSingleSelected: C,
		showCount: m,
		disabledBranchNode: b,
		expandSelected: g,
		isIndependentNodes: k,
		rtl: w,
		defaultPadding: S,
		zeroLevelItemPadding: x,
		inputCallback: V,
		arrowClickCallback: z,
		mouseupCallback: Y
	}) {
		// Private methods
		c(this, Ie);
		c(this, Pe);
		c(this, De);
		c(this, Ve);
		c(this, Be);
		c(this, He);
		c(this, J);
		c(this, Ge);
		c(this, Ue);
		c(this, Me);
		c(this, X);
		c(this, ze);
		c(this, Re);
		c(this, Fe);
		c(this, je);
		c(this, We);
		c(this, Ye);
		c(this, qe);
		c(this, Ze);
		c(this, Ke);
		// Actions
		c(this, $e);
		c(this, Je);
		c(this, Q);
		c(this, ee);
		// Emits
		c(this, Xe);
		// Props
		r(this, "options");
		r(this, "value");
		r(this, "openLevel");
		r(this, "listSlotHtmlComponent");
		r(this, "emptyText");
		r(this, "isSingleSelect");
		r(this, "showCount");
		r(this, "disabledBranchNode");
		r(this, "expandSelected");
		r(this, "isIndependentNodes");
		r(this, "rtl");
		r(this, "defaultPadding");
		r(this, "zeroLevelItemPadding");
		r(this, "iconElements");
		r(this, "unselectOnClickSingleSelected");
		// InnerState
		r(this, "searchText");
		r(this, "flattedOptions");
		r(this, "flattedOptionsBeforeSearch");
		r(this, "selectedNodes");
		r(this, "srcElement");
		// Callbacks
		r(this, "inputCallback");
		r(this, "arrowClickCallback");
		r(this, "mouseupCallback");
		// PrivateInnerState
		c(this, M, null);
		c(this, P, !0);
		c(this, L, []);
		c(this, $, !0);
		(this.options = e),
			(this.value = t),
			(this.openLevel = s ?? 0),
			(this.listSlotHtmlComponent = i ?? null),
			(this.emptyText = a ?? "No results found..."),
			(this.isSingleSelect = h ?? !1),
			(this.showCount = m ?? !1),
			(this.disabledBranchNode = b ?? !1),
			(this.expandSelected = g ?? !1),
			(this.isIndependentNodes = k ?? !1),
			(this.rtl = w ?? !1),
			(this.defaultPadding = S ?? 20),
			(this.zeroLevelItemPadding = x ?? 5),
			(this.iconElements = d),
			(this.unselectOnClickSingleSelected = C ?? !1),
			(this.searchText = ""),
			(this.flattedOptions = wi(
				this.options,
				this.openLevel,
				this.isIndependentNodes
			)),
			(this.flattedOptionsBeforeSearch = this.flattedOptions),
			(this.selectedNodes = {
				nodes: [],
				groupedNodes: [],
				allNodes: []
			}),
			(this.srcElement = o(this, De, ms).call(this)),
			(this.inputCallback = V),
			(this.arrowClickCallback = z),
			(this.mouseupCallback = Y),
			_i(this.flattedOptions);
	}
	// Public methods
	updateValue(e) {
		(this.value = e),
			f(this, L, this.isSingleSelect ? this.value : []),
			Ni(
				e,
				this.flattedOptions,
				this.srcElement,
				this.iconElements,
				this.isSingleSelect,
				n(this, L),
				this.expandSelected,
				n(this, $),
				this.isIndependentNodes,
				this.rtl,
				this.defaultPadding,
				this.zeroLevelItemPadding
			),
			f(this, $, !1),
			o(this, ee, Ot).call(this);
	}
	updateSearchValue(e) {
		if (e === this.searchText) return;
		const t = this.searchText === "" && e !== "";
		(this.searchText = yt(e)),
			t &&
				(this.flattedOptionsBeforeSearch = JSON.parse(
					JSON.stringify(this.flattedOptions)
				)),
			this.searchText === "" &&
				((this.flattedOptions = this.flattedOptionsBeforeSearch.map(
					(s) => {
						const i = this.flattedOptions.find(
							(a) => a.id === s.id
						);
						return (
							(i.isClosed = s.isClosed), (i.hidden = s.hidden), i
						);
					}
				)),
				(this.flattedOptionsBeforeSearch = [])),
			this.searchText && Ai(this.flattedOptions, e),
			he(
				this.flattedOptions,
				this.srcElement,
				this.iconElements,
				n(this, L),
				this.rtl,
				this.defaultPadding,
				this.zeroLevelItemPadding
			),
			this.focusFirstListElement();
	}
	callKeyAction(e) {
		f(this, P, !1);
		const t = this.srcElement.querySelector(
			".treeselect-list__item--focused"
		);
		if (
			t == null
				? void 0
				: t.classList.contains("treeselect-list__item--hidden")
		)
			return;
		const i = e.key;
		i === "Enter" && t && t.dispatchEvent(new Event("mousedown")),
			(i === "ArrowLeft" || i === "ArrowRight") &&
				o(this, Ie, us).call(this, t, e),
			(i === "ArrowDown" || i === "ArrowUp") &&
				o(this, Pe, ps).call(this, t, i);
	}
	focusFirstListElement() {
		const e = "treeselect-list__item--focused",
			t = this.srcElement.querySelector(`.${e}`),
			s = Array.from(
				this.srcElement.querySelectorAll(
					".treeselect-list__item-checkbox"
				)
			).filter((a) => window.getComputedStyle(N(a)).display !== "none");
		if (!s.length) return;
		t && t.classList.remove(e), N(s[0]).classList.add(e);
	}
	isLastFocusedElementExist() {
		return !!n(this, M);
	}
}
(M = new WeakMap()),
	(P = new WeakMap()),
	(L = new WeakMap()),
	($ = new WeakMap()),
	(Ie = new WeakSet()),
	(us = function (e, t) {
		if (!e) return;
		const s = t.key,
			a = e
				.querySelector(".treeselect-list__item-checkbox")
				.getAttribute("input-id"),
			h = Gt(a, this.flattedOptions),
			d = e.querySelector(".treeselect-list__item-icon");
		s === "ArrowLeft" &&
			!h.isClosed &&
			h.isGroup &&
			(d.dispatchEvent(new Event("mousedown")), t.preventDefault()),
			s === "ArrowRight" &&
				h.isClosed &&
				h.isGroup &&
				(d.dispatchEvent(new Event("mousedown")), t.preventDefault());
	}),
	(Pe = new WeakSet()),
	(ps = function (e, t) {
		var i;
		const s = Array.from(
			this.srcElement.querySelectorAll(".treeselect-list__item-checkbox")
		).filter((a) => window.getComputedStyle(N(a)).display !== "none");
		if (s.length)
			if (!e) N(s[0]).classList.add("treeselect-list__item--focused");
			else {
				const a = s.findIndex((x) =>
					N(x).classList.contains("treeselect-list__item--focused")
				);
				N(s[a]).classList.remove("treeselect-list__item--focused");
				const d = t === "ArrowDown" ? a + 1 : a - 1,
					C = t === "ArrowDown" ? 0 : s.length - 1,
					m = s[d] ?? s[C],
					b = !s[d],
					g = N(m);
				g.classList.add("treeselect-list__item--focused");
				const k = this.srcElement.getBoundingClientRect(),
					w = g.getBoundingClientRect();
				if (b && t === "ArrowDown") {
					this.srcElement.scroll(0, 0);
					return;
				}
				if (b && t === "ArrowUp") {
					this.srcElement.scroll(0, this.srcElement.scrollHeight);
					return;
				}
				const S =
					((i = this.listSlotHtmlComponent) == null
						? void 0
						: i.clientHeight) ?? 0;
				if (k.y + k.height < w.y + w.height + S) {
					this.srcElement.scroll(
						0,
						this.srcElement.scrollTop + w.height
					);
					return;
				}
				if (k.y > w.y) {
					this.srcElement.scroll(
						0,
						this.srcElement.scrollTop - w.height
					);
					return;
				}
			}
	}),
	(De = new WeakSet()),
	(ms = function () {
		const e = o(this, Ve, fs).call(this),
			t = o(this, J, St).call(this, this.options);
		e.append(...t);
		const s = o(this, Ue, Es).call(this);
		e.append(s);
		const i = o(this, Ge, gs).call(this);
		return i && e.append(i), e;
	}),
	(Ve = new WeakSet()),
	(fs = function () {
		const e = document.createElement("div");
		return (
			e.classList.add("treeselect-list"),
			this.isSingleSelect &&
				e.classList.add("treeselect-list--single-select"),
			this.disabledBranchNode &&
				e.classList.add("treeselect-list--disabled-branch-node"),
			e.addEventListener("mouseout", (t) =>
				o(this, Be, Cs).call(this, t)
			),
			e.addEventListener("mousemove", () => o(this, He, bs).call(this)),
			e.addEventListener("mouseup", () => this.mouseupCallback(), !0),
			e
		);
	}),
	(Be = new WeakSet()),
	(Cs = function (e) {
		e.stopPropagation(),
			n(this, M) &&
				n(this, P) &&
				n(this, M).classList.add("treeselect-list__item--focused");
	}),
	(He = new WeakSet()),
	(bs = function () {
		f(this, P, !0);
	}),
	(J = new WeakSet()),
	(St = function (e) {
		return e.reduce((t, s) => {
			var a;
			if ((a = s.children) != null && a.length) {
				const h = o(this, Me, ks).call(this, s),
					d = o(this, J, St).call(this, s.children);
				return h.append(...d), t.push(h), t;
			}
			const i = o(this, X, xt).call(this, s, !1);
			return t.push(i), t;
		}, []);
	}),
	(Ge = new WeakSet()),
	(gs = function () {
		if (!this.listSlotHtmlComponent) return null;
		const e = document.createElement("div");
		return (
			e.classList.add("treeselect-list__slot"),
			e.appendChild(this.listSlotHtmlComponent),
			e
		);
	}),
	(Ue = new WeakSet()),
	(Es = function () {
		const e = document.createElement("div");
		e.classList.add("treeselect-list__empty"),
			e.setAttribute("title", this.emptyText);
		const t = document.createElement("span");
		t.classList.add("treeselect-list__empty-icon"),
			I(this.iconElements.attention, t);
		const s = document.createElement("span");
		return (
			s.classList.add("treeselect-list__empty-text"),
			(s.textContent = this.emptyText),
			e.append(t, s),
			e
		);
	}),
	(Me = new WeakSet()),
	(ks = function (e) {
		const t = document.createElement("div");
		t.setAttribute("group-container-id", e.value.toString()),
			t.classList.add("treeselect-list__group-container");
		const s = o(this, X, xt).call(this, e, !0);
		return t.appendChild(s), t;
	}),
	(X = new WeakSet()),
	(xt = function (e, t) {
		const s = o(this, ze, ws).call(this, e);
		if (t) {
			const h = o(this, We, Ss).call(this);
			s.appendChild(h), s.classList.add("treeselect-list__item--group");
		}
		const i = o(this, qe, As).call(this, e),
			a = o(this, Ze, Os).call(this, e, t);
		return s.append(i, a), s;
	}),
	(ze = new WeakSet()),
	(ws = function (e) {
		const t = document.createElement("div");
		return (
			Mi(t, e.htmlAttr),
			t.setAttribute("tabindex", "-1"),
			t.setAttribute("title", e.name),
			t.classList.add("treeselect-list__item"),
			t.addEventListener(
				"mouseover",
				() => o(this, Re, vs).call(this, t),
				!0
			),
			t.addEventListener(
				"mouseout",
				() => o(this, Fe, Ls).call(this, t),
				!0
			),
			t.addEventListener("mousedown", (s) =>
				o(this, je, ys).call(this, s, e)
			),
			t
		);
	}),
	(Re = new WeakSet()),
	(vs = function (e) {
		n(this, P) && o(this, Q, At).call(this, !0, e);
	}),
	(Fe = new WeakSet()),
	(Ls = function (e) {
		n(this, P) && (o(this, Q, At).call(this, !1, e), f(this, M, e));
	}),
	(je = new WeakSet()),
	(ys = function (e, t) {
		var a;
		if (
			(e.preventDefault(),
			e.stopPropagation(),
			(a = this.flattedOptions.find((h) => h.id === t.value)) == null
				? void 0
				: a.disabled)
		)
			return;
		const i = e.target.querySelector(".treeselect-list__item-checkbox");
		(i.checked = !i.checked), o(this, $e, Ns).call(this, i, t);
	}),
	(We = new WeakSet()),
	(Ss = function () {
		const e = document.createElement("span");
		return (
			e.setAttribute("tabindex", "-1"),
			e.classList.add("treeselect-list__item-icon"),
			I(this.iconElements.arrowDown, e),
			e.addEventListener("mousedown", (t) =>
				o(this, Ye, xs).call(this, t)
			),
			e
		);
	}),
	(Ye = new WeakSet()),
	(xs = function (e) {
		e.preventDefault(), e.stopPropagation(), o(this, Je, Ts).call(this, e);
	}),
	(qe = new WeakSet()),
	(As = function (e) {
		const t = document.createElement("div");
		t.classList.add("treeselect-list__item-checkbox-container");
		const s = document.createElement("span");
		s.classList.add("treeselect-list__item-checkbox-icon"),
			(s.innerHTML = "");
		const i = document.createElement("input");
		return (
			i.setAttribute("tabindex", "-1"),
			i.setAttribute("type", "checkbox"),
			i.setAttribute("input-id", e.value.toString()),
			i.classList.add("treeselect-list__item-checkbox"),
			t.append(s, i),
			t
		);
	}),
	(Ze = new WeakSet()),
	(Os = function (e, t) {
		const s = document.createElement("label");
		if (
			((s.textContent = e.name),
			s.classList.add("treeselect-list__item-label"),
			t && this.showCount)
		) {
			const i = o(this, Ke, _s).call(this, e);
			s.appendChild(i);
		}
		return s;
	}),
	(Ke = new WeakSet()),
	(_s = function (e) {
		const t = document.createElement("span"),
			s = this.flattedOptions.filter((i) => i.childOf === e.value);
		return (
			(t.textContent = `(${s.length})`),
			t.classList.add("treeselect-list__item-label-counter"),
			t
		);
	}),
	($e = new WeakSet()),
	(Ns = function (e, t) {
		const s = this.flattedOptions.find((i) => i.id === t.value);
		if (s) {
			if (s != null && s.isGroup && this.disabledBranchNode) {
				const i = Ui(e);
				i == null || i.dispatchEvent(new Event("mousedown"));
				return;
			}
			if (this.isSingleSelect) {
				const [i] = n(this, L);
				if (s.id === i)
					if (this.unselectOnClickSingleSelected)
						f(this, L, []),
							vt(
								[],
								this.flattedOptions,
								this.isSingleSelect,
								this.isIndependentNodes
							);
					else return;
				else
					f(this, L, [s.id]),
						vt(
							[s.id],
							this.flattedOptions,
							this.isSingleSelect,
							this.isIndependentNodes
						);
			} else {
				s.checked = e.checked;
				const i = Vt(s, this.flattedOptions, this.isIndependentNodes);
				e.checked = i;
			}
			he(
				this.flattedOptions,
				this.srcElement,
				this.iconElements,
				n(this, L),
				this.rtl,
				this.defaultPadding,
				this.zeroLevelItemPadding
			),
				o(this, Xe, Is).call(this);
		}
	}),
	(Je = new WeakSet()),
	(Ts = function (e) {
		var a, h;
		const t =
				(h = (a = e.target) == null ? void 0 : a.parentNode) == null
					? void 0
					: h.querySelector("[input-id]"),
			s = (t == null ? void 0 : t.getAttribute("input-id")) ?? null,
			i = Gt(s, this.flattedOptions);
		i &&
			((i.isClosed = !i.isClosed),
			bt(this.flattedOptions, i),
			he(
				this.flattedOptions,
				this.srcElement,
				this.iconElements,
				n(this, L),
				this.rtl,
				this.defaultPadding,
				this.zeroLevelItemPadding
			),
			this.arrowClickCallback(i.id, i.isClosed));
	}),
	(Q = new WeakSet()),
	(At = function (e, t) {
		const s = "treeselect-list__item--focused";
		if (e) {
			const i = Array.from(this.srcElement.querySelectorAll(`.${s}`));
			i.length && i.forEach((a) => a.classList.remove(s)),
				t.classList.add(s);
		} else t.classList.remove(s);
	}),
	(ee = new WeakSet()),
	(Ot = function () {
		const {
			ungroupedNodes: e,
			groupedNodes: t,
			allNodes: s
		} = vi(this.flattedOptions);
		this.selectedNodes = { nodes: e, groupedNodes: t, allNodes: s };
	}),
	(Xe = new WeakSet()),
	(Is = function () {
		o(this, ee, Ot).call(this),
			this.inputCallback(this.selectedNodes),
			(this.value = this.selectedNodes.nodes.map((e) => e.id));
	});
const Ut = ({
		parentHtmlContainer: l,
		staticList: e,
		appendToBody: t,
		isSingleSelect: s,
		value: i,
		direction: a
	}) => {
		l || console.error("Validation: parentHtmlContainer prop is required!"),
			e &&
				t &&
				console.error(
					"Validation: You should set staticList to false if you use appendToBody!"
				),
			s &&
				Array.isArray(i) &&
				console.error(
					"Validation: if you use isSingleSelect prop, you should pass a single value!"
				),
			!s &&
				!Array.isArray(i) &&
				console.error(
					"Validation: you should pass an array as a value!"
				),
			a &&
				a !== "auto" &&
				a !== "bottom" &&
				a !== "top" &&
				console.error(
					"Validation: you should pass (auto | top | bottom | undefined) as a value for the direction prop!"
				);
	},
	ce = (l) => l.map((e) => e.id),
	Ri = (l) => (l ? (Array.isArray(l) ? l : [l]) : []),
	Fi = (l, e) => {
		if (e) {
			const [t] = l;
			return t ?? null;
		}
		return l;
	};
var u,
	p,
	R,
	te,
	F,
	O,
	_,
	y,
	D,
	se,
	_t,
	ie,
	Nt,
	Qe,
	Ps,
	et,
	Ds,
	tt,
	Vs,
	st,
	Bs,
	it,
	Hs,
	lt,
	Gs,
	le,
	Tt,
	nt,
	Us,
	at,
	Ms,
	ot,
	zs,
	rt,
	Rs,
	ne,
	It,
	ct,
	Fs,
	j,
	de,
	ae,
	Pt,
	W,
	ue,
	ht,
	js,
	oe,
	Dt,
	dt,
	Ws,
	ut,
	Ys,
	pt,
	qs,
	mt,
	Zs,
	ft,
	Ks;
class Wi {
	constructor({
		parentHtmlContainer: e,
		value: t,
		options: s,
		openLevel: i,
		appendToBody: a,
		alwaysOpen: h,
		showTags: d,
		tagsCountText: C,
		clearable: m,
		searchable: b,
		placeholder: g,
		grouped: k,
		isGroupedValue: w,
		listSlotHtmlComponent: S,
		disabled: x,
		emptyText: V,
		staticList: z,
		id: Y,
		ariaLabel: gt,
		isSingleSelect: re,
		showCount: Et,
		disabledBranchNode: $s,
		direction: Js,
		expandSelected: Xs,
		saveScrollPosition: Qs,
		isIndependentNodes: ei,
		rtl: ti,
		defaultPadding: si,
		zeroLevelItemPadding: ii,
		iconElements: li,
		showPlaceholderOnOpen: ni,
		unselectOnClickSingleSelected: ai,
		inputCallback: oi,
		openCallback: ri,
		closeCallback: ci,
		nameChangeCallback: hi,
		searchCallback: di,
		openCloseGroupCallback: ui
	}) {
		c(this, se);
		c(this, ie);
		c(this, Qe);
		c(this, et);
		c(this, tt);
		c(this, st);
		c(this, it);
		c(this, lt);
		c(this, le);
		c(this, nt);
		c(this, at);
		c(this, ot);
		c(this, rt);
		c(this, ne);
		c(this, ct);
		c(this, j);
		c(this, ae);
		c(this, W);
		c(this, ht);
		// Emits
		c(this, oe);
		c(this, dt);
		c(this, ut);
		c(this, pt);
		c(this, mt);
		c(this, ft);
		// Props
		r(this, "parentHtmlContainer");
		r(this, "value");
		r(this, "options");
		r(this, "openLevel");
		r(this, "appendToBody");
		r(this, "alwaysOpen");
		r(this, "showTags");
		r(this, "tagsCountText");
		r(this, "clearable");
		r(this, "searchable");
		r(this, "placeholder");
		r(this, "grouped");
		r(this, "isGroupedValue");
		r(this, "listSlotHtmlComponent");
		r(this, "disabled");
		r(this, "emptyText");
		r(this, "staticList");
		r(this, "id");
		r(this, "ariaLabel");
		r(this, "isSingleSelect");
		r(this, "showCount");
		r(this, "disabledBranchNode");
		r(this, "direction");
		r(this, "expandSelected");
		r(this, "saveScrollPosition");
		r(this, "isIndependentNodes");
		r(this, "rtl");
		r(this, "defaultPadding");
		r(this, "zeroLevelItemPadding");
		r(this, "iconElements");
		r(this, "showPlaceholderOnOpen");
		r(this, "unselectOnClickSingleSelected");
		r(this, "inputCallback");
		r(this, "openCallback");
		r(this, "closeCallback");
		r(this, "nameChangeCallback");
		r(this, "searchCallback");
		r(this, "openCloseGroupCallback");
		// InnerState
		r(this, "ungroupedValue");
		r(this, "groupedValue");
		r(this, "allValue");
		r(this, "isListOpened");
		r(this, "selectedName");
		r(this, "srcElement");
		// Components
		c(this, u, null);
		c(this, p, null);
		// Resize props
		c(this, R, null);
		// List position scroll
		c(this, te, 0);
		// Timer for search text
		c(this, F, 0);
		// Outside listeners
		c(this, O, null);
		c(this, _, null);
		c(this, y, null);
		c(this, D, null);
		Ut({
			parentHtmlContainer: e,
			value: t,
			staticList: z,
			appendToBody: a,
			isSingleSelect: re
		}),
			(this.parentHtmlContainer = e),
			(this.value = []),
			(this.options = s ?? []),
			(this.openLevel = i ?? 0),
			(this.appendToBody = a ?? !1),
			(this.alwaysOpen = !!(h && !x)),
			(this.showTags = d ?? !0),
			(this.tagsCountText = C ?? "elements selected"),
			(this.clearable = m ?? !0),
			(this.searchable = b ?? !0),
			(this.placeholder = g ?? "Search..."),
			(this.grouped = k ?? !0),
			(this.isGroupedValue = w ?? !1),
			(this.listSlotHtmlComponent = S ?? null),
			(this.disabled = x ?? !1),
			(this.emptyText = V ?? "No results found..."),
			(this.staticList = !!(z && !this.appendToBody)),
			(this.id = Y ?? ""),
			(this.ariaLabel = gt ?? ""),
			(this.isSingleSelect = re ?? !1),
			(this.showCount = Et ?? !1),
			(this.disabledBranchNode = $s ?? !1),
			(this.direction = Js ?? "auto"),
			(this.expandSelected = Xs ?? !1),
			(this.saveScrollPosition = Qs ?? !0),
			(this.isIndependentNodes = ei ?? !1),
			(this.rtl = ti ?? !1),
			(this.defaultPadding = si ?? 20),
			(this.zeroLevelItemPadding = ii ?? 5),
			(this.iconElements = Ht(li)),
			(this.showPlaceholderOnOpen = ni ?? !1),
			(this.unselectOnClickSingleSelected = ai ?? !1),
			(this.inputCallback = oi),
			(this.openCallback = ri),
			(this.closeCallback = ci),
			(this.nameChangeCallback = hi),
			(this.searchCallback = di),
			(this.openCloseGroupCallback = ui),
			(this.ungroupedValue = []),
			(this.groupedValue = []),
			(this.allValue = []),
			(this.isListOpened = !1),
			(this.selectedName = ""),
			(this.srcElement = null),
			o(this, se, _t).call(this, t);
	}
	mount() {
		Ut({
			parentHtmlContainer: this.parentHtmlContainer,
			value: this.value,
			staticList: this.staticList,
			appendToBody: this.appendToBody,
			isSingleSelect: this.isSingleSelect
		}),
			(this.iconElements = Ht(this.iconElements)),
			o(this, se, _t).call(this, this.value);
	}
	updateValue(e) {
		const t = Ri(e),
			s = n(this, u);
		s &&
			(s.updateValue(t),
			o(this, le, Tt).call(this, s == null ? void 0 : s.selectedNodes));
	}
	destroy() {
		this.srcElement &&
			(o(this, ne, It).call(this),
			(this.srcElement.innerHTML = ""),
			(this.srcElement = null),
			o(this, W, ue).call(this, !0));
	}
	focus() {
		n(this, p) && n(this, p).focus();
	}
	toggleOpenClose() {
		n(this, p) && (n(this, p).openClose(), n(this, p).focus());
	}
	// Outside Listeners
	scrollWindowHandler() {
		this.updateListPosition();
	}
	focusWindowHandler(e) {
		var s, i, a;
		((s = this.srcElement) == null ? void 0 : s.contains(e.target)) ||
			((i = n(this, u)) == null
				? void 0
				: i.srcElement.contains(e.target)) ||
			((a = n(this, p)) == null || a.blur(),
			o(this, W, ue).call(this, !1),
			o(this, j, de).call(this, !1));
	}
	blurWindowHandler() {
		var e;
		(e = n(this, p)) == null || e.blur(),
			o(this, W, ue).call(this, !1),
			o(this, j, de).call(this, !1);
	}
	// Update direction of the list. Support appendToBody and standard mode with absolute
	updateListPosition() {
		var S;
		const e = this.srcElement,
			t = (S = n(this, u)) == null ? void 0 : S.srcElement;
		if (!e || !t) return;
		const { height: s } = t.getBoundingClientRect(),
			{ x: i, y: a, height: h, width: d } = e.getBoundingClientRect(),
			C = window.innerHeight,
			m = a,
			b = C - a - h;
		let g = m > b && m >= s && b < s;
		if (
			(this.direction !== "auto" && (g = this.direction === "top"),
			this.appendToBody)
		) {
			(t.style.top !== "0px" || t.style.left !== "0px") &&
				((t.style.top = "0px"), (t.style.left = "0px"));
			const x = i + window.scrollX,
				V = g ? a + window.scrollY - s : a + window.scrollY + h;
			(t.style.transform = `translate(${x}px,${V}px)`),
				(t.style.width = `${d}px`);
		}
		const k = g ? "top" : "bottom";
		t.getAttribute("direction") !== k &&
			(t.setAttribute("direction", k),
			o(this, ct, Fs).call(this, g, this.appendToBody));
	}
}
(u = new WeakMap()),
	(p = new WeakMap()),
	(R = new WeakMap()),
	(te = new WeakMap()),
	(F = new WeakMap()),
	(O = new WeakMap()),
	(_ = new WeakMap()),
	(y = new WeakMap()),
	(D = new WeakMap()),
	(se = new WeakSet()),
	(_t = function (e) {
		var a;
		this.destroy();
		const { container: t, list: s, input: i } = o(this, Qe, Ps).call(this);
		(this.srcElement = t),
			f(this, u, s),
			f(this, p, i),
			f(this, O, this.scrollWindowHandler.bind(this)),
			f(this, _, this.scrollWindowHandler.bind(this)),
			f(this, y, this.focusWindowHandler.bind(this)),
			f(this, D, this.blurWindowHandler.bind(this)),
			this.alwaysOpen && ((a = n(this, p)) == null || a.openClose()),
			this.disabled
				? this.srcElement.classList.add("treeselect--disabled")
				: this.srcElement.classList.remove("treeselect--disabled"),
			this.updateValue(e ?? this.value);
	}),
	(ie = new WeakSet()),
	(Nt = function ({ groupedNodes: e, nodes: t, allNodes: s }) {
		(this.ungroupedValue = t ? ce(t) : []),
			(this.groupedValue = e ? ce(e) : []),
			(this.allValue = s ? ce(s) : []);
		let i = [];
		this.isIndependentNodes || this.isSingleSelect
			? (i = this.allValue)
			: this.isGroupedValue
			? (i = this.groupedValue)
			: (i = this.ungroupedValue),
			(this.value = Fi(i, this.isSingleSelect));
	}),
	(Qe = new WeakSet()),
	(Ps = function () {
		const e = this.parentHtmlContainer;
		e.classList.add("treeselect"), this.rtl && e.setAttribute("dir", "rtl");
		const t = new zi({
				value: [],
				// updateValue method calls in initMount method to set actual value
				options: this.options,
				openLevel: this.openLevel,
				listSlotHtmlComponent: this.listSlotHtmlComponent,
				emptyText: this.emptyText,
				isSingleSelect: this.isSingleSelect,
				showCount: this.showCount,
				disabledBranchNode: this.disabledBranchNode,
				expandSelected: this.expandSelected,
				isIndependentNodes: this.isIndependentNodes,
				rtl: this.rtl,
				defaultPadding: this.defaultPadding,
				zeroLevelItemPadding: this.zeroLevelItemPadding,
				iconElements: this.iconElements,
				unselectOnClickSingleSelected:
					this.unselectOnClickSingleSelected,
				inputCallback: (i) => o(this, nt, Us).call(this, i),
				arrowClickCallback: (i, a) => o(this, at, Ms).call(this, i, a),
				mouseupCallback: () => {
					var i;
					return (i = n(this, p)) == null ? void 0 : i.focus();
				}
			}),
			s = new Ci({
				value: [],
				// updateValue method calls in initMount method to set actual value
				showTags: this.showTags,
				tagsCountText: this.tagsCountText,
				clearable: this.clearable,
				isAlwaysOpened: this.alwaysOpen,
				searchable: this.searchable,
				placeholder: this.placeholder,
				disabled: this.disabled,
				isSingleSelect: this.isSingleSelect,
				id: this.id,
				ariaLabel: this.ariaLabel,
				iconElements: this.iconElements,
				showPlaceholderOnOpen: this.showPlaceholderOnOpen,
				inputCallback: (i) => o(this, et, Ds).call(this, i),
				searchCallback: (i) => o(this, st, Bs).call(this, i),
				openCallback: () => o(this, rt, Rs).call(this),
				closeCallback: () => o(this, ne, It).call(this),
				keydownCallback: (i) => o(this, tt, Vs).call(this, i),
				focusCallback: () => o(this, it, Hs).call(this),
				blurCallback: () => o(this, lt, Gs).call(this),
				nameChangeCallback: (i) => o(this, ot, zs).call(this, i)
			});
		return (
			this.appendToBody &&
				f(this, R, new ResizeObserver(() => this.updateListPosition())),
			e.append(s.srcElement),
			{ container: e, list: t, input: s }
		);
	}),
	(et = new WeakSet()),
	(Ds = function (e) {
		var i, a;
		const t = ce(e);
		(i = n(this, u)) == null || i.updateValue(t);
		const s = ((a = n(this, u)) == null ? void 0 : a.selectedNodes) ?? {};
		o(this, ie, Nt).call(this, s), o(this, oe, Dt).call(this);
	}),
	(tt = new WeakSet()),
	(Vs = function (e) {
		var t;
		this.isListOpened && ((t = n(this, u)) == null || t.callKeyAction(e));
	}),
	(st = new WeakSet()),
	(Bs = function (e) {
		n(this, F) && clearTimeout(n(this, F)),
			f(
				this,
				F,
				window.setTimeout(() => {
					var t;
					(t = n(this, u)) == null || t.updateSearchValue(e),
						this.updateListPosition();
				}, 350)
			),
			o(this, mt, Zs).call(this, e);
	}),
	(it = new WeakSet()),
	(Hs = function () {
		o(this, j, de).call(this, !0),
			n(this, y) &&
				n(this, y) &&
				n(this, D) &&
				(document.addEventListener("mousedown", n(this, y), !0),
				document.addEventListener("focus", n(this, y), !0),
				window.addEventListener("blur", n(this, D)));
	}),
	(lt = new WeakSet()),
	(Gs = function () {
		setTimeout(() => {
			var s, i;
			const e =
					(s = n(this, p)) == null
						? void 0
						: s.srcElement.contains(document.activeElement),
				t =
					(i = n(this, u)) == null
						? void 0
						: i.srcElement.contains(document.activeElement);
			!e && !t && this.blurWindowHandler();
		}, 1);
	}),
	(le = new WeakSet()),
	(Tt = function (e) {
		var s;
		if (!e) return;
		let t = [];
		this.isIndependentNodes || this.isSingleSelect
			? (t = e.allNodes)
			: this.grouped
			? (t = e.groupedNodes)
			: (t = e.nodes),
			(s = n(this, p)) == null || s.updateValue(t),
			o(this, ie, Nt).call(this, e);
	}),
	(nt = new WeakSet()),
	(Us = function (e) {
		var t, s, i;
		o(this, le, Tt).call(this, e),
			this.isSingleSelect &&
				!this.alwaysOpen &&
				((t = n(this, p)) == null || t.openClose(),
				(s = n(this, p)) == null || s.clearSearch()),
			(i = n(this, p)) == null || i.focus(),
			o(this, oe, Dt).call(this);
	}),
	(at = new WeakSet()),
	(Ms = function (e, t) {
		var s;
		(s = n(this, p)) == null || s.focus(),
			this.updateListPosition(),
			o(this, ft, Ks).call(this, e, t);
	}),
	(ot = new WeakSet()),
	(zs = function (e) {
		this.selectedName !== e &&
			((this.selectedName = e), o(this, dt, Ws).call(this));
	}),
	(rt = new WeakSet()),
	(Rs = function () {
		var e;
		(this.isListOpened = !0),
			n(this, O) &&
				n(this, _) &&
				(window.addEventListener("scroll", n(this, O), !0),
				window.addEventListener("resize", n(this, _))),
			!(!n(this, u) || !this.srcElement) &&
				(this.appendToBody
					? (document.body.appendChild(n(this, u).srcElement),
					  (e = n(this, R)) == null || e.observe(this.srcElement))
					: this.srcElement.appendChild(n(this, u).srcElement),
				this.updateListPosition(),
				o(this, ae, Pt).call(this, !0),
				o(this, ht, js).call(this),
				o(this, ut, Ys).call(this));
	}),
	(ne = new WeakSet()),
	(It = function () {
		var t;
		this.alwaysOpen ||
			((this.isListOpened = !1),
			n(this, O) &&
				n(this, _) &&
				(window.removeEventListener("scroll", n(this, O), !0),
				window.removeEventListener("resize", n(this, _))),
			!n(this, u) || !this.srcElement) ||
			!(this.appendToBody
				? document.body.contains(n(this, u).srcElement)
				: this.srcElement.contains(n(this, u).srcElement)) ||
			(f(this, te, n(this, u).srcElement.scrollTop),
			this.appendToBody
				? (document.body.removeChild(n(this, u).srcElement),
				  (t = n(this, R)) == null || t.disconnect())
				: this.srcElement.removeChild(n(this, u).srcElement),
			o(this, ae, Pt).call(this, !1),
			o(this, pt, qs).call(this));
	}),
	(ct = new WeakSet()),
	(Fs = function (e, t) {
		if (!n(this, u) || !n(this, p)) return;
		const s = t ? "treeselect-list--top-to-body" : "treeselect-list--top",
			i = t
				? "treeselect-list--bottom-to-body"
				: "treeselect-list--bottom";
		e
			? (n(this, u).srcElement.classList.add(s),
			  n(this, u).srcElement.classList.remove(i),
			  n(this, p).srcElement.classList.add("treeselect-input--top"),
			  n(this, p).srcElement.classList.remove(
					"treeselect-input--bottom"
			  ))
			: (n(this, u).srcElement.classList.remove(s),
			  n(this, u).srcElement.classList.add(i),
			  n(this, p).srcElement.classList.remove("treeselect-input--top"),
			  n(this, p).srcElement.classList.add("treeselect-input--bottom"));
	}),
	(j = new WeakSet()),
	(de = function (e) {
		!n(this, p) ||
			!n(this, u) ||
			(e
				? (n(this, p).srcElement.classList.add(
						"treeselect-input--focused"
				  ),
				  n(this, u).srcElement.classList.add(
						"treeselect-list--focused"
				  ))
				: (n(this, p).srcElement.classList.remove(
						"treeselect-input--focused"
				  ),
				  n(this, u).srcElement.classList.remove(
						"treeselect-list--focused"
				  )));
	}),
	(ae = new WeakSet()),
	(Pt = function (e) {
		var t, s, i, a;
		e
			? (t = n(this, p)) == null ||
			  t.srcElement.classList.add("treeselect-input--opened")
			: (s = n(this, p)) == null ||
			  s.srcElement.classList.remove("treeselect-input--opened"),
			this.staticList
				? (i = n(this, u)) == null ||
				  i.srcElement.classList.add("treeselect-list--static")
				: (a = n(this, u)) == null ||
				  a.srcElement.classList.remove("treeselect-list--static");
	}),
	(W = new WeakSet()),
	(ue = function (e) {
		!n(this, O) ||
			!n(this, _) ||
			!n(this, y) ||
			!n(this, D) ||
			((!this.alwaysOpen || e) &&
				(window.removeEventListener("scroll", n(this, O), !0),
				window.removeEventListener("resize", n(this, _))),
			document.removeEventListener("mousedown", n(this, y), !0),
			document.removeEventListener("focus", n(this, y), !0),
			window.removeEventListener("blur", n(this, D)));
	}),
	(ht = new WeakSet()),
	(js = function () {
		var t, s, i;
		const e =
			(t = n(this, u)) == null ? void 0 : t.isLastFocusedElementExist();
		this.saveScrollPosition && e
			? (s = n(this, u)) == null || s.srcElement.scroll(0, n(this, te))
			: (i = n(this, u)) == null || i.focusFirstListElement();
	}),
	(oe = new WeakSet()),
	(Dt = function () {
		var e;
		(e = this.srcElement) == null ||
			e.dispatchEvent(new CustomEvent("input", { detail: this.value })),
			this.inputCallback && this.inputCallback(this.value);
	}),
	(dt = new WeakSet()),
	(Ws = function () {
		var e;
		(e = this.srcElement) == null ||
			e.dispatchEvent(
				new CustomEvent("name-change", { detail: this.selectedName })
			),
			this.nameChangeCallback &&
				this.nameChangeCallback(this.selectedName);
	}),
	(ut = new WeakSet()),
	(Ys = function () {
		var e;
		this.alwaysOpen ||
			((e = this.srcElement) == null ||
				e.dispatchEvent(
					new CustomEvent("open", { detail: this.value })
				),
			this.openCallback && this.openCallback(this.value));
	}),
	(pt = new WeakSet()),
	(qs = function () {
		var e;
		this.alwaysOpen ||
			((e = this.srcElement) == null ||
				e.dispatchEvent(
					new CustomEvent("close", { detail: this.value })
				),
			this.closeCallback && this.closeCallback(this.value));
	}),
	(mt = new WeakSet()),
	(Zs = function (e) {
		var s;
		const t = (e == null ? void 0 : e.trim()) ?? "";
		(s = this.srcElement) == null ||
			s.dispatchEvent(new CustomEvent("search", { detail: t })),
			this.searchCallback && this.searchCallback(t);
	}),
	(ft = new WeakSet()),
	(Ks = function (e, t) {
		var s;
		(s = this.srcElement) == null ||
			s.dispatchEvent(
				new CustomEvent("open-close-group", {
					detail: { groupId: e, isClosed: t }
				})
			),
			this.openCloseGroupCallback && this.openCloseGroupCallback(e, t);
	});
export { Wi as default };
