import { inject } from "vue";
import {
	SYMBOL_LOG_MONITORING,
	SYMBOL_MONITORING
} from "./user-console-monitoring.plugin.js";

export const useLogMonitoring = () => {
	const monitor = inject(SYMBOL_MONITORING);
	const logMonitor = inject(SYMBOL_LOG_MONITORING);
	return {
		notify: (...args) => logMonitor.notify(...args),
		setUser: (user) => monitor.setUser(user)
	};
};
