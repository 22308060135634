/**
 * Keep in memory all elements present in data-table
 * {
 * 	"concatenated-unique-string": [index position of element in table, idx2, ...]
 * }
 * It can happen that a same index will be shared between elements (imagine a case where rows is like [{name: "Murilo"}, {name: "Murilo"}]). There's no way to generate different unique-string, so save all positions
 */
let cacheRowsDataTable = {};
export function clearCacheRows() {
	cacheRowsDataTable = {};
}
function replaceCache(newCache) {
	cacheRowsDataTable = Object.assign({}, newCache);
}
function addRowToCache(rowUniqueId, idx) {
	cacheRowsDataTable[rowUniqueId] = [
		...(cacheRowsDataTable[rowUniqueId] || []),
		idx
	];
}
function getIndexesFromCache(rowUniqueId) {
	return cacheRowsDataTable[rowUniqueId] ?? [-1];
}

export default {
	clearCacheRows,
	replaceCache,
	addRowToCache,
	getIndexesFromCache
};
