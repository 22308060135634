<template>
	<a
		class="dropdown-item"
		:class="{
			'is-primary': type === 'primary',
			'is-default': type === 'default',
			'is-disabled': disabled
		}"
		@click="
			(ev) => {
				!disabled && $emit('click', ev);
			}
		"
		:data-testid="dataTestid"
	>
		<span class="icon" v-if="showIcon && iconSide === 'left'">
			<slot name="icon" />
			<ws-icon :icon="icon || wsIcon" v-if="icon || wsIcon" />
		</span>
		<!-- it was <template> before -->
		<div :class="`text-icon-${iconSide}`">
			<slot />
			<template v-if="!$slots.default">
				{{ title }}
			</template>
		</div>
		<span class="icon right" v-if="showIcon && iconSide === 'right'">
			<slot name="icon" />
			<ws-icon :icon="icon || wsIcon" v-if="icon || wsIcon" />
		</span>
	</a>
</template>

<script>
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";

export default {
	name: "WsDropdownItem",

	emits: ["click"],

	setup() {
		const { notify: logNotifier } = useLogMonitoring();

		return { logNotifier };
	},

	props: {
		title: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "primary",
			validation: function (value) {
				return ["default", "primary"].includes(value);
			}
		},
		icon: {
			type: String,
			default: null
		},
		wsIcon: {
			type: String,
			default: null
		},
		iconSide: {
			type: String,
			default: "left",
			validator: (value) => {
				return [null, "left", "right"].includes(value);
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dataTestid: {
			type: String,
			default: null
		}
	},

	onMounted() {
		if (this.wsIcon || this.fontAwesomeIcon) {
			this.logNotifier(
				"[ws-icon|font-awesome-icon] ws-dropdown-item is using ws-icon|font-awesome-item prop while it's depracted. Use icon only."
			);
		}
	},

	computed: {
		showIcon() {
			return (this.icon || this.wsIcon)?.length || !!this.$slots.icon;
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown-item {
	border-bottom-color: transparent;
	justify-content: left;

	&:hover {
		border-bottom-color: $color-primary-500;
	}

	.icon.right {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.text-icon-left {
		margin-left: 5px;
	}

	&.is-disabled {
		color: $color-grey-400;

		&:deep(svg) {
			color: $color-primary-200;
		}

		&:hover {
			background: $white !important;
			color: $color-grey-400 !important;
		}
	}
}
.dropdown-item.is-default {
	.icon {
		color: $color-grey-700 !important;
		stroke: $color-grey-700 !important;
		fill: $color-grey-700 !important;
	}
	&:hover {
		color: $color-grey-700 !important;
		background-color: $color-grey-200 !important;
		border-bottom-color: $color-grey-200;
	}
}
</style>
